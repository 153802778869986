/** @jsxImportSource @emotion/react */

import { useTheme } from "../../../../../design/hooks/useTheme"
import { Button } from "../../../../../design/system/buttons/Button"
import { ButtonHorizontalContent } from "../../../../../design/system/buttons/ButtonHorizontalContent"
import { GreaterThan } from "../../../../../design/system/icons/GreaterThan"
import { LessThan } from "../../../../../design/system/icons/LessThan"

type Props = {
  onForward: () => void
  onBack: () => void
  disableBack: boolean
  disableForward: boolean
  showBack: boolean
  showForward: boolean
}

export const PagesNavigation: React.FC<Props> = ({ disableBack, disableForward, showBack, showForward, onBack, onForward }) => {
  const { gaps } = useTheme()
  return (
    <div
      css={{
        display: "grid",
        columnGap: gaps[3],
        gridTemplateColumns: "auto auto",
        justifyContent: "center",
        paddingBottom: 50
      }}
      role="navigation"
    >
      {showBack && (
        <Button disabled={disableBack} intent="Primary" onClick={onBack}>
          <ButtonHorizontalContent>
            <LessThan /> Wróć
          </ButtonHorizontalContent>
        </Button>
      )}
      {showForward && (
        <Button disabled={disableForward} intent="Primary" onClick={onForward}>
          <ButtonHorizontalContent>
            Dalej <GreaterThan />
          </ButtonHorizontalContent>
        </Button>
      )}
    </div>
  )
}
