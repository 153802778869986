/** @jsxImportSource @emotion/react */
import { useEffect } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { InitialSurveyState } from "../../../../../../../modules/domain/survey/initial/InitialSurveyState"
import { getInstance } from "../../../../../../../modules/domain/survey/initial/actions"
import { MainState } from "../../../../../../../modules/state/MainState"
import { Spinner } from "../../../../../../design/system/Spinner"
import { Error404 } from "../../../../../errors/Error404"
import { SectionChart } from "../sections/chart/SectionChart"
import { SectionDescriptions } from "../sections/descriptions/SectionDescriptions"
import { Footer } from "./sections/footer/Footer"
import { SectionTop } from "./sections/top/SectionTop"

const mapState = (state: MainState): InitialSurveyState => state.domain.survey.initial

export const HCCSurveyResultPrintablePage: React.FC = () => {
  const { result } = useSelector(mapState, shallowEqual)
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const instanceId = searchParams.get("instanceId")
  const isLoading = result == null

  useEffect(() => {
    if (instanceId) {
      dispatch(getInstance(instanceId))
    }
  }, [dispatch, instanceId])

  return instanceId != null ? (
    <div
      css={{
        cursor: isLoading ? "progress" : "default"
      }}
    >
      <SectionTop />
      {result != null && typeof result !== "string" ? (
        <>
          <SectionChart result={result} />
          <SectionDescriptions result={result} />
        </>
      ) : (
        isLoading && <Spinner />
      )}
      <Footer />
    </div>
  ) : (
    <Error404 />
  )
}
