/** @jsxImportSource @emotion/react */

import { useTheme } from "../../../hooks/useTheme"
import { H1 } from "../../headers/H1"

export const StandardRegulationsHeader: React.FC = ({ children }) => {
  const { colors } = useTheme()

  return (
    <H1 textAlign="left" color={colors.primary[900]} marginBottom={20}>
      {children}
    </H1>
  )
}
