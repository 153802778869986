import { Theme } from "../../../modules/design/Theme"
import { CSSObject } from "./CSSObject"

type Params = {
  theme: Theme
}

export const textLinkCSS = ({ theme }: Params): CSSObject => {
  const { colors, fonts } = theme

  return {
    color: colors.primary[600],
    cursor: "pointer",
    fontFamily: fonts.inter,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "28px",
    textDecoration: "none"
  }
}
