/** @jsxImportSource @emotion/react */

import { Fragment } from "react"
import { polarToNormalizedCoords } from "../../../modules/charts/polarToNormalizedCoords"
import { Dot } from "../Dot"
import { Line } from "../Line"

type Props = {
  color: string
  values: number[]
}

export const SpiderCurve: React.FC<Props> = ({ color, values }) => {
  const calculateAngle = (idx: number): number => (idx * 2 * Math.PI) / values.length

  return (
    <>
      {[...values.keys()].map((i) => {
        const idx1 = i > 0 ? i - 1 : values.length - 1
        const idx2 = i
        const angle1 = calculateAngle(idx1)
        const angle2 = calculateAngle(idx2)
        const v1 = polarToNormalizedCoords(angle1, values[idx1] as number)
        const v2 = polarToNormalizedCoords(angle2, values[idx2] as number)
        return (
          <Fragment key={i}>
            <Line color={color} v1={v1} v2={v2} thickness={6} />
            <Dot color={color} position={v1} size={10} />
          </Fragment>
        )
      })}
    </>
  )
}
