import { ThemeContext } from "@emotion/react"
import { Provider as StoreProvider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { defaultTheme } from "../../modules/design/defaultTheme"
import { Injector } from "../../modules/di/Injector"
import { InjectorContext } from "../injector/InjectorContext"

type Props = {
  injector: Injector
}

export const Providers: React.FC<Props> = ({ children, injector }) => (
  <InjectorContext.Provider value={injector}>
    <StoreProvider store={injector.store}>
      <BrowserRouter window={injector.win}>
        <ThemeContext.Provider value={defaultTheme}>{children}</ThemeContext.Provider>
      </BrowserRouter>
    </StoreProvider>
  </InjectorContext.Provider>
)
