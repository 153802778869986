/** @jsxImportSource @emotion/react */

import { ChangeEvent, useCallback } from "react"
import { Formik } from "../../../../../../../modules/form/Formik"
import { textInputCSS } from "../../../../../../design/css/form/textInputCSS"
import { useTheme } from "../../../../../../design/hooks/useTheme"
import { FormFieldError } from "../../../../../../design/system/form/FormFieldError"

type Props<V extends { email?: string }> = {
  formik: Formik<V>
}

export const EmailField = <V extends { email?: string }>({ formik }: Props<V>): JSX.Element => {
  const theme = useTheme()
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => formik.setFieldValue("email", event.currentTarget.value), [formik])

  return (
    <fieldset>
      <input
        css={{
          ...textInputCSS(theme, { hasErrors: formik.errors.email != null }),
          width: "100%"
        }}
        onChange={onChange}
        placeholder="Wpisz swój e-mail"
        type="email"
        value={formik.values.email}
      />
      <FormFieldError message={formik.errors.email} />
    </fieldset>
  )
}
