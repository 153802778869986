/** @jsxImportSource @emotion/react */

import { dimensions } from "../../../../../../../../modules/domain/survey/hcc/HCCDimension"
import { HCCSurveyResult } from "../../../../../../../../modules/domain/survey/hcc/HCCSurveyResult"
import { useTheme } from "../../../../../../../design/hooks/useTheme"
import { useMessages } from "../../../../../../../i18n/hooks/useMessages"
import { useScreen } from "../../../../../../../screen/hooks/useScreen"
import { DescriptionBox } from "./DescriptionBox"

type Props = {
  result: HCCSurveyResult
}

export const SectionDescriptions: React.FC<Props> = ({ result }) => {
  const { window } = useScreen()
  const labels = useMessages().domain.hcc.dimensions
  const { gaps } = useTheme()
  const shouldUseSmallerPaddings = window.width < 640

  return (
    <section
      css={{
        display: "grid",
        margin: "40px auto",
        maxWidth: 1300,
        padding: gaps[shouldUseSmallerPaddings ? 5 : 6],
        rowGap: gaps[7],
        width: "100%"
      }}
    >
      {dimensions.map((dimension) => (
        <DescriptionBox key={dimension} title={labels[dimension]} result={result[dimension]} />
      ))}
    </section>
  )
}
