import { Theme } from "../../../../modules/design/Theme"
import { Intent } from "../../../../modules/intent/Intent"
import { getBackgroundColorForIntent } from "../../../../modules/intent/getBackgroundColorForIntent"
import { getForegroundColorForIntent } from "../../../../modules/intent/getForegroundColorForIntent"
import { CSSObject } from "../CSSObject"

type Params = {
  disabled?: boolean
  intent: Intent
  paddingBottom?: number
  paddingLeft?: number
  paddingRight?: number
  paddingTop?: number
  theme: Theme
  useAltShade?: boolean
}

export const buttonCSS = ({
  disabled,
  intent,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  theme,
  useAltShade = false
}: Params): CSSObject => {
  const { colors, fonts } = theme

  return {
    backgroundColor: getBackgroundColorForIntent(intent, useAltShade, false, colors),
    borderRadius: 8,
    borderStyle: "none",
    color: getForegroundColorForIntent(intent, useAltShade, colors),
    cursor: disabled ? "not-allowed" : "pointer",
    fontFamily: fonts.inter,
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    outline: "none",
    paddingBottom: paddingBottom ?? 16,
    paddingLeft: paddingLeft ?? 24,
    paddingRight: paddingRight ?? 24,
    paddingTop: paddingTop ?? 16,
    "&:hover": {
      backgroundColor: getBackgroundColorForIntent(intent, useAltShade, true, colors)
    }
  }
}
