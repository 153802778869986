import { Effect, all } from "redux-saga/effects"
import { Injector } from "../../../di/Injector"
import { watchGetDefinition } from "./sagas/watchGetDefinition"
import { watchGetInstance } from "./sagas/watchGetInstance"
import { watchSendResultByEmail } from "./sagas/watchSendResultByEmail"
import { watchUpload } from "./sagas/watchUpload"

export function* initial(injector: Injector): Generator<Effect> {
  yield all([watchGetDefinition(injector), watchGetInstance(injector), watchSendResultByEmail(injector), watchUpload(injector)])
}
