import { Language } from "../../../../i18n/Language"
import { LikertScaleResponseSet } from "./LikertScaleResponseSet"

export const getLikertScaleAnswers = (
  language: Language,
  responseSet: LikertScaleResponseSet
): [string, string, string, string, string] => {
  switch (language) {
    case "en":
      switch (responseSet) {
        case "Agreement":
          return ["Strongly disagree", "Disagree", "Undecided", "Agree", "Strongly agree"]
      }
      break
    case "pl":
      switch (responseSet) {
        case "Agreement":
          return ["Zdecydowanie nie", "Raczej nie", "Nie mam zdania", "Raczej tak", "Zdecydowanie tak"]
      }
  }
}
