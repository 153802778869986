/** @jsxImportSource @emotion/react */

import envelope from "../../../../images/icons/envelope-green.png"

export const GreenEnvelope: React.FC = () => (
  <img
    alt="Green envelope"
    css={{
      display: "block",
      margin: "37px auto 0"
    }}
    height={50}
    src={envelope}
    width={50}
  />
)
