/** @jsxImportSource @emotion/react */
import { useTheme } from "../../hooks/useTheme"
import { HProps } from "./HProps"

export const H0: React.FC<Omit<HProps, "color">> = ({ children, marginBottom, marginTop, textAlign }) => {
  const { fonts } = useTheme()

  return (
    <div
      css={{
        background: "linear-gradient(90deg, #0EA89C 0%, #139797 86.65%)",
        color: "transparent",
        fontFamily: fonts.walsheim,
        fontSize: "53px",
        fontWeight: 700,
        lineHeight: "68px",
        letterSpacing: "-0.025em",
        marginBottom,
        marginTop,
        textAlign,
        WebkitBackgroundClip: "text"
      }}
    >
      {children}
    </div>
  )
}
