/** @jsxImportSource @emotion/react */

import { useInjector } from "../injector/hooks/useInjector"

export const ContactEmail: React.FC = () => {
  const {
    config: {
      contact: { email }
    }
  } = useInjector()

  return <a href={`mailto:${email}`}>{email}</a>
}
