export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string, such as 2019-12-03T09:54:33+02:00, compliant with the date-time format. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AuthMutations = {
  __typename?: 'AuthMutations';
  activateAccount: SimpleMutationResponse;
  refreshToken: SimpleMutationResponse;
  requestPasswordReset: SimpleMutationResponse;
  resetPassword: SimpleMutationResponse;
  signIn: SimpleMutationResponse;
  signOut: SimpleMutationResponse;
  signUp: SimpleMutationResponse;
};


export type AuthMutationsActivateAccountArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};


export type AuthMutationsRefreshTokenArgs = {
  clientId?: InputMaybe<Scalars['String']>;
};


export type AuthMutationsRequestPasswordResetArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};


export type AuthMutationsResetPasswordArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  input: PasswordResetInput;
};


export type AuthMutationsSignInArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};


export type AuthMutationsSignOutArgs = {
  clientId?: InputMaybe<Scalars['String']>;
};


export type AuthMutationsSignUpArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  input: SignUpInput;
};

export type HccSurveyDimensionResult = {
  __typename?: 'HCCSurveyDimensionResult';
  description: Scalars['String'];
  score: Scalars['Float'];
  scoreEvaluation: Scalars['String'];
};

export type HccSurveyResult = {
  __typename?: 'HCCSurveyResult';
  dilligence: HccSurveyDimensionResult;
  emotionalStability: HccSurveyDimensionResult;
  empathy: HccSurveyDimensionResult;
  extraversion: HccSurveyDimensionResult;
  independence: HccSurveyDimensionResult;
  openness: HccSurveyDimensionResult;
  professionalSelfEfficacy: HccSurveyDimensionResult;
};

export enum LikertScaleResponseSet {
  Agreement = 'Agreement'
}

export type Mutation = {
  __typename?: 'Mutation';
  auth: AuthMutations;
  survey: SurveyMutations;
};

export type PasswordResetInput = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  _dummy: Scalars['String'];
  survey: SurveyQueries;
};

export type SignUpInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SimpleMutationResponse = {
  __typename?: 'SimpleMutationResponse';
  clientId?: Maybe<Scalars['String']>;
};

export type SurveyAnswerInput = {
  booleanAnswer?: InputMaybe<Scalars['Boolean']>;
  definedAnswerId?: InputMaybe<Scalars['String']>;
  openAnswer?: InputMaybe<Scalars['String']>;
  questionId: Scalars['String'];
  scaleAnswer?: InputMaybe<Scalars['Int']>;
};

export type SurveyDefinedAnswer = {
  __typename?: 'SurveyDefinedAnswer';
  content: Scalars['String'];
  id: Scalars['String'];
};

export type SurveyDefinition = {
  __typename?: 'SurveyDefinition';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  questions: Array<SurveyQuestion>;
};

export type SurveyInstance = {
  __typename?: 'SurveyInstance';
  answers: Array<SurveyInstanceAnswer>;
  completedAt?: Maybe<Scalars['DateTime']>;
  fillingDurationInSeconds: Scalars['Int'];
  id: Scalars['String'];
  intervieweeId?: Maybe<Scalars['String']>;
  result?: Maybe<HccSurveyResult>;
  surveyDefinition: SurveyDefinition;
};

export type SurveyInstanceAnswer = SurveyInstanceBooleanAnswer | SurveyInstanceDefinedAnswer | SurveyInstanceScaleAnswer;

export enum SurveyInstanceAnswerType {
  BooleanAnswer = 'BooleanAnswer',
  DefinedAnswer = 'DefinedAnswer',
  ScaleAnswer = 'ScaleAnswer'
}

export type SurveyInstanceBooleanAnswer = {
  __typename?: 'SurveyInstanceBooleanAnswer';
  answerType: SurveyInstanceAnswerType;
  booleanAnswer: Scalars['Boolean'];
  question: SurveyQuestion;
};

export type SurveyInstanceDefinedAnswer = {
  __typename?: 'SurveyInstanceDefinedAnswer';
  answerType: SurveyInstanceAnswerType;
  definedAnswerId: Scalars['String'];
  question: SurveyQuestion;
};

export type SurveyInstanceScaleAnswer = {
  __typename?: 'SurveyInstanceScaleAnswer';
  answerType: SurveyInstanceAnswerType;
  question: SurveyQuestion;
  scaleAnswer: Scalars['Int'];
};

export type SurveyLikertScaleQuestion = {
  __typename?: 'SurveyLikertScaleQuestion';
  content: Scalars['String'];
  id: Scalars['String'];
  questionType: SurveyQuestionType;
  responseSet: LikertScaleResponseSet;
};

export type SurveyMutations = {
  __typename?: 'SurveyMutations';
  sendResultByEmail: SimpleMutationResponse;
  uploadSurvey: SurveyUploadResponse;
};


export type SurveyMutationsSendResultByEmailArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  surveyInstanceId: Scalars['String'];
};


export type SurveyMutationsUploadSurveyArgs = {
  answers: Array<SurveyAnswerInput>;
  captchaToken: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  files: Array<InputMaybe<Scalars['Upload']>>;
  fillingDurationInSeconds: Scalars['Int'];
  intervieweeId?: InputMaybe<Scalars['String']>;
  surveyDefinitionId: Scalars['String'];
  surveyInstanceId?: InputMaybe<Scalars['String']>;
};

export type SurveyQueries = {
  __typename?: 'SurveyQueries';
  findSurveyByName?: Maybe<SurveyDefinition>;
  getSurveyInstance: SurveyInstance;
};


export type SurveyQueriesFindSurveyByNameArgs = {
  surveyName: Scalars['String'];
};


export type SurveyQueriesGetSurveyInstanceArgs = {
  instanceId: Scalars['String'];
};

export type SurveyQuestion = SurveyLikertScaleQuestion | SurveySingleChoiceQuestion | SurveyYesNoQuestion;

export enum SurveyQuestionType {
  LikertScale = 'LikertScale',
  SingleChoice = 'SingleChoice',
  YesNo = 'YesNo'
}

export type SurveySingleChoiceQuestion = {
  __typename?: 'SurveySingleChoiceQuestion';
  answers: Array<SurveyDefinedAnswer>;
  content: Scalars['String'];
  id: Scalars['String'];
  questionType: SurveyQuestionType;
};

export type SurveyUploadResponse = {
  __typename?: 'SurveyUploadResponse';
  intervieweeId: Scalars['String'];
  result?: Maybe<HccSurveyResult>;
  surveyInstanceId: Scalars['String'];
};

export type SurveyYesNoQuestion = {
  __typename?: 'SurveyYesNoQuestion';
  content: Scalars['String'];
  id: Scalars['String'];
  questionType: SurveyQuestionType;
};
