import { Saga } from "redux-saga"
import { Effect, all } from "redux-saga/effects"
import { Injector } from "../modules/di/Injector"
import { domain } from "../modules/domain/sagas"
import { error } from "../modules/error/sagas"
import { i18n } from "../modules/i18n/sagas"
import { screen } from "../modules/screen/sagas"
import { init } from "./sagas/init"

export const sagas: (injector: Injector) => Saga = (injector) =>
  function* sagas(): Generator<Effect> {
    yield all([domain(injector), error(injector), init(), i18n(injector), screen(injector)])
  }
