/** @jsxImportSource @emotion/react */

import { toDrawingAreaCoords } from "../../modules/charts/toDrawingAreaCoords"
import { Vector2D } from "../../modules/vector/Vector2D"
import { Vector2DOps } from "../../modules/vector/Vector2DOps"
import { useDrawingArea } from "./hooks/useDrawingArea"

type Props = {
  center: Vector2D
  color: string
  radius: number
  thickness: number
}

export const Circle: React.FC<Props> = ({ center, color, radius, thickness }) => {
  const area = useDrawingArea()
  const scaledRadius = Math.min(area.height, area.width) * radius // pretty arbitrary
  const scaledDiameter = 2 * scaledRadius
  const scaledCenter = toDrawingAreaCoords(center, area)
  const bottomLeft = Vector2DOps.minus(scaledCenter, { x: scaledRadius, y: scaledRadius })

  return (
    <div
      css={{
        border: `${thickness}px solid ${color}`,
        borderRadius: scaledRadius,
        bottom: bottomLeft.y,
        height: scaledDiameter,
        left: bottomLeft.x,
        position: "absolute",
        width: scaledDiameter
      }}
      role="img"
    />
  )
}
