/** @jsxImportSource @emotion/react */
import { useTheme } from "../../../../../design/hooks/useTheme"
import { useScreen } from "../../../../../screen/hooks/useScreen"
import { IntroBox } from "./IntroBox"
import { IntroBoxHeader } from "./IntroBoxHeader"
import { IntroBoxParagraph } from "./IntroBoxParagraph"

const fontColor = "#333333"
const maxWidth = 1033

export const IntroBoxes: React.FC = () => {
  const { colors, gaps } = useTheme()
  const { window } = useScreen()
  const shouldUseSingleColumnLayout = window.width < maxWidth + 2 * gaps[6]

  return (
    <div
      css={{
        display: "grid",
        gap: gaps[6],
        gridTemplateColumns: shouldUseSingleColumnLayout ? "auto" : "1fr 1fr 1fr",
        justifyContent: "center",
        margin: "0 auto",
        maxWidth,
        padding: `0 ${gaps[5]}px`
      }}
    >
      <IntroBox backgroundColor={colors.quinary[1]}>
        <IntroBoxHeader fontColor={fontColor}>Wypełnij test</IntroBoxHeader>
        <IntroBoxParagraph fontColor={fontColor}>
          Wskaż, w jakim stopniu zgadzasz się lub nie zgadzasz z każdym z oświadczeń. Odpowiedz szczerze i zaufaj swojemu instynktowi.
        </IntroBoxParagraph>
      </IntroBox>
      <IntroBox backgroundColor={colors.quinary[2]}>
        <IntroBoxHeader fontColor={fontColor}>Poznaj wyniki</IntroBoxHeader>
        <IntroBoxParagraph fontColor={fontColor}>To przede wszystkim wiedza o Twoim potencjale i predyspozycjach.</IntroBoxParagraph>
      </IntroBox>
      <IntroBox backgroundColor={colors.quinary[3]}>
        <IntroBoxHeader fontColor={fontColor}>Zyskaj w rekrutacji</IntroBoxHeader>
        <IntroBoxParagraph fontColor={fontColor} maxWidth={204}>
          Wyniki możesz załączyć do CV, co pomoże rekruterom poznać Twój profil.
        </IntroBoxParagraph>
      </IntroBox>
    </div>
  )
}
