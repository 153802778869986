import { put, takeLatest } from "redux-saga/effects"
import { PayloadAction } from "../../../../action/PayloadAction"
import { Injector } from "../../../../di/Injector"
import { propagateUnknownError } from "../../../../error/actions"
import { clearFlash } from "../../../../flash/actions"
import { BlackBoxGenerator } from "../../../../saga/types"
import { SurveyData } from "../../SurveyData"
import { setStage, UPLOAD, UploadPayload } from "../actions"

export function* watchUpload(injector: Injector): BlackBoxGenerator {
  yield takeLatest(UPLOAD, function* (action: PayloadAction<UploadPayload>): BlackBoxGenerator {
    yield put(clearFlash())
    const surveyService = injector.domain.survey.service
    const intervieweeService = injector.domain.interviewee.service
    const { routes } = injector
    const {
      formHelpers: { setSubmitting },
      navigate
    } = action.payload

    try {
      const intervieweeIdFromUrl = new URLSearchParams(injector.win.location.search).get("intervieweeId")
      const storedIntervieweeId = intervieweeService.getId()
      const data: SurveyData = {
        ...action.payload.data,
        intervieweeId: action.payload.data.intervieweeId || intervieweeIdFromUrl || storedIntervieweeId || undefined
      }
      yield put(setStage("Uploading"))
      const { intervieweeId, result, surveyInstanceId } = (yield surveyService.upload(data)) as Awaited<
        ReturnType<typeof surveyService.upload>
      >
      intervieweeService.setId(intervieweeId)
      setSubmitting(false)

      if (result != null) {
        yield put(setStage("Completed"))
        navigate(routes.survey.initial.result(surveyInstanceId))
      } else {
        yield put(setStage("PartiallyCompleted"))
      }
    } catch (error) {
      setSubmitting(false)
      yield put(propagateUnknownError(error))
      yield put(setStage("Open"))
    }
  })
}
