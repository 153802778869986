import { put, takeLatest } from "redux-saga/effects"
import { PayloadAction } from "../../../../action/PayloadAction"
import { Injector } from "../../../../di/Injector"
import { propagateUnknownError } from "../../../../error/actions"
import { clearFlash } from "../../../../flash/actions"
import { BlackBoxGenerator } from "../../../../saga/types"
import { SEND_RESULT_BY_EMAIL, SendResultByEmailPayload } from "../actions"

export function* watchSendResultByEmail(injector: Injector): BlackBoxGenerator {
  yield takeLatest(SEND_RESULT_BY_EMAIL, function* (action: PayloadAction<SendResultByEmailPayload>): BlackBoxGenerator {
    yield put(clearFlash())
    const {
      data,
      showModal,
      formHelpers: { setSubmitting }
    } = action.payload
    const service = injector.domain.survey.service
    try {
      yield service.sendResultByEmail(data)
      showModal()
      setSubmitting(false)
    } catch (error) {
      setSubmitting(false)
      yield put(propagateUnknownError(error))
    }
  })
}
