import { Config } from "../config/Config"
import { environment } from "./environment"

const common = {
  contact: {
    email: process.env.REACT_APP_CONTACT_EMAIL || "info@humancompetence.cloud"
  },
  graphql: {
    path: process.env.REACT_APP_GRAPHQL_PATH || "/api/v1/graphql"
  },
  hCaptcha: {
    siteKey: process.env.REACT_APP_HCAPTCHA_SITE_KEY
  }
}

const development: Config = {
  ...common,
  environment: "development",
  hCaptcha: {
    siteKey: common.hCaptcha.siteKey || "10000000-ffff-ffff-ffff-000000000001"
  }
}

const test: Config = {
  ...development,
  environment: "test"
}

const production: Config = {
  ...common,
  environment: "production",
  hCaptcha: {
    ...common.hCaptcha,
    siteKey: common.hCaptcha.siteKey || "e059b555-79bd-425a-b2f2-492fe86d8444"
  }
}

export const config: Config = (() => {
  switch (environment) {
    case "development":
      return development
    case "production":
      return production
    case "test":
      return test
    default:
      throw Error(`No config for the ${environment} environment`)
  }
})()
