/** @jsxImportSource @emotion/react */
import { CookiesBar } from "../../cookies/CookiesBar"
import { useTheme } from "../../design/hooks/useTheme"
import { useScreen } from "../../screen/hooks/useScreen"
import { HCCPowerLogoInv } from "../logo/HCCPowerLogoInv"
import { CopyRight } from "./CopyRight"
import { FooterLinks } from "./FooterLinks"

export const Footer: React.FC = () => {
  const { colors, gaps } = useTheme()
  const { window } = useScreen()
  const shouldUseSmallPadding = window.width < 604

  return (
    <footer
      css={{
        backgroundColor: colors.black,
        marginTop: "auto",
        padding: `64px ${shouldUseSmallPadding ? gaps[6] : 115}px`
      }}
    >
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "auto",
          rowGap: 24
        }}
      >
        <HCCPowerLogoInv />
        <FooterLinks />
        <CopyRight />
      </div>
      <CookiesBar />
    </footer>
  )
}
