/** @jsxImportSource @emotion/react */

import { useTheme } from "../hooks/useTheme"

type Props = {
  marginBottom?: number
  strong?: boolean
  textAlign?: "center" | "left"
}

export const P: React.FC<Props> = ({ children, marginBottom, strong, textAlign }) => {
  const { colors, fonts } = useTheme()
  return (
    <p
      css={{
        color: colors.neutral[700],
        fontFamily: fonts.inter,
        fontSize: "18px",
        fontWeight: strong ? 700 : 400,
        lineHeight: "28px",
        margin: 0,
        marginBottom,
        textAlign,
        "& + &": {
          marginTop: 28
        }
      }}
    >
      {children}
    </p>
  )
}
