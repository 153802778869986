/** @jsxImportSource @emotion/react */

import { Button } from "../../../../design/system/buttons/Button"
import { ButtonHorizontalContent } from "../../../../design/system/buttons/ButtonHorizontalContent"
import { H2 } from "../../../../design/system/headers/H2"
import { GreaterThan } from "../../../../design/system/icons/GreaterThan"
import { CloseableModal } from "../../../../modal/CloseableModal"

type Props = {
  title: string
  onClose: () => void
}

export const InstructionModal: React.FC<Props> = ({ children, onClose, title }) => (
  <CloseableModal maxWidth={634} onClose={onClose}>
    <H2 marginBottom={36} textAlign="center">
      {title}
    </H2>
    {children}
    <div
      css={{
        textAlign: "center",
        paddingTop: 36
      }}
    >
      <Button intent="Primary" onClick={onClose}>
        <ButtonHorizontalContent>
          Przejdź do testu <GreaterThan />
        </ButtonHorizontalContent>
      </Button>
    </div>
  </CloseableModal>
)
