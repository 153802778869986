/** @jsxImportSource @emotion/react */

import { dimensions } from "../../../../../../../../modules/domain/survey/hcc/HCCDimension"
import { HCCSurveyResult } from "../../../../../../../../modules/domain/survey/hcc/HCCSurveyResult"
import { DrawingArea } from "../../../../../../../charts/DrawingArea"
import { SpiderCurve } from "../../../../../../../charts/spider/SpiderCurve"
import { SpiderLabel } from "../../../../../../../charts/spider/SpiderLabel"
import { SpiderMesh } from "../../../../../../../charts/spider/SpiderMesh"
import { useTheme } from "../../../../../../../design/hooks/useTheme"
import { P } from "../../../../../../../design/system/P"
import { useMessages } from "../../../../../../../i18n/hooks/useMessages"

type Props = {
  result: HCCSurveyResult
}

const unitLength = 1 / 14
const spiderMeshSteps = 5
const spiderMeshRadius = spiderMeshSteps * unitLength
const maxScore = 40

export const HCCSurveyResultChart: React.FC<Props> = ({ result }) => {
  const { colors } = useTheme()
  const labels = useMessages().domain.hcc.dimensions
  const angleStep = (2 * Math.PI) / dimensions.length

  return (
    <DrawingArea height="100vh" maxHeight={525} maxWidth={655} width="100vw">
      <SpiderMesh radius={spiderMeshRadius} steps={spiderMeshSteps} />
      {dimensions.map((dimension, i) => (
        <SpiderLabel key={dimension} angle={i * angleStep}>
          <P textAlign="center">{labels[dimension]}</P>
        </SpiderLabel>
      ))}
      <SpiderCurve
        color={colors.primary[600]}
        values={dimensions.map((dimension) => (result[dimension].score / maxScore) * spiderMeshRadius)}
      />
    </DrawingArea>
  )
}
