/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom"
import { useTheme } from "../../design/hooks/useTheme"
import { useRoutes } from "../../routes/hooks/useRoutes"
import { useScreen } from "../../screen/hooks/useScreen"
import { HCCPowerLogo } from "../logo/HCCPowerLogo"
import { NavBarButtons } from "./NavBarButtons"
import { NavElement } from "./NavElement"

export const NavBar: React.FC = () => {
  const { gaps } = useTheme()
  const { window } = useScreen()
  const routes = useRoutes()
  const shouldUseSmallPadding = window.width < 960
  const shouldUseSingleColumnLayout = window.width < 818

  return (
    <div
      css={{
        alignItems: "center",
        display: shouldUseSingleColumnLayout ? "grid" : "flex",
        justifyContent: shouldUseSingleColumnLayout ? "center" : "space-between",
        padding: `25px ${shouldUseSmallPadding ? gaps[6] : 72}px`,
        position: "relative"
      }}
    >
      <div
        css={
          shouldUseSingleColumnLayout
            ? {
                textAlign: "center"
              }
            : {
                paddingRight: 32,
                position: "relative",
                top: 4
              }
        }
      >
        <HCCPowerLogo />
      </div>
      <nav>
        <ul
          css={{
            display: "flex",
            justifyContent: "center",
            listStyle: "none",
            margin: 0,
            padding: 0
          }}
        >
          <NavElement>
            <Link to={routes.index()}>Strona Główna</Link>
          </NavElement>
          <NavElement>
            <Link to={routes.survey.initial.index()}>Test</Link>
          </NavElement>
          <NavElement>
            <Link to={routes.contact()}>Kontakt</Link>
          </NavElement>
          <NavElement>
            <a href="https://app.humancompetence.cloud/candidate/login">Zaloguj się</a>
          </NavElement>
        </ul>
      </nav>
      <div
        css={{
          display: "none",
          marginLeft: shouldUseSingleColumnLayout ? 0 : "auto"
        }}
      >
        <NavBarButtons />
      </div>
    </div>
  )
}
