/** @jsxImportSource @emotion/react */

import { range } from "ramda"
import { SpiderMeshCircle } from "./SpiderMeshCircle"

type Props = {
  radius: number
  steps: number
}

export const SpiderMesh: React.FC<Props> = ({ radius, steps }) => {
  const step = radius / steps
  return (
    <>
      {range(0, steps).map((i) => (
        <SpiderMeshCircle key={i} radius={(i + 1) * step} />
      ))}
    </>
  )
}
