/** @jsxImportSource @emotion/react */
import styleIcon from "../../../../../images/pages/landing/bricks-covering.png"
import valuesIcon from "../../../../../images/pages/landing/bricks-horizontal.png"
import featuresIcon from "../../../../../images/pages/landing/bricks-vertical.png"
import { useTheme } from "../../../../design/hooks/useTheme"
import { P } from "../../../../design/system/P"
import { H1 } from "../../../../design/system/headers/H1"
import { useScreen } from "../../../../screen/hooks/useScreen"
import { Dimension } from "./Dimension"

const maxWidth = 1212

export const SectionDimensions: React.FC = () => {
  const { gaps } = useTheme()
  const { window } = useScreen()
  const shouldUseSingleColumnLayout = window.width < maxWidth + 2 * gaps[6]

  return (
    <section
      css={{
        padding: `96px ${gaps[6]}px`
      }}
    >
      <H1 textAlign="center">Poznaj lepiej siebie i swoje predyspozycje</H1>
      <div
        css={{
          height: shouldUseSingleColumnLayout ? "auto" : 177,
          margin: "0 auto 64px auto",
          maxWidth: 600,
          textAlign: "center"
        }}
      >
        <P>Wspólnie z psychologami przygotowaliśmy unikalne narzędzie, które zdiagnozuje Twój profil w dwóch wymiarach</P>
      </div>
      <div
        css={{
          display: "grid",
          gap: gaps[6],
          gridTemplateColumns: shouldUseSingleColumnLayout ? "auto" : "auto auto auto",
          justifyContent: "center"
        }}
      >
        <Dimension
          description="Czerpiesz energię z kontaktu z ludźmi, a może przeciwnie i jesteś bardziej introwertykiem? Bujna wyobraźnia, czy raczej konkret i pragmatyzm?"
          icon={featuresIcon}
          title="Cechy osobowości i&nbsp;wartości"
        />
        <Dimension
          description="Co jest dla Ciebie ważne? Lubisz zmiany, wykraczać poza utarte schematy? Czy raczej cenisz tradycję, spokój i poczucie bezpieczeństwa?"
          icon={valuesIcon}
          title="Wartości"
        />
        <Dimension
          description="Myślisz, że zawsze dasz sobie radę, czy raczej ogarnia Cię niepewność, co zrobić w nowej sytuacji? Myślisz raczej samodzielnie, czy wolisz popatrzeć co robią inni?"
          icon={styleIcon}
          title="Styl myślenia"
        />
      </div>
    </section>
  )
}
