/** @jsxImportSource @emotion/react */
import { useTheme } from "../../../../design/hooks/useTheme"
import { H2 } from "../../../../design/system/headers/H2"

type Props = { title: string; maxWidth: number }

export const WhyBox: React.FC<Props> = ({ children, maxWidth, title }) => {
  const { colors } = useTheme()
  return (
    <div
      css={{
        backgroundColor: colors.white,
        borderRadius: 8,
        padding: 32,
        margin: "0 auto",
        maxWidth
      }}
    >
      <H2 marginBottom={28} textAlign="left">
        {title}
      </H2>
      {children}
    </div>
  )
}
