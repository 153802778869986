import { Messages } from "../Messages"

export const pl: Messages = {
  collapse: "Zwiń",
  domain: {
    hcc: {
      dimensions: {
        dilligence: "Sumienność",
        emotionalStability: "Stabilność emocjonalna",
        empathy: "Empatia",
        extraversion: "Ekstrawersja",
        independence: "Niezależność",
        openness: "Otwartość",
        professionalSelfEfficacy: "Samoskuteczność zawodowa"
      }
    }
  },
  expand: "Rozwiń",
  form: {
    file: {
      browse: "Przeglądaj",
      choose: {
        multiple: "Wybierz pliki",
        one: "Wybierz plik"
      }
    },
    send: "Wyślij",
    validation: {
      field: {
        required: "To pole jest wymagane"
      }
    }
  },
  no: "Nie",
  yes: "Tak"
}
