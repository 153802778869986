/** @jsxImportSource @emotion/react */

import { Formik } from "../../../../../../modules/form/Formik"
import { useTheme } from "../../../../../design/hooks/useTheme"
import { FormFieldError } from "../../../../../design/system/form/FormFieldError"
import { CompactRegulations } from "../../../../../design/system/regulations/compact/CompactRegulations"
import { CompactRegulationsCheckbox } from "../../../../../design/system/regulations/compact/CompactRegulationsCheckbox"
import { ContactEmail } from "../../../../../domain/ContactEmail"
import { InitialSurveyFormValues } from "./InitialSurveyFormValues"

type Props = {
  formik: Formik<InitialSurveyFormValues>
}

export const InitialSurveyRegulations: React.FC<Props> = ({ formik }) => {
  const { colors, gaps } = useTheme()

  return (
    <div
      css={{
        borderBottom: `solid 2px ${colors.neutral[300]}`,
        margin: "0 auto",
        maxWidth: 1024
      }}
    >
      <CompactRegulations isCollapsable>
        <p>
          Zgodnie z art. 13 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
          fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy
          95/46/WE (ogólne rozporządzenie o ochronie danych), zwane dalej „RODO”, informujemy, że:
        </p>
        <ol>
          <li>
            Administratorem Pani/Pana danych osobowych jest Peaksales Spółka z o.o. z siedzibą w Warszawie 02-057, ul. Filtrowa 39, wpisana
            do Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st.Warszawy, XII Wydział Gospodarczy Krajowego Rejestru
            Sądowego pod numerem KRS 0000784488 posiadająca numer identyfikacji podatkowej NIP: 5272892222 oraz numer statystyczny REGON:
            383240227, zwaną dalej „Peaksales” lub „Administratorem”.
          </li>
          <li>
            W sprawie ochrony swoich danych osobowych może Pani/Pan skontaktować się bezpośrednio z Administratorem pod adresem email:
            <ContactEmail />
          </li>
          <li>
            Przetwarzamy Pani/Pana dane osobowe na podstawie:
            <ul>
              <li>art. 6 ust.1 lit. c RODO</li>
              <li>
                art. 221 Kodeksu pracy, upoważnia nas do przetwarzania danych w celu zawarcia umowy o pracę w zakresie takich danych jak:
                imię (imiona) i nazwisko, data urodzenia, adres do korespondencji wg. Pana/Pani uznania. Na potrzeby przeprowadzenia procesu
                rekrutacji przetwarzamy również takie dane jak: wykształcenie, przebieg dotychczasowego zatrudnienia.
              </li>
              <li>
                art. 6 ust.1 lit. a RODO tj. zgoda, w zakresie innych danych niż wymienione powyżej. Jeżeli Pani/Pana aplikacja zawiera
                takie dane.
              </li>
              <li>art. 6 ust.1 lit. f RODO tj. prawnie uzasadniony interes Administratora.</li>
              <li>
                W trakcie procesu rekrutacji możemy być zainteresowani przeprowadzaniem rozmowy kwalifikacyjnej ewentualnie poprosić o
                rozwiązanie określonych testów kwalifikacyjnych. Mamy uzasadniony interes w tym, aby wykorzystując dane pozyskane w trackie
                takiej rozmowy kwalifikacyjnej lub testu sprawdzić Pani/Pana umiejętności na oferowane stanowisko.
              </li>
            </ul>
          </li>
          <li>
            Odbiorcami danych osobowych mogą być upoważnieni pracownicy Administratora, Współadministratorzy oraz inne osoby działające z
            upoważnienia Administratora, sądy oraz inne organy publiczne upoważnione do odbioru Pani/Pana danych na podstawie odpowiednich
            przepisów prawa. Dane mogą być udostępnione podmiotom z którymi Administrator zawarł Umowę Powierzenia Przetwarzania.
          </li>
          <li>
            Pani/Pana dane osobowe zgromadzone w postępowaniu rekrutacyjnym będą przetwarzane przez okres 5 lat lub do momentu wycofania
            zgody na ich przetwarzanie (w zakresie objętym zgodą).
          </li>
          <li>
            Przysługują Pani/Panu następujące prawa: a) prawo dostępu do swoich danych oraz otrzymania ich kopii; b) prawo sprostowania
            (poprawiania) swoich danych; c) prawo usunięcia, ograniczenia lub wniesienia sprzeciwu wobec ich przetwarzania; d) prawo
            przenoszenia danych; e) prawo wniesienia skargi do organu nadzorczego (Prezesa Urzędu Ochrony Danych Osobowych), w przypadku
            uznania, że przetwarzanie danych osobowych narusza przepisy RODO.
          </li>
        </ol>
      </CompactRegulations>
      <div
        css={{
          display: "grid",
          paddingBottom: 88,
          paddingTop: 38,
          rowGap: gaps[5]
        }}
      >
        <div>
          <CompactRegulationsCheckbox
            hasErrors={formik.errors.consents?.[1] != null}
            label="Wyrażam zgodę na przetwarzanie moich danych osobowych dla potrzeb niezbędnych do realizacji bieżącego procesu rekrutacji prowadzonego w Peaksales Spółka z o.o."
            value={formik.values.consents[1]}
            onChange={(value) => formik.setFieldValue("consents", { ...formik.values.consents, 1: value })}
          />
          <FormFieldError message={formik.errors.consents?.[1]} />
        </div>
        <div>
          <CompactRegulationsCheckbox
            hasErrors={formik.errors.consents?.[2] != null}
            label="Wyrażam zgodę na przetwarzanie moich danych osobowych na potrzeby przyszłych rekrutacji prowadzonego w Peaksales Spółka z o.o."
            value={formik.values.consents[2]}
            onChange={(value) => formik.setFieldValue("consents", { ...formik.values.consents, 2: value })}
          />
          <FormFieldError message={formik.errors.consents?.[2]} />
        </div>
      </div>
    </div>
  )
}
