import { SurveyAnswerInput } from "../../../../../graphql/types.generated"
import { SurveyInstanceAnswers } from "../../../SurveyInstanceAnswers"

export const createAnswerConverterToInput =
  (answers: SurveyInstanceAnswers) =>
  (questionId: string): SurveyAnswerInput => {
    const value = answers[questionId]

    switch (typeof value) {
      case "boolean":
        return { questionId, booleanAnswer: value }
      case "number":
        return { questionId, scaleAnswer: value }
      case "string":
        return { questionId, definedAnswerId: value }
      default:
        throw Error(`Unexpected answer type ${typeof value}`)
    }
  }
