/** @jsxImportSource @emotion/react */
import { useTheme } from "../../../../../../../design/hooks/useTheme"
import { P } from "../../../../../../../design/system/P"
import { H0 } from "../../../../../../../design/system/headers/H0"
import { NavBar } from "../../../../../../../domain/navBar/NavBar"
import { FlashBar } from "../../../../FlashBar"
import { SectionTopBackground } from "./SectionTopBackground"

export const SectionTop: React.FC = () => {
  const { colors, gaps } = useTheme()

  return (
    <section
      css={{
        backgroundColor: "rgba(242, 247, 255, 0.7)",
        overflow: "hidden"
      }}
    >
      <NavBar />
      <div
        css={{
          position: "relative"
        }}
      >
        <SectionTopBackground />
        <FlashBar />
        <div
          css={{
            display: "grid",
            margin: "0 auto",
            maxWidth: 1080,
            padding: `146px ${gaps[5]}px 228px`,
            position: "relative",
            rowGap: gaps[4]
          }}
        >
          <H0 textAlign="center">Wynik testu osobowości</H0>
          <P textAlign="center">
            Kwestionariusz jest narzędziem do badania pracowników oraz kandydatów pod względem różnic indywidualnych. Przedstawiamy skale,
            które mierzą obszar funkcjonowania emocjonalno-społeczno-motywacyjnego i odpowiadają na pytanie: jaki jesteś. Mierzą wartości,
            które preferujesz, co jest dla Ciebie ważne, co Cię motywuje. Odpowiadają też na pytanie, w jaki sposób myślisz i podchodzisz do
            zadań zawodowych.
          </P>
          <P textAlign="center" strong>
            Zapoznaj się z poniższymi opisami{" "}
            <span css={{ color: colors.primary[600] }}>6 wymiarów dotyczących cech osobowości, wartości oraz stylów myślenia w pracy</span>{" "}
            szczególnie istotnych w pracy zdalnej oraz w pracach projektowych wykonywanych w formie freelance
          </P>
        </div>
      </div>
    </section>
  )
}
