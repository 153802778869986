import { combineReducers, Reducer } from "redux"
import { Action } from "../action/Action"
import { ScreenState } from "./ScreenState"
import { isFocused } from "./reducers/isFocused"
import { isVisible } from "./reducers/isVisible"
import { window } from "./reducers/window"

export const screen: Reducer<ScreenState, Action> = combineReducers({
  isFocused,
  isVisible,
  window
})
