import { put, takeEvery } from "redux-saga/effects"
import { PayloadAction } from "../../action/PayloadAction"
import { setFlash } from "../../flash/actions"
import { BlackBoxGenerator } from "../../saga/types"
import { PROPAGATE_SIMPLE_MESSAGE_ERROR } from "../actions"

export function* watchSimpleMessageError(): BlackBoxGenerator {
  yield takeEvery(PROPAGATE_SIMPLE_MESSAGE_ERROR, function* (action: PayloadAction<string>) {
    const message = action.payload
    yield put(
      setFlash({
        intent: "Danger",
        message
      })
    )
  })
}
