import { combineReducers, Reducer } from "redux"
import { Action } from "../../../action/Action"
import { InitialSurveyState } from "./InitialSurveyState"
import { definition } from "./reducers/definition"
import { instance } from "./reducers/instance"
import { result } from "./reducers/result"
import { stage } from "./reducers/stage"

export const initial: Reducer<InitialSurveyState, Action> = combineReducers({
  definition,
  instance,
  result,
  stage
})
