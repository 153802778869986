import { put, takeLatest } from "redux-saga/effects"
import { PayloadAction } from "../../../../action/PayloadAction"
import { Injector } from "../../../../di/Injector"
import { propagateUnknownError } from "../../../../error/actions"
import { unknownErrorToString } from "../../../../error/unknownErrorToString"
import { clearFlash } from "../../../../flash/actions"
import { BlackBoxGenerator } from "../../../../saga/types"
import { GET_INSTANCE, setDefinition, setInstance, setResult } from "../actions"

export function* watchGetInstance(injector: Injector): BlackBoxGenerator {
  yield takeLatest(GET_INSTANCE, function* (action: PayloadAction<string>): BlackBoxGenerator {
    yield put(clearFlash())

    try {
      const service = injector.domain.survey.service
      const instance = (yield service.getInstance(action.payload)) as Awaited<ReturnType<typeof service.getInstance>>
      yield put(setInstance(instance))
      yield put(setDefinition(instance.definition))
      yield put(setResult(instance.result))
      if (instance.intervieweeId) {
        injector.domain.interviewee.service.setId(instance.intervieweeId)
      }
    } catch (error) {
      yield put(propagateUnknownError(error))
      const message = unknownErrorToString(error)
      yield put(setInstance(message))
      yield put(setResult(message))
    }
  })
}
