/** @jsxImportSource @emotion/react */

import { useTheme } from "../../../../../../../../design/hooks/useTheme"

type Props = {
  href: string
}

export const FooterLink: React.FC<Props> = ({ children, href }) => {
  const { colors, fonts } = useTheme()

  return (
    <a
      css={{
        color: colors.primary[600],
        fontFamily: fonts.inter,
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "28px"
      }}
      href={href}
    >
      {children}
    </a>
  )
}
