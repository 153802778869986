/** @jsxImportSource @emotion/react */
import { useEffect } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { InitialSurveyState } from "../../../../../../modules/domain/survey/initial/InitialSurveyState"
import { getInstance } from "../../../../../../modules/domain/survey/initial/actions"
import { MainState } from "../../../../../../modules/state/MainState"
import { Spinner } from "../../../../../design/system/Spinner"
import { Footer } from "../../../../../domain/footer/Footer"
import { Error404 } from "../../../../errors/Error404"
import { SectionChart } from "./sections/chart/SectionChart"
import { SectionDescriptions } from "./sections/descriptions/SectionDescriptions"
import { SectionSendResult } from "./sections/send/SectionSendResult"
import { SectionTop } from "./sections/top/SectionTop"

const mapState = (state: MainState): InitialSurveyState => state.domain.survey.initial

export const HCCSurveyResultPage: React.FC = () => {
  const { result } = useSelector(mapState, shallowEqual)
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const instanceId = searchParams.get("instanceId")

  useEffect(() => {
    if (instanceId) {
      dispatch(getInstance(instanceId))
    }
  }, [dispatch, instanceId])

  useEffect(() => {
    if (result != null) {
      window.scrollTo({ behavior: "smooth", top: 0 })
    }
  }, [result])

  return instanceId != null ? (
    <>
      <SectionTop />
      {result != null && typeof result !== "string" ? (
        <>
          <SectionChart result={result} />
          <SectionDescriptions result={result} />
        </>
      ) : (
        result == null && <Spinner />
      )}
      <SectionSendResult surveyInstanceId={instanceId} />
      <Footer />
    </>
  ) : (
    <Error404 />
  )
}
