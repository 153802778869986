import { Injector } from "../../di/Injector"
import { noop } from "../../saga/noop"
import { BlackBoxGenerator } from "../../saga/types"
import { debounce } from "../../time/debounce"
import { setWindow } from "../actions"

export function* watchWindowResize(injector: Injector): BlackBoxGenerator {
  injector.win.addEventListener(
    "resize",
    debounce(function (this: Window) {
      injector.store.dispatch(
        setWindow({
          height: this.innerHeight,
          width: this.innerWidth
        })
      )
    }, 100)
  )

  yield* noop()
}
