/** @jsxImportSource @emotion/react */
import guyAtDesk from "../../../../../images/pages/landing/guy-at-desk.png"
import { useTheme } from "../../../../design/hooks/useTheme"
import { BlurredCircle } from "../../../../design/system/BlurredCircle"
import { useScreen } from "../../../../screen/hooks/useScreen"

type Props = {
  saveWidth: number
}

export const SectionTopBackground: React.FC<Props> = ({ saveWidth }) => {
  const { colors } = useTheme()
  const { window } = useScreen()
  const distanceThreshold = window.width - saveWidth
  const guyAtDeskWidth = 552

  return (
    <>
      <div
        css={{
          background: "linear-gradient(to left top, white 50%, transparent 0)",
          bottom: 0,
          height: 178,
          left: 0,
          position: "absolute",
          width: "100%"
        }}
      ></div>
      <div
        css={{
          position: "absolute",
          top: -37,
          right: -53
        }}
      >
        <BlurredCircle color={colors.secondary[600]} radius={203} />
      </div>
      <div
        css={{
          position: "absolute",
          right: 339,
          bottom: 120
        }}
      >
        <BlurredCircle color={colors.secondary[600]} radius={84} />
      </div>
      {distanceThreshold >= guyAtDeskWidth && (
        <div
          css={{
            position: "absolute",
            right: distanceThreshold >= 104 + guyAtDeskWidth ? 104 : 0,
            bottom: 8
          }}
        >
          <img alt="Guy at desk" height={578} src={guyAtDesk} width={guyAtDeskWidth} />
        </div>
      )}
    </>
  )
}
