/** @jsxImportSource @emotion/react */

import { Intent } from "../../../modules/intent/Intent"
import { useTheme } from "../hooks/useTheme"

type Props = {
  intent: Extract<"Success", Intent>
}

export const IntentMessage: React.FC<Props> = ({ children }) => {
  const { colors, fonts } = useTheme()

  return (
    <span
      css={{
        color: colors.success[900],
        fontFamily: fonts.inter,
        fontSize: 18,
        fontWeight: 700,
        lineHeight: "20px"
      }}
    >
      {children}
    </span>
  )
}
