import { CombinedState, combineReducers, Reducer } from "redux"
import { Action } from "../modules/action/Action"
import { domain } from "../modules/domain/reducer"
import { flash } from "../modules/flash/reducer"
import { language } from "../modules/i18n/reducer"
import { screen } from "../modules/screen/reducer"
import { MainState } from "../modules/state/MainState"

export const reducer: Reducer<CombinedState<MainState>, Action> = combineReducers<MainState, Action>({
  domain,
  flash,
  language,
  screen
})
