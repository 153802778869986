/** @jsxImportSource @emotion/react */

import { FadeInOut } from "./FadeInOut"
import { ScrollTo } from "./ScrollTo"

type Props = {
  animationDuration: number
  children: JSX.Element[]
  idx?: number
  opacityOnOut: number
}

export const OpacityFocus: React.FC<Props> = ({ animationDuration, children, idx, opacityOnOut }) => {
  return (
    <>
      {children.map((element, i) => (
        <ScrollTo key={element.key} visible={i === idx}>
          <FadeInOut duration={animationDuration} opacityOnOut={opacityOnOut} visible={i === idx}>
            {element}
          </FadeInOut>
        </ScrollTo>
      ))}
    </>
  )
}
