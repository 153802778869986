/** @jsxImportSource @emotion/react */

import { P } from "../../../../../design/system/P"
import { StandardRegulations as Regulations } from "../../../../../design/system/regulations/standard/StandardRegulations"
import { StandardRegulationsHeader as Header } from "../../../../../design/system/regulations/standard/StandardRegulationsHeader"
import { StandardRegulationsItem as Item } from "../../../../../design/system/regulations/standard/StandardRegulationsItem"
import { StandardRegulationsSubHeader as SubHeader } from "../../../../../design/system/regulations/standard/StandardRegulationsSubHeader"
import { ContactEmail } from "../../../../../domain/ContactEmail"
import { ServiceLink } from "../../../ServiceLink"

export const PrivacyPolicy: React.FC = () => (
  <Regulations>
    <Header>Polityka Prywatności</Header>
    <P>
      Peaksales rozumie jak istotna jest prywatność w dzisiejszych czasach. Bezpieczeństwo danych osobowych użytkowników naszej strony
      internetowej jest zatem naszym priorytetem. Jednocześnie chcemy być transparentni. Przygotowaliśmy wobec tego politykę prywatności,
      gdzie użytkownik może uzyskać informacje na temat przetwarzania jego danych osobowych, w tym praw jakie mu w związku z tym
      przysługują.
    </P>
    <P>
      Polityka prywatności znajduje zastosowanie do osób odwiedzających naszą stronę internetową, w tym osób, które założyły konto a także
      wypełniły test osobowości i załączyły swoje CV. Osoby te będą dalej zwane „Użytkownikami”.
    </P>
    <P>
      W dalszej części będziemy posługiwali się skrótem RODO, przez co należy rozumieć: Rozporządzenie Parlamentu Europejskiego i Rady (UE)
      2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
      swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE .
    </P>
    <SubHeader value="I.">Kto zarządza stroną internetową i danymi osobowymi Użytkowników?</SubHeader>
    <P>
      Użytkownik odwiedzający stronę internetową dostępną pod adresem: <ServiceLink /> zwanej dalej „Portalem” przekazuje dane osobowe na
      różnych etapach interakcji z Portalem. Użytkownik proszony jest o podanie danych osobowych przy zakładaniu konta, wypełnianiu
      formularza kontaktowego a także na etapie wykonywania testu i załączania CV.
    </P>
    <P>
      Administratorem podanych danych osobowych, czyli podmiotem który zarządza Portalem i przekazanymi tam danymi osobowymi jest Peaksales
      sp. z o.o. z siedzibą w Warszawie (02-057), przy ul. Filtrowej 39, KRS 0000784488, dalej zwany „Administratorem” lub jako „my”.
    </P>
    <SubHeader value="II.">Jak można się z nami skontaktować?</SubHeader>
    <P>Użytkownik może się z nami skontaktować:</P>
    <Item value="1)">
      tradycyjnie – poprzez korespondencję na adres: Peaksales sp. z o.o. z siedzibą w Warszawie (02-057), ul. Filtrowej 39;
    </Item>
    <Item value="2)">
      elektronicznie – poprzez email na adres <ContactEmail />.
    </Item>
    <SubHeader value="III.">W jakich celach, na jakiej podstawie i jak długą są przetwarzane dane osobowe Użytkowników?</SubHeader>
    <table width="100%">
      <thead>
        <tr>
          <th>
            <P>Cele przetwarzania</P>
          </th>
          <th>
            <P>Podstawa prawna</P>
          </th>
          <th>
            <P>Okres przechowywania</P>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <P>
              Świadczenie usług drogą elektroniczną poprzez umożliwienie Użytkownikowi wypełnienia testu osobowości oraz uzyskania wyników.
              Podanie danych osobowych jest warunkiem zawarcia umowy. Ich niepodanie skutkuje brakiem możliwości wypełnienia testu oraz
              otrzymania wyników z jego przeprowadzenia.
            </P>
          </td>
          <td>
            <P>art. 6 ust. 1 lit. b RODO</P>
          </td>
          <td>
            <P>Dane użytkowników będą przechowywane przez czas trwania umowy tj. do momentu rozwiązania umowy.</P>
          </td>
        </tr>
        <tr>
          <td>
            <P>Przeprowadzenie procesu rekrutacyjnego, jeżeli Użytkownik wypełnia test w związku z prowadzoną aktualnie rekrutacją.</P>
            <P>Podanie danych jest dobrowolne i nie wpływa na możliwość udziału w rekrutacji.</P>
          </td>
          <td>
            <P>art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes polegający na sprawdzeniu osobowości kandydata)</P>
          </td>
          <td>
            <P>Przez czas trwania procesu rekrutacyjnego a następnie przez czas zatrudnienia</P>
          </td>
        </tr>
        <tr>
          <td>
            <P>Dołączenie do bazy kandydatów poprzez dobrowolne załączenie CV po wykonanym teście osobowości.</P>
          </td>
          <td>
            <P>art. 6 ust. 1 lit. b RODO</P>
          </td>
          <td>
            <P>Dane użytkowników będą przechowywane przez czas trwania umowy tj. do momentu rozwiązania umowy.</P>
          </td>
        </tr>
        <tr>
          <td>
            <P>
              Udzielanie odpowiedzi na pytania zawarte poprzez formularz kontaktowy. Podanie danych osobowych oznaczonych gwiazdką jest
              obowiązkowe i jest konieczne w celu udzielenia odpowiedzi na pytanie. Inne dane osobowe podawane są dobrowolnie i nie ma
              negatywnych konsekwencji ich niepodania.
            </P>
          </td>
          <td>
            <P>art. 6 ust. lit. f RODO (prawnie uzasadniony interes polegający na komunikacji z Użytkownikami strony)</P>
          </td>
          <td>
            <P>Przez czas niezbędny dla udzielenia odpowiedzi lub do momentu zgłoszenia skutecznego sprzeciwu wobec przetwarzania</P>
          </td>
        </tr>
        <tr>
          <td>
            <P>Wysyłanie treści marketingowych (za pośrednictwem poczty email). Podanie danych osobowych jest dobrowolne.</P>
          </td>
          <td>
            <P>art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes polegający na promowaniu działalności Administratora)</P>
          </td>
          <td>
            <P>Do momentu zgłoszenia skutecznego sprzeciwu wobec przetwarzania lub wycofania zgody na marketing bezpośredni</P>
          </td>
        </tr>
        <tr>
          <td>
            <P>Wykorzystanie plików cookie. Podanie danych osobowych jest dobrowolne.</P>
          </td>
          <td>
            <P>
              art. 6 ust. 1 lit. f RODO w przypadku plików marketingowych, które potencjalnie mogą wiązać się z przetwarzaniem danych
              osobowych.
            </P>
          </td>
          <td>
            <P>Polityka cookie</P>
          </td>
        </tr>
        <tr>
          <td>
            <P>Ustalenie, dochodzenie, obrona roszczeń.</P>
          </td>
          <td>
            <P>art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes polegający na zapewnieniu skutecznej ochrony prawnej)</P>
          </td>
          <td>
            <P>Do momentu przedawnienia ewentualnych roszczeń</P>
          </td>
        </tr>
      </tbody>
    </table>
    <SubHeader value="IV.">Komu możemy przekazać dane osobowe Użytkowników?</SubHeader>
    <P>Dostęp do danych osobowych Użytkowników mogą mieć:</P>
    <Item value="a)">odmioty wspierające funkcjonowanie Portalu i działalność Administratora od strony informatycznej;</Item>
    <P>
      Przetwarzanie danych osobowych Użytkowników przez te podmioty zabezpieczone jest odpowiednimi umowami powierzenia danych osobowych;
    </P>
    <Item value="b)">
      niezależni administratorzy: np. organy państwowe, które mogą uzyskać dostęp do danych osobowych na podstawie przepisów prawa.
    </Item>
    <P>Co istotne – dane osobowe Użytkowników są przetwarzane wyłącznie na terenie Unii Europejskiej.</P>
    <SubHeader value="V.">Jakie prawa przysługują Użytkownikom?</SubHeader>
    <P>Użytkownikom przysługują następujące prawa w zakresie przetwarzania przez Administratora ich danych osobowych:</P>
    <Item value="1)">Prawo do żądania od Administratora dostępu do danych osobowych;</Item>
    <Item value="2)">Prawo do sprostowania danych osobowych;</Item>
    <Item value="3)">Prawo do usunięcia danych osobowych;</Item>
    <Item value="4)">Prawo do ograniczenia przetwarzania;</Item>
    <Item value="5)">Prawo do wniesienia sprzeciwu wobec przetwarzania;</Item>
    <Item value="6)">Prawo do przenoszenia danych osobowych;</Item>
    <Item value="7)">
      Prawo do cofnięcia zgody na przetwarzanie danych osobowych, w dowolnym momencie, co pozostaje jednak bez wpływu na zgodność
      przetwarzania z prawem, którego dokonano na podstawie zgody przed jej cofnięciem.
    </Item>
    <P>Realizacja niektórych prawa może być ograniczona z uwagi na przepisy prawa lub uzasadnione interesy Administratora.</P>
    <P>
      Użytkownik może złożyć żądanie realizacji ww. praw, w tym wycofać zgodę na przetwarzanie danych osobowych pod adresem:{" "}
      <ContactEmail />.
    </P>
    <SubHeader value="VI.">Czy Użytkownik ma prawo zaskarżyć Administratora?</SubHeader>
    <P>
      Użytkownik ma prawo wniesienia skargi do organu nadzorczego nad ochroną danych osobowych. W Polsce organem tym jest Prezes Urzędu
      Ochrony Danych Osobowych (ul. Stawski 2, 00 - 193 Warszawa).
    </P>
    <SubHeader value="VII.">Nie chcesz otrzymywać już treści marketingowych? </SubHeader>
    <P>
      Jeżeli nie chcesz otrzymywać od nas treści marketingowych możesz w dowolnym momencie wycofać na to zgodę. Wystarczy, że wyślesz do nas
      takiego maila na adres: <ContactEmail />.
    </P>
  </Regulations>
)
