/** @jsxImportSource @emotion/react */

import { P } from "../../../../../design/system/P"
import { StandardRegulations as Regulations } from "../../../../../design/system/regulations/standard/StandardRegulations"
import { StandardRegulationsHeader as Header } from "../../../../../design/system/regulations/standard/StandardRegulationsHeader"
import { StandardRegulationsItem as Item } from "../../../../../design/system/regulations/standard/StandardRegulationsItem"
import { ContactEmail } from "../../../../../domain/ContactEmail"

export const CookiesPolicy: React.FC = () => (
  <Regulations>
    <Header>Polityka Cookies</Header>
    <P marginBottom={80}>
      Poniższy wzór polityki cookies chroniony jest prawem autorskim, które przysługują IAB Polska i został pobrany ze strony:{" "}
      <a href="http://wszystkoociasteczkach.pl/" target="_blank" rel="noreferrer">
        http://wszystkoociasteczkach.pl/
      </a>
    </P>
    <Item value="1.">Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem informacji zawartych w plikach cookies.</Item>
    <Item value="2.">
      Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu
      końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony
      internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.
    </Item>
    <Item value="3.">
      Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator
      Serwisu Peaksales sp z o.o. z siedzibą pod adresem ul. Filtrowa 29, 02-057 Warszawa
    </Item>
    <Item value="4.">
      Pliki cookies wykorzystywane są w celu:
      <Item value="a.">
        dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika oraz optymalizacji korzystania ze stron
        internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika Serwisu i odpowiednio wyświetlić stronę
        internetową, dostosowaną do jego indywidualnych potrzeb;
      </Item>
      <Item value="b.">
        tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych, co umożliwia
        ulepszanie ich struktury i zawartości;
      </Item>
      <Item value="c.">
        w przypadku logowania - utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki której Użytkownik nie musi na każdej
        podstronie Serwisu ponownie wpisywać loginu i hasła;
      </Item>
    </Item>
    <Item value="5.">
      W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies).
      Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia
      strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu
      końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.
    </Item>
    <Item value="6.">
      W ramach Serwisu stosowane są następujące rodzaje plików cookies:
      <Item value="a.">
        „niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach Serwisu, np. uwierzytelniające pliki cookies
        wykorzystywane do usług wymagających uwierzytelniania w ramach Serwisu;
      </Item>
      <Item value="b.">
        pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach
        Serwisu;
      </Item>
      <Item value="c.">
        „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych Serwisu;
      </Item>
      <Item value="d.">
        „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez Użytkownika ustawień i personalizację interfejsu
        Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony
        internetowej itp.;
      </Item>
      <Item value="e.">
        „reklamowe” pliki cookies, umożliwiające dostarczanie Użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań.
      </Item>
    </Item>
    <Item value="7.">
      W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa) domyślnie dopuszcza
      przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień
      dotyczących plików cookies. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę
      plików cookies w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika
      Serwisu. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania
      (przeglądarki internetowej).
    </Item>
    <Item value="8.">
      Operator Serwisu informuje, że ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach
      internetowych Serwisu.
    </Item>
    <Item value="9.">
      Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu i wykorzystywane mogą być również przez współpracujących z
      operatorem Serwisu reklamodawców oraz partnerów.
    </Item>
    <Item value="10.">
      Więcej informacji na temat plików cookies dostępnych jest pod adresem <ContactEmail /> lub w sekcji „Pomoc” w menu przeglądarki
      internetowej.
    </Item>
  </Regulations>
)
