/** @jsxImportSource @emotion/react */

import { useCallback, useState } from "react"
import { SurveyDefinition } from "../../../../../../modules/domain/survey/SurveyDefinition"
import { FormikSubmitFunction } from "../../../../../../modules/form/FormikSubmitFunction"
import { textLinkCSS } from "../../../../../design/css/textLinkCSS"
import { useTheme } from "../../../../../design/hooks/useTheme"
import { FinishLaterModal } from "../../later/FinishLaterModal"
import { InitialSurveyFormValues } from "./InitialSurveyFormValues"

type Props = {
  definition: SurveyDefinition
  initialFormValues: InitialSurveyFormValues
  onSubmit: FormikSubmitFunction<InitialSurveyFormValues>
}

export const FinishLaterSubsection: React.FC<Props> = ({ definition, initialFormValues, onSubmit }) => {
  const theme = useTheme()
  const [isModalVisible, setModalVisibility] = useState(false)
  const showModal = useCallback(() => setModalVisibility(true), [setModalVisibility])
  const hideModal = useCallback(() => setModalVisibility(false), [setModalVisibility])

  return (
    <div
      css={{
        paddingTop: 49,
        textAlign: "center"
      }}
    >
      <span css={textLinkCSS({ theme })} onClick={showModal}>
        Chcesz dokończyć test później?
      </span>
      {isModalVisible && (
        <FinishLaterModal onSubmit={onSubmit} definition={definition} initialFormValues={initialFormValues} onClose={hideModal} />
      )}
    </div>
  )
}
