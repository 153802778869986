/** @jsxImportSource @emotion/react */
import { useState } from "react"
import { useTheme } from "../../../../design/hooks/useTheme"
import { H1 } from "../../../../design/system/headers/H1"
import { NavArrows } from "./NavArrows"
import { Testimonial } from "./Testimonial"
import { TestimonialsFaces } from "./TestimonialsFaces"

const animationDuration = 500

const testimonials: string[] = [
  "Ania: “W czasie studiów ciągle próbowałam zrozumieć czy jestem bardziej humanistą, czy ścisłowcem. Dopiero w pierwszej pracy zrozumiałam, że nie da się mnie tak prosto zaklasyfikować. Mam dużo kreatywnych pomysłów, lubię pisać, malować. Jednak kiedy w pracy muszę zmierzyć się z trudnym zadaniem, dużo planuję, liczę, sprawdzam, aby nie nawalić”",
  "Lena: “Super test, który nie „szufladkuje” do mało precyzyjnych kategorii, ale pokazuje, jak się przekładają twoje, bardzo indywidualne cechy do konkretnych sytuacji, które mogą cię spotkać w pracy. Za pomocą testu przypomniałam sobie, że spontaniczny i aktywny styl mojego życia niekoniecznie powinien się przekładać na styl mojej pracy, gdyż w pracy lubię działać bez pośpiechu i we własnym tempie.“",
  "Kasia: “W przeszłości nie raz zdarzało mi się rozwiązywać testy osobowości, ale jeszcze nigdy nie spotkałam takiego, który by dawał tak obszerną i dokładną odpowiedź - jestem bardzo pozytywnie zaskoczona. Nigdy nie przeczytałam o sobie prawdziwszego zdania niż: „Z reguły udaje Ci się zdążyć na czas ze swoimi zobowiązaniami, choć czasem robisz to w ostatnim momencie i żałujesz, że zbyt wolno pracowałaś nad danym zadaniem, gdy miałaś jeszcze dużo czasu.” - tak, czasem odkładam obowiązki na ostatnią chwilę, czego potem żałuję, ale zawsze się z nich wywiązuję.“",
  "Hanka: “Od czasu chodzenia do liceum, zawsze zastanawiałam się co powinnam robić w życiu i gdzie jest moje miejsce. Nie wiedziałam tak naprawdę czego chce i gdzie chce być. Wiedziałam tylko jedno, że nie cierpię rutyny w swojej codzienności. Dopiero z czasem, próbując wielu nowych rzeczy, wyjeżdżając za granicę, a także podejmując pracę w różnych branżach odkryłam to, co tak naprawdę mnie kręci i motywuje do działania. Test idealnie potwierdził moje przekonania. Lubię nowe wyzwania, a praca w zespole i dobra atmosfera tylko nakręca mnie do dalszego działania. Jestem osobą, która zawsze chętnie pomoże każdemu i nie ma problemu w odnalezieniu się w trudnej sytuacji.”",
  "Piotrek: “Fajny test, pomógł mi rozwiać moje wszystkie wątpliwości, dzięki wynikom jakie otrzymałem już wiem, które oferty pracy są dla mnie a jakich powinienem unikać. Co najważniejsze test był krótki i zwięzły, nie musiałem poświęcić na niego kilku godzin, mogłem też do niego wracać wtedy kiedy miałem czas.”"
]

const testimonialWidth = 546

export const SectionTestimonials: React.FC = () => {
  const { gaps } = useTheme()
  const [idx, setIdx] = useState(0)

  return (
    <section
      css={{
        background:
          "linear-gradient(180deg, rgba(236, 240, 253, 0) 0%, rgba(236, 240, 253, 0.53) 14.32%, #ECF0FD 45.83%, rgba(236, 240, 253, 0.43) 84.33%, rgba(236, 240, 253, 0) 100%)",
        position: "relative"
      }}
    >
      <TestimonialsFaces saveWidth={testimonialWidth + 2 * gaps[5]} />
      <div
        css={{
          padding: `103px ${gaps[6]}px 242px`,
          position: "relative"
        }}
      >
        <div
          css={{
            maxWidth: 798,
            margin: "0 auto"
          }}
        >
          <H1 marginBottom={100} textAlign="center">
            Czego dowiedziały się o sobie osoby, które wykonały test
          </H1>
        </div>
        <div
          css={{
            margin: "0 auto",
            maxWidth: testimonialWidth
          }}
        >
          <Testimonial animationDuration={animationDuration} contents={testimonials} idx={idx} />
          <div
            css={{
              display: "grid",
              padding: "54px 61px 0 0",
              justifyContent: "right"
            }}
          >
            <NavArrows
              animationDuration={animationDuration}
              backEnabled={idx > 0}
              forwardEnabled={idx < testimonials.length - 1}
              onBackClick={() => {
                if (idx > 0) {
                  setIdx(idx - 1)
                }
              }}
              onForwardClick={() => {
                if (idx < testimonials.length - 1) {
                  setIdx(idx + 1)
                }
              }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
