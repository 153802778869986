/** @jsxImportSource @emotion/react */
import { useTheme } from "../../../../../design/hooks/useTheme"

type Props = {
  fontColor: string
  maxWidth?: number
}

export const IntroBoxParagraph: React.FC<Props> = ({ children, fontColor, maxWidth }) => {
  const { fonts } = useTheme()

  return (
    <p
      css={{
        color: fontColor,
        fontFamily: fonts.poppins,
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "18px",
        margin: 0,
        maxWidth
      }}
    >
      {children}
    </p>
  )
}
