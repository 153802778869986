/** @jsxImportSource @emotion/react */

import { useTheme } from "../../../hooks/useTheme"

export const StandardRegulations: React.FC = ({ children }) => {
  const theme = useTheme()
  const { colors } = theme

  return (
    <div
      css={{
        margin: "0 auto",
        maxWidth: 1214,
        "& a": {
          color: colors.primary[600],
          textDecoration: "underline"
        },
        "& table": {
          "& td, & th": {
            padding: 10,
            verticalAlign: "top",
            "&:first-of-type": {
              paddingLeft: 0
            },
            "&:last-of-type": {
              paddingRight: 0
            }
          },
          "& th": {
            textTransform: "uppercase"
          }
        }
      }}
    >
      {children}
    </div>
  )
}
