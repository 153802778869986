import { Theme } from "../design/Theme"
import { Intent } from "./Intent"

export const getBackgroundColorForIntent = (intent: Intent, useAltShade: boolean, emphasise: boolean, colors: Theme["colors"]): string => {
  const shade = useAltShade ? 100 : emphasise ? 900 : 600

  switch (intent) {
    case "Danger":
      return colors.danger[900]
    case "Primary":
      return colors.primary[shade]
    case "Secondary":
      return colors.secondary[shade]
    case "Success":
      return colors.success[900]
    case "Tertiary":
      return colors.tertiary[useAltShade ? 600 : 900]
    case "Quaternary":
      return colors.quaternary[shade]
    case "Quinary":
      return useAltShade ? colors.quinary[1] : colors.primary[600]
    default:
      return "inherit"
  }
}
