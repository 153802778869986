/** @jsxImportSource @emotion/react */
import { H0 } from "../../../../../design/system/headers/H0"
import { NavBar } from "../../../../../domain/navBar/NavBar"
import { FlashBar } from "../../FlashBar"
import { IntroBoxes } from "./IntroBoxes"

const blurRadius = 50

export const SectionTop: React.FC = () => (
  <section
    css={{
      position: "relative"
    }}
  >
    <div
      css={{
        bottom: 0,
        display: "grid",
        height: 282 + blurRadius,
        justifyContent: "center",
        left: 0,
        overflow: "hidden",
        padding: blurRadius,
        position: "absolute",
        width: "100%"
      }}
    >
      <div
        css={{
          backgroundColor: "#ECF8FD",
          borderRadius: "50%",
          filter: `blur(${blurRadius}px)`,
          height: 694 - 2 * blurRadius,
          width: 1087 - 2 * blurRadius
        }}
      />
    </div>
    <NavBar />
    <FlashBar />
    <div
      css={{
        paddingTop: 44,
        position: "relative"
      }}
    >
      <H0 textAlign="center">Test osobowości</H0>
      <div
        css={{
          padding: "34px 0 126px 0"
        }}
      >
        <div
          css={{
            position: "relative"
          }}
        >
          <IntroBoxes />
        </div>
      </div>
    </div>
  </section>
)
