import { Milliseconds } from "./Milliseconds"

export function debounce<F extends (...args: unknown[]) => void, T>(func: F, timeout: Milliseconds): (...args: Parameters<F>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | null = null

  return function (this: T, ...args) {
    if (timeoutId != null) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(() => func.apply(this, args), timeout)
  }
}
