/** @jsxImportSource @emotion/react */
import { injectGlobal } from "@emotion/css"
import { Footer } from "../../domain/footer/Footer"
import { SectionDimensions } from "./sections/dimensions/SectionDimensions"
import { SectionExperience } from "./sections/experience/SectionExperience"
import { SectionTestimonials } from "./sections/testimonials/SectionTestimonials"
import { SectionTop } from "./sections/top/SectionTop"
import { SectionWhy } from "./sections/why/SectionWhy"

injectGlobal`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
`

export const LandingPage: React.FC = () => {
  return (
    <>
      <SectionTop />
      <SectionDimensions />
      <SectionWhy />
      <SectionExperience />
      <SectionTestimonials />
      <Footer />
    </>
  )
}
