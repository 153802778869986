/** @jsxImportSource @emotion/react */

import { P } from "../../../../design/system/P"
import { InstructionModal } from "./InstructionModal"

type Props = {
  onClose: () => void
}

export const SecondInstructionModal: React.FC<Props> = ({ onClose }) => (
  <InstructionModal onClose={onClose} title="Instrukcja do części 2">
    <P textAlign="center">
      Poniżej znajduje się kolejna lista stwierdzeń, które odnoszą się do Ciebie w kontekście Twojej pracy zawodowej. Przeczytaj uważnie
      każde stwierdzenie i określ, na ile się z nim zgadzasz, to znaczy, na ile pasuje ono do opisu Ciebie i dobrze opisuje Twoje typowe
      reakcje czy preferencje.
    </P>
    <P textAlign="center">
      Odpowiedz na wszystkie poniższe pytania. Jeśli masz wątpliwości, określ, jak zazwyczaj lub najczęściej reagujesz. Pamiętaj, że nie ma
      tu dobrych i złych odpowiedzi.
    </P>
  </InstructionModal>
)
