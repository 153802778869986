/** @jsxImportSource @emotion/react */

type Props = {
  onClick: () => void
}

export const AlertXClose: React.FC<Props> = ({ onClick }) => (
  <div
    css={{
      cursor: "pointer",
      display: "inline-block"
    }}
    onClick={onClick}
  >
    &#10005;
  </div>
)
