/** @jsxImportSource @emotion/react */

import { useTheme } from "../../design/hooks/useTheme"

export const NavElement: React.FC = ({ children }) => {
  const { fonts } = useTheme()

  return (
    <li
      css={{
        cursor: "pointer",
        fontFamily: fonts.inter,
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "20px",
        marginRight: 8,
        padding: "14px 8px"
      }}
    >
      {children}
    </li>
  )
}
