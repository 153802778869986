/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react"
import { DrawingAreaContext } from "./DrawingAreaContext"

type Props = {
  maxHeight: number
  maxWidth: number
  height: number | string
  width: number | string
}

export const DrawingArea: React.FC<Props> = ({ children, height, maxHeight, maxWidth, width }) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null)
  const [rect, setRect] = useState<DOMRect | null>(null)

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null
    let previousRect: typeof rect = null

    const check = (): void => {
      const currentRect = ref?.getBoundingClientRect()

      if (currentRect != null && (currentRect?.width !== previousRect?.width || currentRect?.height !== previousRect?.height)) {
        previousRect = currentRect
        setRect(currentRect)
      }

      timeoutId = setTimeout(check, 100)
    }

    check()

    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId)
      }
    }
  }, [ref, setRect, setRef])

  return (
    <div
      css={{
        height,
        maxHeight,
        maxWidth,
        position: "relative",
        width
      }}
      ref={setRef}
      role="figure"
    >
      <DrawingAreaContext.Provider
        value={{
          height: rect?.height || 0,
          width: rect?.width || 0
        }}
      >
        {children}
      </DrawingAreaContext.Provider>
    </div>
  )
}
