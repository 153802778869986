/** @jsxImportSource @emotion/react */

import { HCCSurveyResult } from "../../../../../../../../modules/domain/survey/hcc/HCCSurveyResult"
import { HCCSurveyResultChart } from "./HCCSurveyResultChart"

type Props = {
  result: HCCSurveyResult
}

export const SectionChart: React.FC<Props> = ({ result }) => (
  <section
    css={{
      display: "grid",
      overflow: "hidden",
      justifyContent: "center"
    }}
  >
    <HCCSurveyResultChart result={result} />
  </section>
)
