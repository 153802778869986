/** @jsxImportSource @emotion/react */
import { SingleChoiceQuestion } from "../../../../../../../../modules/domain/survey/SurveyQuestion"
import { useTheme } from "../../../../../../../design/hooks/useTheme"
import { Button } from "../../../../../../../design/system/buttons/Button"
import { ButtonCheckCircle } from "../../../../../../../design/system/buttons/ButtonCheckCircle"
import { ButtonHorizontalContent } from "../../../../../../../design/system/buttons/ButtonHorizontalContent"
import { QuestionFieldSet } from "../../QuestionFieldSet"
import { QuestionLegend } from "../../QuestionLegend"

type Props = {
  question: SingleChoiceQuestion
  value: string | undefined
  onChange: (value: string) => void
}

export const SingleChoiceQuestionField: React.FC<Props> = ({ question, value, onChange }) => {
  const { gaps } = useTheme()
  const gap = gaps[3]

  return (
    <QuestionFieldSet paddingBottom={54}>
      <QuestionLegend textAlign="left">{question.content}</QuestionLegend>
      <div
        css={{
          margin: -gap
        }}
        role="radiogroup"
      >
        {question.answers.map((answer) => {
          const selected = value === answer.id

          return (
            <div
              aria-checked={selected}
              css={{
                display: "inline-block",
                margin: gap
              }}
              key={answer.id}
              role="radio"
            >
              <Button
                intent="Quaternary"
                onClick={() => {
                  if (value !== answer.id) {
                    onChange(answer.id)
                  }
                }}
                useAltShade={!selected}
              >
                <ButtonHorizontalContent>
                  <ButtonCheckCircle checked={selected} intent="Quaternary" />
                  {answer.content}
                </ButtonHorizontalContent>
              </Button>
            </div>
          )
        })}
      </div>
    </QuestionFieldSet>
  )
}
