/** @jsxImportSource @emotion/react */
import { TestimonialCard } from "./TestimonialCard"
import { TestimonialSubCard } from "./TestimonialSubCard"

type Props = { animationDuration: number; contents: string[]; idx: number }

const borderRadius = 20
const boxShadow = "0px 18px 53px rgba(215, 228, 249, 0.5)"

export const Testimonial: React.FC<Props> = ({ animationDuration, contents, idx }) => {
  return (
    <div
      css={{
        position: "relative"
      }}
    >
      <TestimonialSubCard borderRadius={borderRadius} offset={40} />
      <TestimonialSubCard borderRadius={borderRadius} offset={20} boxShadow={boxShadow} />
      <TestimonialCard
        animationDuration={animationDuration}
        borderRadius={borderRadius}
        boxShadow={boxShadow}
        contents={contents}
        idx={idx}
      />
    </div>
  )
}
