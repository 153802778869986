/** @jsxImportSource @emotion/react */
import questionsIcon from "../../../../../images/pages/landing/bricks-aligned-right.png"
import surveyIcon from "../../../../../images/pages/landing/bricks-fat-slim.png"
import usersIcon from "../../../../../images/pages/landing/bricks-road.png"
import testsIcon from "../../../../../images/pages/landing/bricks-vertical.png"
import { useTheme } from "../../../../design/hooks/useTheme"
import { P } from "../../../../design/system/P"
import { H1 } from "../../../../design/system/headers/H1"
import { useScreen } from "../../../../screen/hooks/useScreen"
import { ExperienceItem } from "./ExperienceItem"

export const SectionExperience: React.FC = () => {
  const { gaps } = useTheme()
  const { window } = useScreen()
  const breakMainColumns = window.width < 1400
  const breakItems = window.width < 600 + 2 * gaps[6]

  return (
    <section
      css={{
        display: "grid",
        gap: 32,
        gridTemplateColumns: breakMainColumns ? "auto" : "auto auto",
        justifyContent: "space-around",
        padding: `80px ${breakMainColumns ? gaps[6] : 112}px 80px`
      }}
    >
      <div
        css={{
          maxWidth: 592
        }}
      >
        <H1 textAlign={breakMainColumns ? "center" : "left"}>Nasze 15 letnie doświadczenie</H1>
        <P>
          HCC to zespół managerów z wieloletnim doświadczeniem w rekrutacji do projektów digital marketingowych. Dziś chcemy pomagać
          kandydatom w znalezieniu dla nich ciekawych projektów, dopasowanych do ich możliwości.
        </P>
      </div>
      <div
        css={{
          alignItems: "center",
          display: "grid"
        }}
      >
        <div
          css={{
            columnGap: 32,
            display: "grid",
            gridTemplateColumns: breakItems ? "auto" : "280px 280px",
            justifyContent: breakItems ? "center" : "left",
            rowGap: 40
          }}
        >
          <ExperienceItem description="zrealizowanych testów" icon={testsIcon} title="1000+" />
          <ExperienceItem description="pytań" icon={questionsIcon} title="50+" />
          <ExperienceItem description="użytkowników" icon={usersIcon} title="500+" />
          <ExperienceItem description="minut trwa test" icon={surveyIcon} title="15+" />
        </div>
      </div>
    </section>
  )
}
