/** @jsxImportSource @emotion/react */

import { MouseEventHandler, useCallback, useRef } from "react"
import { useTheme } from "../design/hooks/useTheme"

type Props = {
  onDirectClick?: () => void
}

export const ModalBackDrop: React.FC<Props> = ({ children, onDirectClick }) => {
  const { gaps } = useTheme()
  const ref = useRef<HTMLDivElement>(null)
  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (onDirectClick != null && event.target === ref.current) {
        onDirectClick()
      }
    },
    [onDirectClick, ref]
  )

  return (
    <div
      css={{
        alignItems: "start",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        display: "grid",
        gridTemplateColumns: "auto",
        height: "100vh",
        justifyContent: "center",
        left: 0,
        padding: gaps[5],
        position: "fixed",
        top: 0,
        width: "100vw"
      }}
      onClick={onClick}
      ref={ref}
    >
      {children}
    </div>
  )
}
