/** @jsxImportSource @emotion/react */

import { useTheme } from "../../../hooks/useTheme"
import { H2 } from "../../headers/H2"

type Props = {
  value?: string
}

export const StandardRegulationsSubHeader: React.FC<Props> = ({ children, value }) => {
  const { colors } = useTheme()

  return (
    <H2 textAlign="left" color={colors.primary[900]} marginBottom={20} marginTop={80}>
      {value} {children}
    </H2>
  )
}
