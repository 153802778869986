/** @jsxImportSource @emotion/react */

import { useCallback, useRef, useState } from "react"
import { useMessages } from "../../../../i18n/hooks/useMessages"
import { textLinkCSS } from "../../../css/textLinkCSS"
import { useTheme } from "../../../hooks/useTheme"

const transition = "height 500ms ease-in"

type Props = {
  isCollapsable?: boolean
}

export const CompactRegulations: React.FC<Props> = ({ children, isCollapsable = false }) => {
  const theme = useTheme()
  const { colors, fonts, gaps } = theme
  const messages = useMessages()
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isCollapsable)
  const onClick = useCallback(() => setIsCollapsed(!isCollapsed), [isCollapsed, setIsCollapsed])
  const ref = useRef<HTMLDivElement>(null)
  const height = isCollapsed ? 300 : ref.current?.getBoundingClientRect().height

  return (
    <>
      <div
        css={{
          background: isCollapsed ? "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)" : "none",
          borderBottom: isCollapsable ? `solid 1px ${colors.primary[300]}` : undefined,
          height,
          overflow: "hidden",
          position: "relative",
          transition,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: isCollapsed ? "transparent" : "initial"
        }}
      >
        <div
          css={{
            color: colors.neutral[900],
            fontFamily: fonts.inter,
            fontSize: 12,
            lineHeight: "24px",
            paddingBottom: gaps[6],
            "& a": {
              textDecoration: "underline"
            },
            "& p": {
              marginBottom: gaps[6]
            },
            "& ol, & ul": {
              margin: 0,
              paddingInlineStart: 24
            },
            "& ul": {
              listStyleType: isCollapsed ? "none" : "disc"
            }
          }}
          ref={ref}
        >
          {children}
        </div>
        <div
          css={{
            background: "linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%)",
            bottom: 0,
            left: 0,
            height: isCollapsed ? 110 : 0,
            position: "absolute",
            transition,
            width: "100%"
          }}
        ></div>
      </div>
      {isCollapsable && (
        <>
          <div
            css={{
              padingTop: gaps[3],
              textAlign: "right"
            }}
          >
            <span css={textLinkCSS({ theme })} onClick={onClick}>
              {isCollapsed ? messages.expand : messages.collapse}
              <span
                css={{
                  display: "inline-block",
                  marginLeft: gaps[2],
                  transform: `rotate(${(isCollapsed ? -1 : 1) * 90}deg)`
                }}
                dangerouslySetInnerHTML={{ __html: "&lt;" }}
              ></span>
            </span>
          </div>
        </>
      )}
    </>
  )
}
