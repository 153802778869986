/** @jsxImportSource @emotion/react */

import { useTheme } from "../../../hooks/useTheme"

type Props = {
  value: string
}

export const StandardRegulationsItem: React.FC<Props> = ({ children, value }) => {
  const { colors, fonts } = useTheme()
  return (
    <div
      css={{
        color: colors.neutral[700],
        display: "grid",
        fontFamily: fonts.inter,
        fontSize: "18px",
        gridTemplateColumns: "44px auto",
        lineHeight: "28px",
        margin: "20px 0"
      }}
    >
      <div>{value}</div>
      <div>{children}</div>
    </div>
  )
}
