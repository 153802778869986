/** @jsxImportSource @emotion/react */

import { useFormik } from "formik"
import { useMemo } from "react"
import * as Yup from "yup"
import { SurveyDefinition } from "../../../../../modules/domain/survey/SurveyDefinition"
import { createValidationSchemaForSurvey } from "../../../../../modules/domain/survey/validation/createValidationSchemaForSurvey"
import { FormikSubmitFunction } from "../../../../../modules/form/FormikSubmitFunction"
import { requiredBoolean } from "../../../../../modules/form/yup/requiredBoolean"
import { requiredString } from "../../../../../modules/form/yup/requiredString"
import { Messages } from "../../../../../modules/i18n/Messages"
import { useTheme } from "../../../../design/hooks/useTheme"
import { Button } from "../../../../design/system/buttons/Button"
import { ButtonHorizontalContent } from "../../../../design/system/buttons/ButtonHorizontalContent"
import { GreaterThan } from "../../../../design/system/icons/GreaterThan"
import { useMessages } from "../../../../i18n/hooks/useMessages"
import { InitialSurveyFormValues } from "../sections/form/InitialSurveyFormValues"
import { CaptchaField } from "../sections/form/fields/CaptchaField"
import { EmailField } from "../sections/form/fields/EmailField"

type Props = {
  definition: SurveyDefinition
  initialValues: InitialSurveyFormValues
  onSubmit: FormikSubmitFunction<InitialSurveyFormValues>
}

const createValidationSchema = (definition: SurveyDefinition, messages: Messages): Yup.SchemaOf<InitialSurveyFormValues> =>
  Yup.object().shape({
    answers: createValidationSchemaForSurvey(definition, messages, true),
    email: requiredString(messages).email(),
    surveyInstanceId: Yup.string(),
    captchaToken: requiredString(messages),
    consents: Yup.object().shape({
      1: requiredBoolean(messages),
      2: requiredBoolean(messages)
    }),
    files: Yup.array()
  })

export const FinishLaterForm: React.FC<Props> = ({ definition, initialValues, onSubmit }) => {
  const { gaps } = useTheme()
  const messages = useMessages()
  const validationSchema = useMemo(() => createValidationSchema(definition, messages), [definition, messages])
  const formik = useFormik<InitialSurveyFormValues>({
    validationSchema,
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit
  })

  return (
    <form
      css={{
        paddingBottom: 20,
        paddingTop: 36,
        "& > div:nth-of-type(n + 2)": {
          display: "grid",
          justifyContent: "center",
          paddingTop: gaps[3]
        }
      }}
      onSubmit={formik.handleSubmit}
    >
      <div>
        <EmailField formik={formik} />
      </div>
      <div>
        <CaptchaField formik={formik} />
      </div>
      <div>
        <Button intent="Primary" disabled={formik.isSubmitting} type="submit">
          <ButtonHorizontalContent>
            wyślij link do testu <GreaterThan />
          </ButtonHorizontalContent>
        </Button>
      </div>
    </form>
  )
}
