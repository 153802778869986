/** @jsxImportSource @emotion/react */

import { Formik } from "../../../../../../../modules/form/Formik"
import { Captcha } from "../../../../../../catpcha/Captcha"
import { FormFieldError } from "../../../../../../design/system/form/FormFieldError"
import { InitialSurveyFormValues } from "../InitialSurveyFormValues"

type Props = {
  formik: Formik<InitialSurveyFormValues>
}

export const CaptchaField: React.FC<Props> = ({ formik }) => (
  <fieldset>
    <Captcha onVerify={(token) => formik.setFieldValue("captchaToken", token)} />
    <FormFieldError message={formik.errors.captchaToken} />
  </fieldset>
)
