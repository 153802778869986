/** @jsxImportSource @emotion/react */

import { useEffect } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { SurveyDefinition } from "../../../../../modules/domain/survey/SurveyDefinition"
import { InitialSurveyState } from "../../../../../modules/domain/survey/initial/InitialSurveyState"
import { setStage } from "../../../../../modules/domain/survey/initial/actions"
import { FormikSubmitFunction } from "../../../../../modules/form/FormikSubmitFunction"
import { MainState } from "../../../../../modules/state/MainState"
import { IntentMessage } from "../../../../design/system/IntentMessage"
import { P } from "../../../../design/system/P"
import { H2 } from "../../../../design/system/headers/H2"
import { GreenEnvelope } from "../../../../design/system/icons/GreenEnvelope"
import { CloseableModal } from "../../../../modal/CloseableModal"
import { InitialSurveyFormValues } from "../sections/form/InitialSurveyFormValues"
import { FinishLaterForm } from "./FinishLaterForm"

type Props = {
  definition: SurveyDefinition
  initialFormValues: InitialSurveyFormValues
  onClose: () => void
  onSubmit: FormikSubmitFunction<InitialSurveyFormValues>
}

const mapState = (state: MainState): InitialSurveyState => state.domain.survey.initial

export const FinishLaterModal: React.FC<Props> = ({ definition, initialFormValues, onClose, onSubmit }) => {
  const { stage } = useSelector(mapState, shallowEqual)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setStage("Open"))
  }, [dispatch])

  return (
    <CloseableModal maxWidth={634} onClose={onClose}>
      <H2 textAlign="center" marginBottom={69}>
        Dokończ test później
      </H2>
      {stage === "PartiallyCompleted" ? (
        <>
          <div
            css={{
              margin: "0 auto",
              maxWidth: 486,
              textAlign: "center"
            }}
          >
            <IntentMessage intent="Success">Link został pomyślnie wysłany. Sprawdź swoją skrzynke mailową.</IntentMessage>
          </div>
          <div
            css={{
              display: "block",
              margin: "37px auto 0"
            }}
          >
            <GreenEnvelope />
          </div>
        </>
      ) : (
        <>
          <P textAlign="center">
            Nie możesz teraz wypełnić testu? Nic straconego! Wyślemy na Twoją skrzynkę mailową link, pod którym będziesz mógł kontynuować
            wypełnianie testu w dowolnej chwili.
          </P>
          <FinishLaterForm definition={definition} initialValues={initialFormValues} onSubmit={onSubmit} />
        </>
      )}
    </CloseableModal>
  )
}
