/** @jsxImportSource @emotion/react */

import { useTheme } from "../../../../../design/hooks/useTheme"
import { ServiceRegulations } from "./ServiceRegulations"

export const TextSection: React.FC = () => {
  const { gaps } = useTheme()

  return (
    <section
      css={{
        padding: `100px ${gaps[4]}px`
      }}
    >
      <ServiceRegulations />
    </section>
  )
}
