/** @jsxImportSource @emotion/react */
import { useRoutes } from "../../routes/hooks/useRoutes"
import { useScreen } from "../../screen/hooks/useScreen"
import { FooterLink } from "./FooterLink"

export const FooterLinks: React.FC = () => {
  const routes = useRoutes()
  const { window } = useScreen()
  const shouldUseSingleColumnLayout = window.width < 438

  return (
    <nav>
      <ul
        css={{
          display: shouldUseSingleColumnLayout ? "block" : "flex",
          listStyle: "none",
          margin: 0,
          padding: 0
        }}
      >
        <FooterLink title="Test" url={routes.survey.initial.index()} />
        <FooterLink title="Kontakt" url={routes.contact()} />
        <FooterLink title="Regulamin" url={routes.regulations()} />
        <FooterLink title="Polityka prywatności" url={routes.privacyPolicy()} />
        <FooterLink title="Polityka ciastek" url={routes.cookiesPolicy()} />
      </ul>
    </nav>
  )
}
