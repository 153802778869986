/** @jsxImportSource @emotion/react */
import { ScrollTo } from "../../../animation/ScrollTo"
import { Footer } from "../../../domain/footer/Footer"
import { NavBar } from "../../../domain/navBar/NavBar"
import { TextSection } from "./sections/text/TextSection"

export const PrivacyPolicyPage: React.FC = () => {
  return (
    <>
      <ScrollTo />
      <NavBar />
      <TextSection />
      <Footer />
    </>
  )
}
