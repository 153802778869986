/** @jsxImportSource @emotion/react */

import { useCallback, useState } from "react"
import { useTheme } from "../../../../../../../design/hooks/useTheme"
import { H2 } from "../../../../../../../design/system/headers/H2"
import { ResultSentModal } from "./ResultSentModal"
import { SendResultForm } from "./SendResultForm"

type Props = {
  surveyInstanceId: string
}

export const SectionSendResult: React.FC<Props> = ({ surveyInstanceId }) => {
  const { gaps } = useTheme()
  const [isModalVisible, setModalVisibility] = useState(false)
  const closeModal = useCallback(() => setModalVisibility(false), [setModalVisibility])
  const showModal = useCallback(() => setModalVisibility(true), [setModalVisibility])

  return (
    <section
      css={{
        padding: `${gaps[7]}px ${gaps[6]}px 96px`
      }}
    >
      <H2 marginBottom={gaps[7]} textAlign="center">
        Wyślij wyniki testu w formacie PDF na Twój adres e-mail
      </H2>
      <SendResultForm showModal={showModal} surveyInstanceId={surveyInstanceId} />
      {isModalVisible && <ResultSentModal onClose={closeModal} />}
    </section>
  )
}
