/** @jsxImportSource @emotion/react */
import { useCallback, useMemo } from "react"
import { SingleChoiceQuestion, YesNoQuestion } from "../../../../../../../../modules/domain/survey/SurveyQuestion"
import { useMessages } from "../../../../../../../i18n/hooks/useMessages"
import { SingleChoiceQuestionField } from "../singleChoice/SingleChoiceQuestionField"

type Props = {
  question: YesNoQuestion
  onChange: (value: boolean) => void
  value: boolean | undefined
}

const yes = "yes"
const no = "no"

export const YesNoQuestionField: React.FC<Props> = ({ question, value, onChange }) => {
  const messages = useMessages()
  const singleChoiceQuestion: SingleChoiceQuestion = useMemo(
    () => ({
      answers: [
        { id: yes, content: messages.yes },
        { id: no, content: messages.no }
      ],
      content: question.content,
      id: question.id,
      type: "SingleChoice"
    }),
    [messages, question]
  )
  const onChangeCallback = useCallback((value: string) => onChange(value === yes), [onChange])

  return (
    <SingleChoiceQuestionField
      question={singleChoiceQuestion}
      value={value != null ? (value && yes) || no : undefined}
      onChange={onChangeCallback}
    />
  )
}
