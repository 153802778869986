/** @jsxImportSource @emotion/react */
import { useTheme } from "../../../design/hooks/useTheme"
import { Flash } from "../../../flash/Flash"

export const FlashBar: React.FC = () => {
  const { gaps } = useTheme()

  return (
    <div
      css={{
        justifyContent: "center",
        display: "grid",
        padding: `${gaps[4]}px ${gaps[6]}px 0`
      }}
    >
      <Flash />
    </div>
  )
}
