const idKey = "intervieweeId"

export class IntervieweeService {
  setId(intervieweeId: string): void {
    localStorage.setItem(idKey, intervieweeId)
  }

  getId(): string | null {
    return localStorage.getItem(idKey)
  }
}
