/** @jsxImportSource @emotion/react */

import { FormikHelpers, useFormik } from "formik"
import { useMemo } from "react"
import * as Yup from "yup"
import { requiredString } from "../../../../modules/form/yup/requiredString"
import { useMessages } from "../../../i18n/hooks/useMessages"
import { overTextInputContent } from "../../css/form/overTextInputContent"
import { textInputCSS } from "../../css/form/textInputCSS"
import { useTheme } from "../../hooks/useTheme"
import { Button } from "../buttons/Button"
import { ButtonHorizontalContent } from "../buttons/ButtonHorizontalContent"
import { GreaterThan } from "../icons/GreaterThan"
import { FormFieldError } from "./FormFieldError"

export type EmailFormValues = {
  email: string
}

const initialValues: EmailFormValues = {
  email: ""
}

type Props = {
  disabled?: boolean
  onSubmit: (values: EmailFormValues, formHelpers: FormikHelpers<EmailFormValues>) => void
}

export const EmailForm: React.FC<Props> = ({ onSubmit }) => {
  const theme = useTheme()
  const messages = useMessages()
  const validationSchema: Yup.SchemaOf<EmailFormValues> = useMemo(
    () =>
      Yup.object().shape({
        email: requiredString(messages).email()
      }),
    [messages]
  )
  const formik = useFormik<EmailFormValues>({
    validationSchema,
    initialValues,
    onSubmit
  })

  return (
    <form
      css={{
        margin: "0 auto",
        maxWidth: 487
      }}
      onSubmit={formik.handleSubmit}
    >
      <div
        css={{
          position: "relative"
        }}
      >
        <input
          css={{
            ...textInputCSS(theme),
            width: "100%"
          }}
          name="email"
          onChange={formik.handleChange}
          placeholder="Wpisz swój e-mail"
          type="email"
          value={formik.values.email}
        />
        <div css={overTextInputContent()}>
          <Button disabled={formik.isSubmitting} intent="Primary" paddingLeft={47} paddingRight={47} type="submit">
            <ButtonHorizontalContent>
              {messages.form.send.toLowerCase()} <GreaterThan />
            </ButtonHorizontalContent>
          </Button>
        </div>
      </div>
      <FormFieldError message={formik.errors.email} />
    </form>
  )
}
