/** @jsxImportSource @emotion/react */
import faceFemaleBlonde from "../../../../../images/pages/landing/face-female-blonde.png"
import faceFemaleBrunette from "../../../../../images/pages/landing/face-female-brunette.png"
import faceFemaleCurly from "../../../../../images/pages/landing/face-female-curly.png"
import faceMaleHairy from "../../../../../images/pages/landing/face-male-hairy.png"
import faceMaleNerd from "../../../../../images/pages/landing/face-male-nerd.png"
import faceMalePlain from "../../../../../images/pages/landing/face-male-plain.png"
import { useScreen } from "../../../../screen/hooks/useScreen"

type Props = {
  saveWidth: number
}

export const TestimonialsFaces: React.FC<Props> = ({ saveWidth }) => {
  const { window } = useScreen()
  const distanceThreshold = (window.width - saveWidth) / 2

  return (
    <>
      {distanceThreshold >= 231 + 102 && (
        <img
          alt="Blonde female"
          css={{
            left: 231,
            position: "absolute",
            top: 209
          }}
          height={102}
          src={faceFemaleBlonde}
          width={102}
        />
      )}
      {distanceThreshold >= 90 + 70 && (
        <img
          alt="Hairy male"
          css={{
            left: 90,
            position: "absolute",
            top: 407
          }}
          height={70}
          src={faceMaleHairy}
          width={70}
        />
      )}
      {distanceThreshold >= 191 + 79 && (
        <img
          alt="Brunette female"
          css={{
            left: 191,
            position: "absolute",
            top: 584
          }}
          height={79}
          src={faceFemaleBrunette}
          width={79}
        />
      )}
      {distanceThreshold >= 260 + 70 && (
        <img
          alt="Plain male"
          css={{
            filter: "drop-shadow(0px 14px 24px rgba(0, 0, 0, 0.14));",
            position: "absolute",
            right: 260,
            top: 260
          }}
          height={70}
          src={faceMalePlain}
          width={70}
        />
      )}
      {distanceThreshold >= 79 + 115 && (
        <img
          alt="Nerd male"
          css={{
            filter: "drop-shadow(0px 14px 24px rgba(67, 48, 48, 0.14))",
            position: "absolute",
            right: 79,
            top: 419
          }}
          height={115}
          src={faceMaleNerd}
          width={115}
        />
      )}
      {distanceThreshold > 297 + 67 && (
        <img
          alt="Curly female"
          css={{
            position: "absolute",
            right: 297,
            top: 632
          }}
          height={67}
          src={faceFemaleCurly}
          width={67}
        />
      )}
    </>
  )
}
