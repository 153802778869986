/** @jsxImportSource @emotion/react */
import { P } from "../../../../design/system/P"
import { H2 } from "../../../../design/system/headers/H2"

type Props = { description: string; icon: string; title: string }

export const ExperienceItem: React.FC<Props> = ({ description, icon, title }) => (
  <div
    css={{
      alignItems: "center",
      display: "grid",
      gridTemplateColumns: "60px 1fr",
      justifyContent: "left"
    }}
  >
    <div>
      <img alt={title} height={32} src={icon} width={32} />
    </div>
    <div>
      <H2 marginBottom={0} textAlign="left">
        {title}
      </H2>
      <P>{description}</P>
    </div>
  </div>
)
