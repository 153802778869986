/** @jsxImportSource @emotion/react */
import { Intent } from "../../../../modules/intent/Intent"
import { getForegroundColorForIntent } from "../../../../modules/intent/getForegroundColorForIntent"
import { useTheme } from "../../hooks/useTheme"

type Props = {
  checked: boolean
  intent: Intent
}

export const ButtonCheckCircle: React.FC<Props> = ({ checked, intent }) => {
  const { colors, fonts } = useTheme()
  const color = getForegroundColorForIntent(intent, !checked, colors)

  return (
    <div
      css={{
        border: `solid 2px ${color}`,
        borderRadius: 10,
        display: "inline-block",
        position: "relative",
        height: 20,
        width: 20
      }}
    >
      <span
        css={{
          color,
          fontFamily: fonts.inter,
          lineHeight: "18px",
          fontSize: 18,
          fontWeight: 500,
          left: 0,
          position: "absolute",
          top: "-1px"
        }}
        dangerouslySetInnerHTML={checked ? { __html: "&check;" } : undefined}
      ></span>
    </div>
  )
}
