import { CSSObject } from "../CSSObject"

export const overTextInputContent = (): CSSObject => ({
  alignItems: "center",
  display: "grid",
  height: "100%",
  position: "absolute",
  right: 6,
  top: 0
})
