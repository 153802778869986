/** @jsxImportSource @emotion/react */

import { Button } from "../../design/system/buttons/Button"

export const NavBarButtons: React.FC = () => (
  <div
    css={{
      display: "grid",
      gap: 8,
      gridTemplateColumns: "auto auto"
    }}
  >
    <Button intent="Primary">Zarejestruj się</Button>
    <Button intent="None">Zaloguj się</Button>
  </div>
)
