/** @jsxImportSource @emotion/react */

import { range } from "ramda"
import { Theme } from "../../../../../modules/design/Theme"
import { formatPercent } from "../../../../../modules/percent/formatPercent"
import { useTheme } from "../../../hooks/useTheme"

type Props = {
  numOfSteps: number
  activeStep: number
}

const customColors = {
  active: "#9B51E0"
}

const createStep = (activeStep: number, numOfSteps: number, theme: Theme) => (step: number) => {
  const { colors, fonts } = theme
  const stepFraction = step / numOfSteps
  const backgroundColor = (() => {
    if (step < activeStep) {
      return customColors.active
    }

    if (step === activeStep) {
      return colors.white
    }

    return colors.content.disabled
  })()
  const borderColor = step <= activeStep ? customColors.active : colors.white

  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "auto",
        justifyContent: "center"
      }}
      key={step}
    >
      <div
        css={{
          backgroundColor,
          borderColor,
          borderRadius: 8,
          borderStyle: "solid",
          borderWidth: 4,
          position: "relative",
          height: 16,
          width: 16
        }}
      >
        <span
          css={{
            color: colors.white,
            fontFamily: fonts.inter,
            lineHeight: "18px",
            fontSize: 18,
            fontWeight: 500,
            left: "-3px",
            position: "absolute",
            top: "-4px"
          }}
          dangerouslySetInnerHTML={step < activeStep ? { __html: "&check;" } : undefined}
        ></span>
      </div>
      <div
        css={{
          color: step === activeStep ? customColors.active : colors.content.secondary,
          fontFamily: fonts.inter,
          fontSize: 12,
          fontWeight: 400,
          lineHeight: "18px"
        }}
      >
        {formatPercent(stepFraction, 0)}
      </div>
    </div>
  )
}

export const Stepper: React.FC<Props> = ({ numOfSteps, activeStep }) => {
  const theme = useTheme()
  const { colors } = theme
  const fraction = activeStep / numOfSteps

  return (
    <div
      css={{
        position: "relative"
      }}
    >
      <div
        css={{
          alignItems: "center",
          display: "grid",
          gridTemplateColumns: "1fr",
          height: 16,
          padding: `0 ${50 / (numOfSteps + 1)}%`
        }}
      >
        <div
          css={{
            display: "grid",
            gridTemplateColumns: `${fraction}fr ${1 - fraction}fr`
          }}
        >
          <div
            css={{
              backgroundColor: customColors.active,
              height: 2
            }}
          />
          <div
            css={{
              backgroundColor: colors.content.disabled,
              height: 2
            }}
          />
        </div>
      </div>
      <div
        css={{
          left: 0,
          display: "grid",
          gridTemplateColumns: range(0, numOfSteps + 1)
            .map(() => "1fr")
            .join(" "),
          position: "absolute",
          top: 0,
          width: "100%"
        }}
      >
        {range(0, numOfSteps + 1).map(createStep(activeStep, numOfSteps, theme))}
      </div>
    </div>
  )
}
