/** @jsxImportSource @emotion/react */
import { range } from "ramda"
import { LikertScaleQuestion } from "../../../../../../../../modules/domain/survey/SurveyQuestion"
import { LikertScaleIndex } from "../../../../../../../../modules/domain/survey/scale/likert/LikertScaleIndex"
import { getLikertScaleAnswers } from "../../../../../../../../modules/domain/survey/scale/likert/getLikertScaleAnswers"
import { useTheme } from "../../../../../../../design/hooks/useTheme"
import { useLanguage } from "../../../../../../../i18n/hooks/useLanguage"
import { useScreen } from "../../../../../../../screen/hooks/useScreen"
import { QuestionFieldSet } from "../../QuestionFieldSet"
import { QuestionLegend } from "../../QuestionLegend"
import { LikertScaleLabel } from "./LikertScaleLabel"
import { LikertScaleOption } from "./LikertScaleOption"

type Props = {
  question: LikertScaleQuestion
  value: LikertScaleIndex | undefined
  onChange: (value: LikertScaleIndex) => void
}

const maxWidth = 785

export const LikertScaleQuestionField: React.FC<Props> = ({ question, value, onChange }) => {
  const language = useLanguage()
  const { colors } = useTheme()
  const { window } = useScreen()
  const answers = getLikertScaleAnswers(language, question.responseSet)
  const createOnChangeCallback = (idx: LikertScaleIndex) => () => {
    if (value !== idx) {
      onChange(idx)
    }
  }
  const shouldWrapLabels = window.width < maxWidth
  const LowLabel = (): JSX.Element => <LikertScaleLabel color={colors.primary[600]}>{answers[0]}</LikertScaleLabel>
  const HighLabel = (): JSX.Element => <LikertScaleLabel color={colors.tertiary[900]}>{answers[4]}</LikertScaleLabel>

  return (
    <QuestionFieldSet paddingBottom={86}>
      <QuestionLegend textAlign="center">{question.content}</QuestionLegend>
      <div
        css={{
          alignItems: "center",
          display: "grid",
          gap: 25,
          gridTemplateColumns: shouldWrapLabels ? "auto" : "auto auto auto",
          justifyContent: "center"
        }}
      >
        {!shouldWrapLabels && <LowLabel />}
        <div
          css={{
            alignItems: "center",
            display: "grid",
            gap: 25,
            gridTemplateColumns: range(0, answers.length)
              .map(() => "auto")
              .join(" "),
            justifyContent: "center"
          }}
          role="radiogroup"
        >
          <LikertScaleOption intent="Quinary" onClick={createOnChangeCallback(1)} selected={value === 1} />
          <LikertScaleOption intent="Quinary" onClick={createOnChangeCallback(2)} selected={value === 2} />
          <LikertScaleOption intent="Quaternary" onClick={createOnChangeCallback(3)} selected={value === 3} />
          <LikertScaleOption intent="Tertiary" onClick={createOnChangeCallback(4)} selected={value === 4} />
          <LikertScaleOption intent="Tertiary" onClick={createOnChangeCallback(5)} selected={value === 5} />
        </div>
        {shouldWrapLabels ? (
          <div
            css={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              justifyContent: "space-between"
            }}
          >
            <LowLabel />
            <HighLabel />
          </div>
        ) : (
          <HighLabel />
        )}
      </div>
    </QuestionFieldSet>
  )
}
