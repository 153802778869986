import { Action } from "./Action"
import { ActionType } from "./ActionType"
import { PayloadAction } from "./PayloadAction"

export const emptyAction = (type: ActionType) => (): Action => ({ type })

export const payloadAction =
  <P>(type: ActionType) =>
  (payload: P): PayloadAction<P> => ({
    type,
    payload
  })
