import { SurveyInstanceAnswerType } from "../../../../../graphql/types.generated"
import { SurveyInstanceAnswerValue } from "../../../SurveyInstanceAnswerValue"
import { GetInstanceQuery } from "../../../SurveyService.generated"

export const convertAnswerValue = (
  input: GetInstanceQuery["survey"]["getSurveyInstance"]["answers"][number]
): SurveyInstanceAnswerValue => {
  switch (input.answerType) {
    case SurveyInstanceAnswerType.BooleanAnswer:
      if ("booleanAnswer" in input) {
        return input.booleanAnswer
      }
      break
    case SurveyInstanceAnswerType.DefinedAnswer:
      if ("definedAnswerId" in input) {
        return input.definedAnswerId
      }
      break
    case SurveyInstanceAnswerType.ScaleAnswer:
      if ("scaleAnswer" in input) {
        return input.scaleAnswer
      }
      break
  }

  throw Error("No valid answer value found in the answer response")
}
