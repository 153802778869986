/** @jsxImportSource @emotion/react */
import { Intent } from "../../../../../../../../modules/intent/Intent"
import { useTheme } from "../../../../../../../design/hooks/useTheme"
import { Button } from "../../../../../../../design/system/buttons/Button"
import { ButtonCheckCircle } from "../../../../../../../design/system/buttons/ButtonCheckCircle"
import { ButtonHorizontalContent } from "../../../../../../../design/system/buttons/ButtonHorizontalContent"

type Props = {
  intent: Intent
  onClick: () => void
  selected: boolean
}

export const LikertScaleOption: React.FC<Props> = ({ intent, onClick, selected }) => {
  const { gaps } = useTheme()
  const padding = gaps[5]

  return (
    <div
      aria-checked={selected}
      css={{
        display: "grid",
        gridTemplateColumns: "auto",
        justifyContent: "center"
      }}
      role="radio"
    >
      <Button
        intent={intent}
        onClick={onClick}
        paddingBottom={padding}
        paddingLeft={padding}
        paddingRight={padding}
        paddingTop={padding}
        useAltShade={!selected}
      >
        <ButtonHorizontalContent>
          <ButtonCheckCircle checked={selected} intent={intent} />
        </ButtonHorizontalContent>
      </Button>
    </div>
  )
}
