import { Reducer } from "redux"
import { Action } from "../action/Action"
import { ActionType } from "../action/ActionType"
import { PayloadAction } from "../action/PayloadAction"

export const setReducer =
  <T>(setActionTypes: ActionType[], initialState: T): Reducer<T, Action> =>
  (state = initialState, action) => {
    if (setActionTypes.includes(action.type)) {
      return (action as PayloadAction<typeof state>).payload
    } else {
      return state
    }
  }

export const setClearReducer =
  <T>(setActionTypes: ActionType[], clearActionTypes: ActionType[]): Reducer<T | null, Action> =>
  (state = null, action) => {
    if (setActionTypes.includes(action.type)) {
      return (action as PayloadAction<typeof state>).payload
    } else if (clearActionTypes.includes(action.type)) {
      return null
    } else {
      return state
    }
  }

export const setRestoreReducer =
  <V>(setActions: ActionType[], restoreActions: ActionType[], initialValue: V): Reducer<V, Action> =>
  (state = initialValue, action) => {
    if (setActions.includes(action.type)) {
      return (action as PayloadAction<typeof state>).payload
    } else if (restoreActions.includes(action.type)) {
      return initialValue
    } else {
      return state
    }
  }
