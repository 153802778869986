/** @jsxImportSource @emotion/react */

import { polarToNormalizedCoords } from "../../../modules/charts/polarToNormalizedCoords"
import { toDrawingAreaCoords } from "../../../modules/charts/toDrawingAreaCoords"
import { Vector2D } from "../../../modules/vector/Vector2D"
import { useDrawingArea } from "../hooks/useDrawingArea"

type Props = {
  angle: number
}

const radius = 6 / 14

export const SpiderLabel: React.FC<Props> = ({ angle, children }) => {
  const area = useDrawingArea()
  const position: Vector2D = toDrawingAreaCoords(polarToNormalizedCoords(angle, radius), area)

  return (
    <div
      css={{
        bottom: position.y,
        justifyContent: "center",
        overflow: "visible",
        left: position.x,
        position: "absolute",
        transform: "translate(-50%, 50%)"
      }}
    >
      {children}
    </div>
  )
}
