/** @jsxImportSource @emotion/react */
import { ScrollTo } from "../../animation/ScrollTo"
import { Footer } from "../../domain/footer/Footer"
import { NavBar } from "../../domain/navBar/NavBar"
import { TextSection } from "./sections/text/TextSection"

export const ContactPage: React.FC = () => {
  return (
    <>
      <ScrollTo />
      <div
        css={{
          display: "grid",
          gridTemplateRows: "auto 1fr auto",
          minHeight: "100vh"
        }}
      >
        <NavBar />
        <TextSection />
        <Footer />
      </div>
    </>
  )
}
