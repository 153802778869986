/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom"
import { useTheme } from "../../design/hooks/useTheme"

type Props = { title: string; url: string }

export const FooterLink: React.FC<Props> = ({ title, url }) => {
  const { colors, fonts } = useTheme()

  return (
    <li
      css={{
        paddingRight: 24
      }}
    >
      <Link
        css={{
          color: colors.white,
          fontFamily: fonts.inter,
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          textDecoration: "none"
        }}
        to={url}
      >
        {title}
      </Link>
    </li>
  )
}
