/** @jsxImportSource @emotion/react */
import { useTheme } from "../../../../../design/hooks/useTheme"

type Props = {
  fontColor: string
}

export const IntroBoxHeader: React.FC<Props> = ({ children, fontColor }) => {
  const { fonts, gaps } = useTheme()

  return (
    <h1
      css={{
        color: fontColor,
        fontFamily: fonts.poppins,
        fontWeight: 500,
        fontSize: 23,
        lineHeight: "31px",
        margin: `${gaps[3]}px 0`
      }}
    >
      {children}
    </h1>
  )
}
