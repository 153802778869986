/** @jsxImportSource @emotion/react */
import { useTheme } from "../../../../../../../../design/hooks/useTheme"
import { Copyright } from "./Copyright"
import { FooterLink } from "./FooterLink"
import { FooterParagraph } from "./FooterParagraph"

const backgroundColor = "#F5F5F5"

export const Footer: React.FC = () => {
  const { gaps } = useTheme()

  return (
    <footer
      css={{
        backgroundColor,
        padding: gaps[6]
      }}
    >
      <div
        css={{
          display: "grid",
          rowGap: 24,
          textAlign: "center",
          margin: "0 auto",
          maxWidth: 1216
        }}
      >
        <FooterParagraph strong>
          Prezentowane opisy nie maja charakteru diagnostycznego, na ich podstawie nie jest podejmowana decyzja dotycząca zatrudnienia
          kandydata.
        </FooterParagraph>
        <div>
          <FooterLink href="https://www.hcc.pl">www.hcc.pl</FooterLink>
        </div>
        <div>
          <Copyright>© 2022 Peaksales. Wszystkie prawa zastrzeżone</Copyright>
        </div>
      </div>
    </footer>
  )
}
