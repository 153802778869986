/** @jsxImportSource @emotion/react */

import { useTheme } from "../../../../design/hooks/useTheme"
import { H1 } from "../../../../design/system/headers/H1"
import { ContactEmail } from "../../../../domain/ContactEmail"
import { ContactLine } from "./ContactLine"

export const TextSection: React.FC = () => {
  const { gaps } = useTheme()

  return (
    <section
      css={{
        display: "grid",
        justifyContent: "center",
        padding: `100px ${gaps[4]}px`
      }}
    >
      <H1 textAlign="center">Kontakt</H1>
      <div
        css={{
          margin: "0 auto"
        }}
      >
        <ContactLine textAlign="center">Peaksales</ContactLine>
        <ContactLine>al. Jerozolimskie 94</ContactLine>
        <ContactLine>00-807 Warszawa</ContactLine>
        <ContactLine>(+48) 22 312 4098</ContactLine>
        <ContactLine>
          <ContactEmail />
        </ContactLine>
      </div>
    </section>
  )
}
