/** @jsxImportSource @emotion/react */
import { useTheme } from "../../hooks/useTheme"

export const ButtonHorizontalContent: React.FC = ({ children }) => {
  const { gaps } = useTheme()

  return (
    <div
      css={{
        alignItems: "center",
        columnGap: gaps[4],
        display: "flex"
      }}
    >
      {children}
    </div>
  )
}
