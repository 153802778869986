/** @jsxImportSource @emotion/react */

import { useTheme } from "../hooks/useTheme"

export const Spinner: React.FC = () => {
  const { colors, fonts } = useTheme()

  return (
    <div
      css={{
        color: colors.neutral[700],
        fontFamily: fonts.inter,
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "28px",
        textAlign: "center"
      }}
    >
      Ładowanie...
    </div>
  )
}
