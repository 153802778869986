import HCaptcha from "@hcaptcha/react-hcaptcha"
import { useCallback, useRef } from "react"
import { useInjector } from "../injector/hooks/useInjector"

type Props = {
  onVerify: (token: string) => void
}

export const Captcha: React.FC<Props> = ({ onVerify }) => {
  const hCaptchaRef = useRef<HCaptcha>(null)
  const { config, logger } = useInjector()

  const onError = useCallback(
    (message: string) => {
      logger.error({ message })
    },
    [logger]
  )

  return <HCaptcha sitekey={config.hCaptcha.siteKey} onError={onError} onVerify={onVerify} ref={hCaptchaRef} />
}
