/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react"
import { Milliseconds } from "../../../modules/time/Milliseconds"
import { inOutFaceCSS } from "../css/inOutFadeCSS"

type Props = {
  children: (() => JSX.Element | null)[]
  duration: Milliseconds
  idx: number
}

export const FadeTransition: React.FC<Props> = ({ children, duration, idx }) => {
  const [isVisible, setIsVisible] = useState(true)
  const [currentIdx, setCurrentIdx] = useState(idx)

  useEffect(() => {
    if (currentIdx !== idx) {
      setIsVisible(false)
      setTimeout(() => {
        setCurrentIdx(idx)
        setIsVisible(true)
      }, duration / 2)
    }
  }, [currentIdx, duration, idx])

  const currentElement = children[currentIdx]

  return <div css={inOutFaceCSS({ duration: duration / 2, visible: isVisible })}>{currentElement && currentElement()}</div>
}
