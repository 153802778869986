/** @jsxImportSource @emotion/react */

import { toDrawingAreaCoords } from "../../modules/charts/toDrawingAreaCoords"
import { Vector2D } from "../../modules/vector/Vector2D"
import { Vector2DOps } from "../../modules/vector/Vector2DOps"
import { useDrawingArea } from "./hooks/useDrawingArea"

type Props = {
  color: string
  v1: Vector2D
  v2: Vector2D
  thickness: number
}

export const Line: React.FC<Props> = ({ color, v1, v2, thickness }) => {
  const area = useDrawingArea()
  const scaledV1 = toDrawingAreaCoords(v1, area)
  const scaledV2 = toDrawingAreaCoords(v2, area)
  const vector = Vector2DOps.minus(scaledV2, scaledV1)
  const length = Vector2DOps.length(vector)
  const angle = -Math.atan2(vector.y, vector.x)
  const offset = Math.floor(thickness / 2)

  return (
    <div
      css={{
        backgroundColor: color,
        left: scaledV1.x,
        bottom: scaledV1.y,
        height: thickness,
        overflow: "visible",
        position: "absolute",
        transform: `translate(0, ${offset}px) rotate(${angle}rad)`,
        transformOrigin: `0 ${offset}px`,
        width: length
      }}
    />
  )
}
