import { Injector } from "../../modules/di/Injector"
import { IntervieweeService } from "../../modules/domain/survey/IntervieweeService"
import { SurveyService } from "../../modules/domain/survey/SurveyService"
import { GraphQLClient } from "../../modules/graphql/GraphQLClient"
import { config } from "./../config"
import { logger } from "./../logger"
import { routes } from "./../routes"
import { sagaMiddleware, store } from "./../store"

const graphQLClient = new GraphQLClient(config.graphql.path)

export const createMainInjector = (): Injector => ({
  config,
  doc: document,
  domain: {
    interviewee: {
      service: new IntervieweeService()
    },
    survey: {
      service: new SurveyService(graphQLClient)
    }
  },
  graphql: {
    client: graphQLClient
  },
  logger,
  routes,
  sagaMiddleware,
  store,
  win: window
})
