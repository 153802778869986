import { GraphQLClient, MutationResult, QueryResult } from "../../graphql/GraphQLClient"
import { graphql } from "../../graphql/graphql"
import { SurveyData } from "./SurveyData"
import { SurveyDefinition } from "./SurveyDefinition"
import { SurveyInstance } from "./SurveyInstance"
import { FindDefinitionQuery, GetInstanceQuery, UploadSurveyMutation } from "./SurveyService.generated"
import { SurveyUploadResponse } from "./SurveyUploadResponse"
import { convertSurveyDefinition } from "./graphql/convertes/from/convertSurveyDefinition"
import { convertSurveyInstance } from "./graphql/convertes/from/convertSurveyInstance"
import { convertAnswersToInputs } from "./graphql/convertes/to/convertAnswersToInputs"

type SendResultByEmailParams = {
  email: string
  surveyInstanceId: string
}

const hccSurveyDimensionResultFragment = graphql`
  fragment HCCSurveyDimensionResultFields on HCCSurveyDimensionResult {
    description
    score
  }
`

const surveyResultFragment = graphql`
  ${hccSurveyDimensionResultFragment}
  fragment SurveyResultFields on HCCSurveyResult {
    dilligence {
      ...HCCSurveyDimensionResultFields
    }
    emotionalStability {
      ...HCCSurveyDimensionResultFields
    }
    empathy {
      ...HCCSurveyDimensionResultFields
    }
    extraversion {
      ...HCCSurveyDimensionResultFields
    }
    independence {
      ...HCCSurveyDimensionResultFields
    }
    openness {
      ...HCCSurveyDimensionResultFields
    }
    professionalSelfEfficacy {
      ...HCCSurveyDimensionResultFields
    }
  }
`

export class SurveyService {
  constructor(private client: GraphQLClient) {}

  async findDefinition(surveyName: string): Promise<SurveyDefinition | null> {
    const result: QueryResult<FindDefinitionQuery> = await this.client.query({
      fetchPolicy: "cache-first",
      query: graphql`
        query FindDefinition($surveyName: String!) {
          survey {
            findSurveyByName(surveyName: $surveyName) {
              id
              questions {
                ... on SurveyLikertScaleQuestion {
                  id
                  questionType
                  content
                  responseSet
                }
                ... on SurveySingleChoiceQuestion {
                  id
                  questionType
                  content
                  answers {
                    id
                    content
                  }
                }
                ... on SurveyYesNoQuestion {
                  id
                  questionType
                  content
                }
              }
            }
          }
        }
      `,
      variables: {
        surveyName
      }
    })

    const input = result.data.survey.findSurveyByName

    if (input != null) {
      return convertSurveyDefinition(input)
    }

    return null
  }

  async getInstance(instanceId: string): Promise<SurveyInstance> {
    const result: QueryResult<GetInstanceQuery> = await this.client.query({
      fetchPolicy: "no-cache",
      query: graphql`
        ${surveyResultFragment}
        query GetInstance($instanceId: String!) {
          survey {
            getSurveyInstance(instanceId: $instanceId) {
              answers {
                ... on SurveyInstanceScaleAnswer {
                  answerType
                  question {
                    ... on SurveyLikertScaleQuestion {
                      id
                    }
                  }
                  scaleAnswer
                }
                ... on SurveyInstanceDefinedAnswer {
                  answerType
                  definedAnswerId
                  question {
                    ... on SurveySingleChoiceQuestion {
                      id
                    }
                  }
                }
                ... on SurveyInstanceBooleanAnswer {
                  answerType
                  booleanAnswer
                  question {
                    ... on SurveyYesNoQuestion {
                      id
                    }
                  }
                }
              }
              id
              intervieweeId
              fillingDurationInSeconds
              result {
                ...SurveyResultFields
              }
              surveyDefinition {
                id
                questions {
                  ... on SurveyLikertScaleQuestion {
                    id
                    questionType
                    content
                    responseSet
                  }
                  ... on SurveySingleChoiceQuestion {
                    id
                    questionType
                    content
                    answers {
                      id
                      content
                    }
                  }
                  ... on SurveyYesNoQuestion {
                    id
                    questionType
                    content
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        instanceId
      }
    })

    return convertSurveyInstance(result.data.survey.getSurveyInstance)
  }

  async sendResultByEmail({ email, surveyInstanceId }: SendResultByEmailParams): Promise<void> {
    await this.client.mutate({
      mutation: graphql`
        mutation SendResultByEmail($email: String!, $surveyInstanceId: String!) {
          survey {
            sendResultByEmail(email: $email, surveyInstanceId: $surveyInstanceId) {
              clientId
            }
          }
        }
      `,
      variables: {
        email,
        surveyInstanceId
      }
    })
  }

  async upload(data: SurveyData): Promise<SurveyUploadResponse> {
    const mutationResult: MutationResult<UploadSurveyMutation> = await this.client.mutate({
      mutation: graphql`
        ${surveyResultFragment}
        mutation UploadSurvey(
          $surveyDefinitionId: String!
          $answers: [SurveyAnswerInput!]!
          $files: [Upload]!
          $fillingDurationInSeconds: Int!
          $captchaToken: String!
          $surveyInstanceId: String
          $email: String
          $intervieweeId: String
        ) {
          survey {
            uploadSurvey(
              answers: $answers
              files: $files
              surveyDefinitionId: $surveyDefinitionId
              fillingDurationInSeconds: $fillingDurationInSeconds
              captchaToken: $captchaToken
              surveyInstanceId: $surveyInstanceId
              email: $email
              intervieweeId: $intervieweeId
            ) {
              intervieweeId
              result {
                ...SurveyResultFields
              }
              surveyInstanceId
            }
          }
        }
      `,
      variables: {
        answers: convertAnswersToInputs(data.answers),
        captchaToken: data.captchaToken,
        email: data.email,
        intervieweeId: data.intervieweeId,
        files: data.files,
        fillingDurationInSeconds: data.fillingDuration,
        surveyDefinitionId: data.id,
        surveyInstanceId: data.instanceId
      }
    })
    const uploadSurveyResult = mutationResult.data?.survey.uploadSurvey
    const { intervieweeId, surveyInstanceId } = uploadSurveyResult || {}

    if (!intervieweeId) {
      throw Error("Cannot obtain the interviewee id")
    }

    if (!surveyInstanceId) {
      throw Error("Cannot obtain the survey instance id")
    }

    return {
      intervieweeId,
      result: uploadSurveyResult?.result || null,
      surveyInstanceId
    }
  }
}
