import { boolean, object, number, string, ObjectSchema } from "yup"
import BooleanSchema from "yup/lib/boolean"
import NumberSchema from "yup/lib/number"
import StringSchema from "yup/lib/string"
import { requiredBoolean } from "../../../form/yup/requiredBoolean"
import { requiredNumber } from "../../../form/yup/requiredNumber"
import { requiredString } from "../../../form/yup/requiredString"
import { Messages } from "../../../i18n/Messages"
import { SurveyDefinition } from "../SurveyDefinition"
import { SurveyQuestion } from "../SurveyQuestion"
import { likertScaleIndices } from "../scale/likert/LikertScaleIndex"

type SurveyQuestionSchema = BooleanSchema<boolean | undefined> | NumberSchema<number | undefined> | StringSchema<string | undefined>

const createQuestionValidator = (question: SurveyQuestion, messages: Messages, partial: boolean): SurveyQuestionSchema => {
  switch (question.type) {
    case "LikertScale":
      return (partial ? number() : requiredNumber(messages)).oneOf([...likertScaleIndices])
    case "SingleChoice":
      return (partial ? string() : requiredString(messages)).oneOf(question.answers.map((answer) => answer.id))
    case "YesNo":
      return partial ? boolean() : requiredBoolean(messages)
  }
}

export const createValidationSchemaForSurvey = (
  survey: SurveyDefinition,
  messages: Messages,
  partial: boolean
): ObjectSchema<Record<string, SurveyQuestionSchema>> =>
  object().shape(
    survey.questions.reduce<Record<string, SurveyQuestionSchema>>(
      (acc, question) => ({
        ...acc,
        [question.id]: createQuestionValidator(question, messages, partial)
      }),
      {}
    )
  )
