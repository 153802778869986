/** @jsxImportSource @emotion/react */

import { useTheme } from "../../../../../../../design/hooks/useTheme"
import { BlurredCircle } from "../../../../../../../design/system/BlurredCircle"

export const SectionTopBackground: React.FC = () => {
  const { colors } = useTheme()

  return (
    <>
      <div
        css={{
          bottom: -222,
          display: "grid",
          justifyContent: "center",
          left: 0,
          position: "absolute",
          width: "100%"
        }}
      >
        <BlurredCircle color={colors.secondary[600]} opacity={0.2} radius={391} />
      </div>
      <div
        css={{
          bottom: 24,
          left: 0,
          position: "absolute"
        }}
      >
        <BlurredCircle color={colors.primary[600]} radius={84} />
      </div>
      <div
        css={{
          background: "linear-gradient(to left top, white 50%, transparent 0)",
          bottom: 0,
          height: 178,
          left: 0,
          position: "absolute",
          width: "100%"
        }}
      ></div>
    </>
  )
}
