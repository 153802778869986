/** @jsxImportSource @emotion/react */
import { Intent } from "../../../../modules/intent/Intent"
import { useTheme } from "../../../design/hooks/useTheme"
import { buttonCSS } from "../../css/form/buttonCSS"

type Props = {
  disabled?: boolean
  intent: Intent
  onClick?: () => void
  paddingBottom?: number
  paddingLeft?: number
  paddingRight?: number
  paddingTop?: number
  type?: "button" | "submit"
  useAltShade?: boolean
}

export const Button: React.FC<Props> = ({
  children,
  disabled,
  intent,
  onClick,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  type,
  useAltShade
}) => {
  const theme = useTheme()

  return (
    <button
      css={buttonCSS({
        disabled,
        intent,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingTop,
        theme,
        useAltShade
      })}
      disabled={disabled}
      onClick={onClick}
      type={type || "button"}
    >
      {children}
    </button>
  )
}
