/** @jsxImportSource @emotion/react */

import { useCallback, useEffect, useState } from "react"
import { useTheme } from "../design/hooks/useTheme"
import { Alert } from "../design/system/alert/Alert"
import { AlertXClose } from "../design/system/alert/AlertXClose"

const localStorageKey = "areCookiesAccepted"

export const CookiesBar: React.FC = () => {
  const { gaps } = useTheme()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(localStorage.getItem(localStorageKey) == null)
  }, [])

  const onClick = useCallback(() => {
    localStorage.setItem(localStorageKey, "true")
    setVisible(false)
  }, [])

  return visible ? (
    <div
      css={{
        bottom: 0,
        display: "grid",
        justifyContent: "center",
        left: 0,
        padding: gaps[5],
        position: "fixed",
        width: "100vw"
      }}
    >
      <Alert intent="Quaternary">
        <div
          css={{
            display: "grid",
            columnGap: gaps[4],
            gridTemplateColumns: "auto auto",
            justifyContent: "space-between"
          }}
        >
          <div>
            Ta strona korzysta z ciasteczek, aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz
            się na ich użycie.
          </div>
          <AlertXClose onClick={onClick}></AlertXClose>
        </div>
      </Alert>
    </div>
  ) : (
    <></>
  )
}
