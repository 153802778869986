/** @jsxImportSource @emotion/react */

import { useTheme } from "../../../../design/hooks/useTheme"

type Props = {
  textAlign?: "center" | "left"
}

export const ContactLine: React.FC<Props> = ({ children, textAlign }) => {
  const { colors, fonts } = useTheme()

  return (
    <p
      css={{
        color: colors.neutral[700],
        fontFamily: fonts.inter,
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "28px",
        margin: 0,
        textAlign
      }}
    >
      {children}
    </p>
  )
}
