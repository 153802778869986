/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom"
import { useTheme } from "../../../../design/hooks/useTheme"
import { P } from "../../../../design/system/P"
import { Button } from "../../../../design/system/buttons/Button"
import { H0 } from "../../../../design/system/headers/H0"
import { NavBar } from "../../../../domain/navBar/NavBar"
import { useRoutes } from "../../../../routes/hooks/useRoutes"
import { useScreen } from "../../../../screen/hooks/useScreen"
import { SectionTopBackground } from "./SectionTopBackground"

export const SectionTop: React.FC = () => {
  const { gaps } = useTheme()
  const { window } = useScreen()
  const routes = useRoutes()
  const maxWidth = 683
  const largeMarginLeft = 72
  const saveWidth = maxWidth
  const shouldUseSmallPadding = window.width < saveWidth

  return (
    <section
      css={{
        backgroundColor: "rgba(242, 247, 255, 0.7)",
        overflow: "hidden",
        paddingBottom: 28,
        position: "relative"
      }}
    >
      <SectionTopBackground saveWidth={saveWidth} />
      <NavBar />
      <div
        css={{
          marginLeft: shouldUseSmallPadding ? gaps[6] : largeMarginLeft,
          maxWidth,
          paddingRight: shouldUseSmallPadding ? gaps[6] : 0,
          paddingTop: 137,
          position: "relative"
        }}
      >
        <H0 textAlign="left">Wykonaj darmowy test, który pozwoli Ci lepiej zrozumieć siebie</H0>
        <div
          css={{
            paddingTop: 12
          }}
        >
          <P marginBottom={0}>Odpowiadając na proste pytania, lepiej poznasz siebie!</P>
          <P>Dzięki temu łatwiej Ci będzie podjąć decyzje o zmianie pracy, czy wyborze oferty, którą ktoś Ci złożył.</P>
        </div>
        <div
          css={{
            padding: "60px 0"
          }}
        >
          <Link to={routes.survey.initial.index()}>
            <Button intent="Primary">Rozpocznij test</Button>
          </Link>
        </div>
      </div>
    </section>
  )
}
