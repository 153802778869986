/** @jsxImportSource @emotion/react */
import { useTheme } from "../../hooks/useTheme"
import { HProps } from "./HProps"

export const H2: React.FC<HProps> = ({ children, color, marginBottom, marginTop, textAlign }) => {
  const { colors, fonts } = useTheme()

  return (
    <h2
      css={{
        color: color || colors.neutral[900],
        fontFamily: fonts.inter,
        fontSize: "30px",
        fontWeight: 700,
        lineHeight: "40px",
        margin: "0 0 8px 0",
        marginBottom,
        marginTop,
        textAlign
      }}
    >
      {children}
    </h2>
  )
}
