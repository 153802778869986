import { Theme } from "./Theme"

export const defaultTheme: Theme = {
  colors: {
    black: "#000000",
    content: {
      disabled: "#E0E0E7",
      secondary: "#898F8F"
    },
    danger: {
      900: "#DC0546"
    },
    form: {
      placeholder: "#D7D7DE"
    },
    neutral: {
      200: "#E5E7EB",
      300: "#D1D5DB",
      600: "#4B5563",
      700: "#374151",
      900: "#272D4E"
    },
    primary: {
      100: "#EDE9FE",
      300: "#C4B5FD",
      600: "#7C3AED",
      900: "#4C1D95"
    },
    secondary: {
      100: "#D5FAFC",
      600: "#1DB5BE",
      900: "#1DB5BE" // TODO a proper shade here
    },
    success: {
      900: "#27AE60"
    },
    tertiary: {
      100: "#BAEFD9", // TODO a proper shade here
      600: "#BAEFD9",
      900: "#02776B"
    },
    quaternary: {
      100: "#E5E7EB",
      600: "#4F4F4F",
      900: "#4F4F4F" // TODO a proper shade here
    },
    quinary: {
      1: "#C6FCFF",
      2: "#B6FFF6",
      3: "#CEE2FF"
    },
    white: "#FFFFFF"
  },
  fonts: {
    inter: "Inter, sans-serif",
    poppins: "sans-serif",
    walsheim: "sans-serif"
  },
  gaps: {
    1: 1,
    2: 2,
    3: 4,
    4: 8,
    5: 16,
    6: 32,
    7: 64
  }
}
