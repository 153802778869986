import { put, takeEvery } from "redux-saga/effects"
import { PayloadAction } from "../../action/PayloadAction"
import { Injector } from "../../di/Injector"
import { BlackBoxGenerator } from "../../saga/types"
import { propagateSimpleMessageError, PROPAGATE_UNKNOWN_ERROR } from "../actions"
import { unknownErrorToString } from "../unknownErrorToString"

export function* watchUnknownError(injector: Injector): BlackBoxGenerator {
  yield takeEvery(PROPAGATE_UNKNOWN_ERROR, function* (action: PayloadAction<unknown>) {
    const error = action.payload
    injector.logger.error({ error })
    yield put(propagateSimpleMessageError(unknownErrorToString(error)))
  })
}
