/** @jsxImportSource @emotion/react */
import quotes from "../../../../../images/pages/landing/quotes.png"
import { useTheme } from "../../../../design/hooks/useTheme"
import { FadeTransition } from "../../../../design/system/FadeTransition"

type Props = {
  animationDuration: number
  borderRadius: number
  boxShadow: string
  contents: string[]
  idx: number
}

export const TestimonialCard: React.FC<Props> = ({ animationDuration, borderRadius, boxShadow, contents, idx }) => {
  const { colors, fonts } = useTheme()

  return (
    <div
      css={{
        color: colors.neutral[900],
        backgroundColor: colors.white,
        borderRadius,
        boxShadow,
        lineHeight: "28px",
        padding: "84px 61px 61px 61px",
        position: "relative",
        fontFamily: fonts.inter,
        fontSize: "18px",
        fontStyle: "italic",
        fontWeight: 400,
        textAlign: "center"
      }}
    >
      <div
        css={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 22
        }}
      >
        <img alt="Quotes" height={133} src={quotes} width={175} />
      </div>
      <div
        css={{
          position: "relative"
        }}
      >
        <FadeTransition duration={animationDuration} idx={idx}>
          {contents.map((content) => () => <>{content}</>)}
        </FadeTransition>
      </div>
    </div>
  )
}
