import { ApolloClient, FetchPolicy, InMemoryCache, NormalizedCacheObject } from "@apollo/client"
import { createUploadLink } from "apollo-upload-client"
import { graphql } from "./graphql"

type Variables = Record<string, unknown>
type Data = Record<string, unknown>

type QueryParams<V extends Variables> = {
  query: ReturnType<typeof graphql>
  fetchPolicy: FetchPolicy
  variables: V
}

type MutationParams<V extends Variables> = {
  mutation: ReturnType<typeof graphql>
  variables: V
}

export type QueryResult<D extends Data> = {
  data: D
}

export type MutationResult<D extends Data> = {
  data?: D | null
}

export class GraphQLClient {
  private underlying: ApolloClient<NormalizedCacheObject>

  constructor(uri: string) {
    this.underlying = new ApolloClient({
      link: createUploadLink({ uri }),
      cache: new InMemoryCache()
    })
  }

  async query<D extends Data, V extends Variables>(params: QueryParams<V>): Promise<QueryResult<D>> {
    return this.underlying.query(params)
  }

  async mutate<D extends Data, V extends Variables>(params: MutationParams<V>): Promise<MutationResult<D>> {
    return this.underlying.mutate(params)
  }
}
