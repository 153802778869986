/** @jsxImportSource @emotion/react */

import { useEffect, useRef } from "react"

type Props = {
  visible?: boolean
}

export const ScrollTo: React.FC<Props> = ({ children, visible = true }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current != null && ref.current.scrollIntoView != null && visible) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center"
      })
    }
  }, [visible])

  return <div ref={ref}>{children}</div>
}
