/** @jsxImportSource @emotion/react */
import womanOnPhone from "../../../../../images/pages/landing/woman-on-phone.png"
import { useTheme } from "../../../../design/hooks/useTheme"
import { P } from "../../../../design/system/P"
import { H1 } from "../../../../design/system/headers/H1"
import { useScreen } from "../../../../screen/hooks/useScreen"
import { WhyBox } from "./WhyBox"

export const SectionWhy: React.FC = () => {
  const { gaps } = useTheme()
  const { window } = useScreen()
  const shouldUseSingleColumnLayout = window.width <= 1400

  return (
    <section
      css={{
        backgroundColor: "#F6F9FF",
        overflow: "hidden",
        position: "relative"
      }}
    >
      <img
        alt="Woman on phone"
        css={{
          left: 0,
          position: "absolute",
          bottom: 133
        }}
        height={582}
        src={womanOnPhone}
        width={541}
      />
      <div
        css={{
          display: "grid",
          gap: gaps[6],
          gridTemplateColumns: shouldUseSingleColumnLayout ? "auto" : "1fr auto",
          justifyContent: shouldUseSingleColumnLayout ? "center" : "left",
          position: "relative"
        }}
      >
        <div
          css={{
            padding: shouldUseSingleColumnLayout ? gaps[6] : `72px ${gaps[6]}px ${gaps[6]}px  66px`
          }}
        >
          <div
            css={{
              maxWidth: shouldUseSingleColumnLayout ? undefined : 375
            }}
          >
            <H1 textAlign={shouldUseSingleColumnLayout ? "center" : "left"} marginBottom={0}>
              Dlaczego warto wykonać test?
            </H1>
          </div>
        </div>

        <div>
          <div
            css={{
              display: "grid",
              gap: gaps[6],
              gridTemplateColumns: shouldUseSingleColumnLayout ? "auto" : "auto auto",
              justifyContent: shouldUseSingleColumnLayout ? "center" : "left"
            }}
          >
            <div
              css={{
                padding: shouldUseSingleColumnLayout ? `0 ${gaps[6]}px` : "112px 0 23px"
              }}
            >
              <WhyBox title="Ile to potrwa? I co mi da?" maxWidth={425}>
                <P>
                  Pytania są bardzo proste, a odpowiedzi nie powinny sprawiać najmniejszych trudnosci. Odpowiadasz tak, jak czujesz,
                  zanaczając odpowiedzi na skali. Przygotuj kubek z herbatą i kawą, bo podróż w głąb siebie zajmie Ci przynajmniej 30 min.
                </P>

                <P>W efekcie otrzymasz opis tego, jakie posiadasz cechy, jakie zadania moga być dla Ciebie najatrakcyjniejsze.</P>
              </WhyBox>
            </div>
            <div
              css={{
                padding: shouldUseSingleColumnLayout ? `0 ${gaps[6]}px` : "217px 75px 0 0"
              }}
            >
              <WhyBox title="Inne testy a nasz test" maxWidth={375}>
                <P>
                  Oczywiście na rynku jest ich bardzo dużo i kazdy z nich moze pomóc Ci lepiej poznac siebie. Ponieważ naszą misją jest
                  znajdywanie talentów zawodowych, zbudowaliśmy test tak, aby pozwolił w jednym czasie zebrać informacje o tych cechach,
                  które mają wpływ na jakość wyonywanyh zadań.
                </P>
              </WhyBox>
            </div>
          </div>
          <div
            css={{
              padding: shouldUseSingleColumnLayout ? gaps[6] : "37px 153px 67px 47px"
            }}
          >
            <WhyBox title="Czy jest faktycznie za darmo?" maxWidth={653}>
              <P>Tak! W przeciwieństwie do innych testów na rynku, nie pobieramy opłat za jego wykonanie.</P>
              <P>
                Aby mieć możliwość kontaktu z Tobą w przyszłosci, gdyby pojawił sie ciekawy projekt dla Ciebie, przed zakończeniem
                wypełniania, poprosimy Cię o przesłanie CV.
              </P>
            </WhyBox>
          </div>
        </div>
      </div>
    </section>
  )
}
