/** @jsxImportSource @emotion/react */

import { useTheme } from "../../../../../design/hooks/useTheme"
import { useScreen } from "../../../../../screen/hooks/useScreen"

type Props = {
  textAlign: "center" | "left"
}

export const QuestionLegend: React.FC<Props> = ({ children, textAlign }) => {
  const { window } = useScreen()
  const { colors, fonts } = useTheme()
  const shouldUseSmallerFont = window.width < 640

  return (
    <legend
      css={{
        color: colors.neutral[900],
        fontFamily: fonts.inter,
        fontWeight: 600,
        fontSize: shouldUseSmallerFont ? 18 : 30,
        lineHeight: `${shouldUseSmallerFont ? 28 : 40}px`,
        paddingBottom: 44,
        textAlign
      }}
    >
      {children}
    </legend>
  )
}
