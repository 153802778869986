import { Milliseconds } from "../../../modules/time/Milliseconds"
import { CSSObject } from "./CSSObject"

type Params = {
  visible: boolean
  duration: Milliseconds
}

export const inOutFaceCSS = ({ visible, duration }: Params): CSSObject => ({
  opacity: visible ? 1 : 0,
  transition: `opacity ${duration}ms ease-in-out`
})
