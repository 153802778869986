/** @jsxImportSource @emotion/react */
import { useState } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { InitialSurveyState } from "../../../../../../modules/domain/survey/initial/InitialSurveyState"
import { MainState } from "../../../../../../modules/state/MainState"
import { useTheme } from "../../../../../design/hooks/useTheme"
import { P } from "../../../../../design/system/P"
import { Spinner } from "../../../../../design/system/Spinner"
import { Stepper } from "../../../../../design/system/progress/stepper/Stepper"
import { useScreen } from "../../../../../screen/hooks/useScreen"
import { InitialSurveyForm } from "./InitialSurveyForm"

const mapState = (state: MainState): InitialSurveyState => state.domain.survey.initial

const maxWidth = 974

export const SectionForm: React.FC = () => {
  const { definition, instance } = useSelector(mapState, shallowEqual)
  const { gaps } = useTheme()
  const { window } = useScreen()
  const [activeStep, setActiveStep] = useState(0)
  const numOfSteps = Math.ceil(Math.min(window.width / maxWidth, 1) * 10)

  return (
    <section>
      <div
        css={{
          margin: "-8px auto 0 auto",
          maxWidth
        }}
      >
        <Stepper activeStep={activeStep} numOfSteps={numOfSteps} />
      </div>
      <div
        css={{
          display: "flex",
          justifyContent: "center",
          padding: `91px ${gaps[5]}px`
        }}
      >
        <div>
          {definition != null && typeof instance !== "string" ? (
            <InitialSurveyForm definition={definition} instance={instance} numOfSteps={numOfSteps} setActiveStep={setActiveStep} />
          ) : typeof instance === "string" ? (
            <P>Nie udało się przywrócić odpowiedzi. Sprawdź adres URL i spróbuj ponownie.</P>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </section>
  )
}
