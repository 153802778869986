/** @jsxImportSource @emotion/react */

import { IntentMessage } from "../../../../../../../design/system/IntentMessage"
import { H2 } from "../../../../../../../design/system/headers/H2"
import { GreenEnvelope } from "../../../../../../../design/system/icons/GreenEnvelope"
import { CloseableModal } from "../../../../../../../modal/CloseableModal"

type Props = {
  onClose: () => void
}

export const ResultSentModal: React.FC<Props> = ({ onClose }) => (
  <CloseableModal maxWidth={634} onClose={onClose}>
    <H2 textAlign="center" marginBottom={69}>
      Wyniki testu w formacie PDF
    </H2>
    <div
      css={{
        margin: "0 auto",
        textAlign: "center"
      }}
    >
      <div>
        <IntentMessage intent="Success">Wyniki testu osobowości zostały pomyślnie wysłane.</IntentMessage>
      </div>
      <div>
        <IntentMessage intent="Success">Sprawdź swoją skrzynke mailową.</IntentMessage>
      </div>
      <div></div>
    </div>
    <div
      css={{
        display: "block",
        margin: "47px auto 0"
      }}
    >
      <GreenEnvelope />
    </div>
  </CloseableModal>
)
