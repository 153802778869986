/** @jsxImportSource @emotion/react */
import { useTheme } from "../../design/hooks/useTheme"

export const CopyRight: React.FC = () => {
  const { colors, fonts } = useTheme()

  return (
    <div
      css={{
        color: colors.neutral[300],
        fontFamily: fonts.inter,
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px"
      }}
    >
      © 2022 Peaksales. Wszystkie prawa zastrzeżone
    </div>
  )
}
