/** @jsxImportSource @emotion/react */

import { toDrawingAreaCoords } from "../../modules/charts/toDrawingAreaCoords"
import { Vector2D } from "../../modules/vector/Vector2D"
import { useDrawingArea } from "./hooks/useDrawingArea"

type Props = {
  position: Vector2D
  color: string
  size: number
}

export const Dot: React.FC<Props> = ({ position, color, size }) => {
  const area = useDrawingArea()
  const absoluteRadius = size / 2
  const scaledPosition = toDrawingAreaCoords(position, area)

  return (
    <div
      css={{
        backgroundColor: color,
        borderRadius: absoluteRadius,
        bottom: scaledPosition.y,
        height: size,
        left: scaledPosition.x,
        position: "absolute",
        transform: "translate(-50%, 50%)",
        width: size
      }}
    />
  )
}
