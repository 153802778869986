import { Routes } from "../modules/routing/Routes"

export const routes: Routes = {
  contact: () => "/contact",
  cookiesPolicy: () => "/cookies",
  index: () => "/",
  landing: () => "/landing",
  privacyPolicy: () => "/privacy",
  regulations: () => "/regulations",
  survey: {
    initial: {
      index: () => "/survey/initial",
      result: (instanceId: string) => `/survey/result?instanceId=${instanceId}`
    }
  }
}
