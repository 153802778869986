import { SurveyInstance } from "../../../SurveyInstance"
import { GetInstanceQuery } from "../../../SurveyService.generated"
import { convertAnswers } from "./convertAnswers"
import { convertSurveyDefinition } from "./convertSurveyDefinition"

export const convertSurveyInstance = (input: GetInstanceQuery["survey"]["getSurveyInstance"]): SurveyInstance => ({
  answers: convertAnswers(input.answers),
  definition: convertSurveyDefinition(input.surveyDefinition),
  fillingDuration: input.fillingDurationInSeconds,
  id: input.id,
  intervieweeId: input.intervieweeId || null,
  result: input.result || null
})
