import { SurveyInstanceAnswers } from "../../../SurveyInstanceAnswers"
import { GetInstanceQuery } from "../../../SurveyService.generated"
import { convertAnswerValue } from "./convertAnswerValue"

export const convertAnswers = (input: GetInstanceQuery["survey"]["getSurveyInstance"]["answers"]): SurveyInstanceAnswers =>
  input.reduce<SurveyInstanceAnswers>((acc, answer) => {
    if ("id" in answer.question) {
      return {
        [answer.question.id]: convertAnswerValue(answer),
        ...acc
      }
    }

    throw Error("Question id not found in the answer response")
  }, {})
