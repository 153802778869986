import { SurveyQuestionType } from "../../../../../graphql/types.generated"
import { SurveyDefinition } from "../../../SurveyDefinition"
import { FindDefinitionQuery } from "../../../SurveyService.generated"

export const convertSurveyDefinition = (
  input: Exclude<FindDefinitionQuery["survey"]["findSurveyByName"], null | undefined>
): SurveyDefinition => ({
  id: input.id,
  questions: input.questions.map((question) => {
    if (question.questionType === SurveyQuestionType.LikertScale && "responseSet" in question) {
      return {
        id: question.id,
        content: question.content,
        responseSet: question.responseSet,
        type: SurveyQuestionType.LikertScale
      }
    } else if (question.questionType === SurveyQuestionType.SingleChoice && "answers" in question) {
      return {
        answers: question.answers.map((answer) => ({
          id: answer.id,
          content: answer.content
        })),
        id: question.id,
        content: question.content,
        type: SurveyQuestionType.SingleChoice
      }
    } else if (question.questionType === SurveyQuestionType.YesNo) {
      return {
        id: question.id,
        content: question.content,
        type: SurveyQuestionType.YesNo
      }
    } else {
      throw Error("Invalid question format")
    }
  })
})
