/** @jsxImportSource @emotion/react */

import { FormikHelpers } from "formik"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { sendResultByEmail } from "../../../../../../../../modules/domain/survey/initial/actions"
import { EmailForm, EmailFormValues } from "../../../../../../../design/system/form/EmailForm"

type Props = {
  showModal: () => void
  surveyInstanceId: string
}

export const SendResultForm: React.FC<Props> = ({ showModal, surveyInstanceId }) => {
  const dispatch = useDispatch()
  const onSubmit = useCallback(
    (values: EmailFormValues, formHelpers: FormikHelpers<EmailFormValues>) => {
      dispatch(
        sendResultByEmail({
          data: {
            ...values,
            surveyInstanceId
          },
          formHelpers,
          showModal
        })
      )
    },
    [dispatch, showModal, surveyInstanceId]
  )

  return <EmailForm onSubmit={onSubmit} />
}
