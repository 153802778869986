import { Messages } from "../Messages"

export const en: Messages = {
  collapse: "Collapse",
  domain: {
    hcc: {
      dimensions: {
        dilligence: "Dilligence",
        emotionalStability: "Emotional stability",
        empathy: "Empathy",
        extraversion: "Extraversion",
        independence: "Independence",
        openness: "Openness",
        professionalSelfEfficacy: "Professional self efficacy"
      }
    }
  },
  expand: "Expand",
  form: {
    file: {
      browse: "Browse",
      choose: {
        multiple: "Choose files",
        one: "Choose file"
      }
    },
    send: "Send",
    validation: {
      field: {
        required: "This field is required"
      }
    }
  },
  no: "No",
  yes: "Yes"
}
