import { takeLatest } from "redux-saga/effects"
import { PayloadAction } from "../../action/PayloadAction"
import { Injector } from "../../di/Injector"
import { noop } from "../../saga/noop"
import { BlackBoxGenerator } from "../../saga/types"
import { Language } from "../Language"
import { SET_LANGUAGE } from "../actions"

export function* watchSetLanguage(injector: Injector): BlackBoxGenerator {
  yield takeLatest(SET_LANGUAGE, function* (action: PayloadAction<Language>): BlackBoxGenerator {
    injector.doc.querySelector("html")?.setAttribute("lang", action.payload)
    yield* noop()
  })
}
