import { Logger, LogArg, Loggable } from "./Logger"

/* eslint-disable no-console */
export class ConsoleLogger implements Logger {
  protected DEFAULT_ERROR_MESSAGE = "Unknown error"

  private makeConsoleArgs({ message, error }: Loggable): [string] | [unknown] | [string, unknown] {
    if (message != null) {
      if (error != null) {
        return [message, error]
      }
      return [message]
    } else if (error != null) {
      return [error]
    }

    return [this.DEFAULT_ERROR_MESSAGE]
  }

  debug(arg: LogArg): void {
    console.debug(...this.makeConsoleArgs(arg))
  }

  info(arg: LogArg): void {
    console.info(...this.makeConsoleArgs(arg))
  }

  warn(arg: LogArg): void {
    console.warn(...this.makeConsoleArgs(arg))
  }

  error(arg: LogArg): void {
    console.error(...this.makeConsoleArgs(arg))
  }
}
/* eslint-enable no-console */
