import { Injector } from "../../di/Injector"
import { noop } from "../../saga/noop"
import { BlackBoxGenerator } from "../../saga/types"
import { setIsFocused } from "../actions"

export function* watchFocusChange(injector: Injector): BlackBoxGenerator {
  const { win, store } = injector

  win.addEventListener("focus", () => {
    store.dispatch(setIsFocused(true))
  })

  win.addEventListener("blur", () => {
    store.dispatch(setIsFocused(false))
  })

  yield* noop()
}
