/** @jsxImportSource @emotion/react */

import { HCCSurveyDimensionResult } from "../../../../../../../../modules/domain/survey/hcc/HCCSurveyDimensionResult"
import { useTheme } from "../../../../../../../design/hooks/useTheme"
import { P } from "../../../../../../../design/system/P"
import { H2 } from "../../../../../../../design/system/headers/H2"
import { useScreen } from "../../../../../../../screen/hooks/useScreen"

type Props = {
  title: string
  result: HCCSurveyDimensionResult
}

export const DescriptionBox: React.FC<Props> = ({ title, result }) => {
  const { window } = useScreen()
  const { colors, gaps } = useTheme()
  const shouldUseSmallerPaddings = window.width < 640

  return (
    <div
      css={{
        border: `1px solid ${colors.primary[300]}`,
        borderRadius: 12,
        padding: shouldUseSmallerPaddings ? gaps[6] : 90
      }}
    >
      <H2 textAlign="left">{title}</H2>
      <P>{result.description}</P>
    </div>
  )
}
