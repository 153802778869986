/** @jsxImportSource @emotion/react */

import { FormikErrors } from "formik"
import alert from "../../../../images/forms/alert.png"
import { useTheme } from "../../hooks/useTheme"

type Props = {
  message?: string | string[] | FormikErrors<unknown>
}

export const FormFieldError: React.FC<Props> = ({ message }) => {
  const { colors, fonts, gaps } = useTheme()

  return (
    <div
      css={{
        alignItems: "bottom",
        color: colors.danger[900],
        display: "grid",
        columnGap: gaps[3],
        fontFamily: fonts.inter,
        fontSize: 13,
        fontWeight: 400,
        gridTemplateColumns: "auto 1fr",
        height: 24 + gaps[3],
        lineHeight: "24px",
        paddingTop: gaps[3]
      }}
      role="status"
    >
      {message != null && (
        <>
          <img alt="Alert" height={18} src={alert} width={20} />
          <div>{message}</div>
        </>
      )}
    </div>
  )
}
