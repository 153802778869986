/** @jsxImportSource @emotion/react */
import { useTheme } from "../../../design/hooks/useTheme"
import { useScreen } from "../../../screen/hooks/useScreen"
import { HProps } from "./HProps"

export const H1: React.FC<HProps> = ({ children, color, marginBottom, marginTop, textAlign }) => {
  const { window } = useScreen()
  const { colors, fonts } = useTheme()
  const shouldUseSmallerFont = window.width < 407

  return (
    <h1
      css={{
        color: color || colors.neutral[900],
        fontFamily: fonts.inter,
        fontSize: `${shouldUseSmallerFont ? 42 : 48}px`,
        fontWeight: 700,
        lineHeight: `${shouldUseSmallerFont ? 52 : 60}px`,
        margin: "0 0 8px 0",
        marginBottom,
        marginTop,
        textAlign
      }}
    >
      {children}
    </h1>
  )
}
