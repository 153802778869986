import { Theme } from "../../../../modules/design/Theme"
import { CSSObject } from "../CSSObject"

type Params = {
  hasErrors?: boolean
}

export const textInputCSS = (theme: Theme, { hasErrors }: Params = {}): CSSObject => {
  const { colors, fonts } = theme

  return {
    "&::placeholder": {
      color: colors.form.placeholder
    },
    backgroundColor: colors.white,
    border: `1px solid ${hasErrors ? colors.danger[900] : colors.quaternary[100]}`,
    boxShadow: "0px 4px 8px -2px rgba(17, 24, 39, 0.1)",
    borderRadius: 8,
    color: colors.neutral[700],
    fontFamily: fonts.inter,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "24px",
    outline: "none",
    padding: "21px 30px"
  }
}
