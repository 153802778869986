import { Messages } from "../../../modules/i18n/Messages"
import { en } from "../../../modules/i18n/messages/en"
import { pl } from "../../../modules/i18n/messages/pl"
import { exhaustiveCheck } from "../../../modules/typed/exhaustiveCheck"
import { useLanguage } from "./useLanguage"

export const useMessages = (): Messages => {
  const language = useLanguage()

  switch (language) {
    case "en":
      return en
    case "pl":
      return pl
    default:
      return exhaustiveCheck(language)
  }
}
