/** @jsxImportSource @emotion/react */
import { useTheme } from "../../../../../../../../design/hooks/useTheme"
import { P } from "../../../../../../../../design/system/P"
import { H0 } from "../../../../../../../../design/system/headers/H0"
import { HCCLogo } from "../../../../../../../../domain/logo/HCCLogo"
import { FlashBar } from "../../../../../FlashBar"
import { SectionTopBackground } from "../../../sections/top/SectionTopBackground"

export const SectionTop: React.FC = () => {
  const { colors, gaps } = useTheme()

  return (
    <section
      css={{
        backgroundColor: "rgba(242, 247, 255, 0.7)",
        overflow: "hidden"
      }}
    >
      <div
        css={{
          display: "flex",
          backgroundColor: colors.white,
          justifyContent: "center",
          padding: 30
        }}
      >
        <HCCLogo />
      </div>
      <div
        css={{
          position: "relative"
        }}
      >
        <SectionTopBackground />
        <FlashBar />
        <div
          css={{
            display: "grid",
            margin: "0 auto",
            maxWidth: 1080,
            padding: `146px ${gaps[5]}px 228px`,
            position: "relative",
            rowGap: gaps[4]
          }}
        >
          <H0 textAlign="center">Wynik testu osobowości</H0>
          <P textAlign="center">
            Dziękujemy za wykonanie testu! Zapoznaj się z poniższymi opisami, aby lepiej poznać jakie cechy cię wyróżniają. Na tej podstawie
            możesz z większą łatwością podjać decyzje zawodowe lub w jakim kierunku dalej kształcić się.
          </P>
        </div>
      </div>
    </section>
  )
}
