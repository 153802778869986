import { Theme } from "../design/Theme"
import { Intent } from "./Intent"

export const getForegroundColorForIntent = (intent: Intent, useAltShade: boolean, colors: Theme["colors"]): string => {
  const shade = 600

  switch (intent) {
    case "Danger":
      return colors.white
    case "Primary":
      return useAltShade ? colors.primary[shade] : colors.white
    case "Secondary":
      return useAltShade ? colors.secondary[shade] : colors.white
    case "Success":
      return colors.white
    case "Tertiary":
      return useAltShade ? colors.tertiary[900] : colors.tertiary[600]
    case "Quaternary":
      return useAltShade ? colors.quaternary[shade] : colors.white
    case "Quinary":
      return useAltShade ? colors.primary[shade] : colors.quinary[1]
    default:
      return "inherit"
  }
}
