import { Providers } from "./components/providers/Providers"
import { RootRoutes } from "./components/routes/RootRoutes"
import { Injector } from "./modules/di/Injector"

type Props = {
  injector: Injector
}

export const App: React.FC<Props> = ({ injector }) => (
  <Providers injector={injector}>
    <RootRoutes />
  </Providers>
)
