/** @jsxImportSource @emotion/react */

type Props = {
  paddingBottom?: number
}

export const QuestionFieldSet: React.FC<Props> = ({ children, paddingBottom }) => (
  <fieldset
    css={{
      maxWidth: 1182,
      paddingBottom
    }}
  >
    {children}
  </fieldset>
)
