/** @jsxImportSource @emotion/react */

import { SurveyInstanceAnswerValue } from "../../../../../../../modules/domain/survey/SurveyInstanceAnswerValue"
import { SurveyQuestion } from "../../../../../../../modules/domain/survey/SurveyQuestion"
import { LikertScaleIndex } from "../../../../../../../modules/domain/survey/scale/likert/LikertScaleIndex"
import { LikertScaleQuestionField } from "./likert/LikertScaleQuestionField"
import { SingleChoiceQuestionField } from "./singleChoice/SingleChoiceQuestionField"
import { YesNoQuestionField } from "./yesNo/YesNoQuestionField"

type Props = {
  onChange: (value: SurveyInstanceAnswerValue) => void
  question: SurveyQuestion
  value?: SurveyInstanceAnswerValue
}

export const QuestionField: React.FC<Props> = ({ question, onChange, value }) => (
  <div key={question.id}>
    {((): JSX.Element => {
      switch (question.type) {
        case "YesNo":
          return <YesNoQuestionField onChange={onChange} question={question} value={value as boolean | undefined} />
        case "SingleChoice":
          return <SingleChoiceQuestionField onChange={onChange} question={question} value={value as string | undefined} />
        case "LikertScale":
          return <LikertScaleQuestionField onChange={onChange} question={question} value={value as LikertScaleIndex | undefined} />
      }
    })()}
  </div>
)
