import { put, takeLatest } from "redux-saga/effects"
import { Injector } from "../../../../di/Injector"
import { propagateUnknownError } from "../../../../error/actions"
import { clearFlash, setFlash } from "../../../../flash/actions"
import { BlackBoxGenerator } from "../../../../saga/types"
import { clearInstance, GET_DEFINITION, setDefinition } from "../actions"

export function* watchGetDefinition(injector: Injector): BlackBoxGenerator {
  yield takeLatest(GET_DEFINITION, function* (): BlackBoxGenerator {
    yield put(clearFlash())
    const service = injector.domain.survey.service

    try {
      const definition = (yield service.findDefinition("initial")) as Awaited<ReturnType<typeof service.findDefinition>>

      if (definition != null) {
        yield put(clearInstance())
        yield put(setDefinition(definition))
      } else {
        yield put(
          setFlash({
            message: "Cannot find initial survey definition",
            intent: "Danger"
          })
        )
      }
    } catch (error) {
      yield put(propagateUnknownError(error))
    }
  })
}
