/** @jsxImportSource @emotion/react */

import { useTheme } from "../../../../../../../design/hooks/useTheme"

type Props = {
  color: string
}

export const LikertScaleLabel: React.FC<Props> = ({ children, color }) => {
  const { fonts } = useTheme()

  return (
    <span
      css={{
        color,
        fontFamily: fonts.inter,
        fontWeight: 700,
        fontSize: 18,
        lineHeight: "28px"
      }}
    >
      {children}
    </span>
  )
}
