/** @jsxImportSource @emotion/react */

import { useTheme } from "../../../../../../../../design/hooks/useTheme"

export const Copyright: React.FC = ({ children }) => {
  const { colors, fonts } = useTheme()

  return (
    <span
      css={{
        color: colors.neutral[700],
        fontFamily: fonts.inter,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px"
      }}
    >
      {children}
    </span>
  )
}
