/** @jsxImportSource @emotion/react */

import { useTheme } from "../../../../../../../../design/hooks/useTheme"

type Props = {
  marginBottom?: number
  strong?: boolean
  textAlign?: "center" | "left"
}

export const FooterParagraph: React.FC<Props> = ({ children, marginBottom, strong, textAlign }) => {
  const { colors, fonts } = useTheme()

  return (
    <p
      css={{
        color: colors.neutral[700],
        fontFamily: fonts.inter,
        fontSize: "16px",
        fontWeight: strong ? 700 : 400,
        lineHeight: "20px",
        margin: 0,
        marginBottom,
        textAlign
      }}
    >
      {children}
    </p>
  )
}
