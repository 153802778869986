/** @jsxImportSource @emotion/react */
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { getDefinition, getInstance } from "../../../../modules/domain/survey/initial/actions"
import { Footer } from "../../../domain/footer/Footer"
import { SectionForm } from "./sections/form/SectionForm"
import { SectionTop } from "./sections/top/SectionTop"

export const InitialSurveyPage: React.FC = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const instanceId = searchParams.get("instanceId")

  useEffect(() => {
    if (instanceId) {
      dispatch(getInstance(instanceId))
    } else {
      dispatch(getDefinition())
    }
  }, [dispatch, instanceId])

  return (
    <>
      <SectionTop />
      <SectionForm />
      <Footer />
    </>
  )
}
