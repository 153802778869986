import { NavigateFunction } from "react-router-dom"
import { emptyAction, payloadAction } from "../../../action/creators"
import { FormHelpers } from "../../../form/FormHelpers"
import { SendResultByEmailData } from "../SendResultByEmailData"
import { SurveyData } from "../SurveyData"
import { SurveyDefinition } from "../SurveyDefinition"
import { SurveyFillingStage } from "../SurveyFillingStage"
import { SurveyInstance } from "../SurveyInstance"
import { HCCSurveyResult } from "../hcc/HCCSurveyResult"

const prefix = "SURVEY/INITIAL"

export const GET_DEFINITION = `${prefix}/DEFINITION/GET`
export const getDefinition = emptyAction(GET_DEFINITION)

export const GET_INSTANCE = `${prefix}/INSTANCE/GET`
export const getInstance = payloadAction<string>(GET_INSTANCE)

export const SET_DEFINITION = `${prefix}/DEFINITION/SET`
export const setDefinition = payloadAction<SurveyDefinition>(SET_DEFINITION)

export const SET_INSTANCE = `${prefix}/INSTANCE/SET`
export const setInstance = payloadAction<SurveyInstance | string>(SET_INSTANCE)

export const CLEAR_INSTANCE = `${prefix}/INSTANCE/CLEAR`
export const clearInstance = emptyAction(CLEAR_INSTANCE)

export type UploadPayload = {
  data: SurveyData
  formHelpers: FormHelpers
  navigate: NavigateFunction
}

export const UPLOAD = `${prefix}/UPLOAD`
export const upload = payloadAction<UploadPayload>(UPLOAD)

export const SET_RESULT = `${prefix}/RESULT/SET`
export const setResult = payloadAction<HCCSurveyResult | string | null>(SET_RESULT)

export const SET_STAGE = `${prefix}/STAGE/SET`
export const setStage = payloadAction<SurveyFillingStage>(SET_STAGE)

export type SendResultByEmailPayload = {
  data: SendResultByEmailData
  formHelpers: FormHelpers
  showModal: () => void
}

export const SEND_RESULT_BY_EMAIL = `${prefix}/RESULT/SEND_BY_EMAIL`
export const sendResultByEmail = payloadAction<SendResultByEmailPayload>(SEND_RESULT_BY_EMAIL)
