/** @jsxImportSource @emotion/react */
import { useTheme } from "../../../../../design/hooks/useTheme"

type Props = {
  backgroundColor: string
}

export const IntroBox: React.FC<Props> = ({ backgroundColor, children }) => {
  const { gaps } = useTheme()

  return (
    <div
      css={{
        backgroundColor,
        boxShadow: "0px 8px 12px -4px rgba(184, 184, 210, 0.2)",
        borderRadius: 12,
        maxWidth: 312,
        padding: gaps[5]
      }}
    >
      {children}
    </div>
  )
}
