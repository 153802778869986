/** @jsxImportSource @emotion/react */
import { differenceInSeconds } from "date-fns"
import { FormikHelpers, useFormik } from "formik"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { SurveyDefinition } from "../../../../../../modules/domain/survey/SurveyDefinition"
import { SurveyInstance } from "../../../../../../modules/domain/survey/SurveyInstance"
import { SurveyInstanceAnswerValue } from "../../../../../../modules/domain/survey/SurveyInstanceAnswerValue"
import { SurveyQuestion } from "../../../../../../modules/domain/survey/SurveyQuestion"
import { upload } from "../../../../../../modules/domain/survey/initial/actions"
import { createValidationSchemaForSurvey } from "../../../../../../modules/domain/survey/validation/createValidationSchemaForSurvey"
import { requiredString } from "../../../../../../modules/form/yup/requiredString"
import { requiredTrue } from "../../../../../../modules/form/yup/requiredTrue"
import { use } from "../../../../../../modules/functional/use"
import { Messages } from "../../../../../../modules/i18n/Messages"
import { Seconds } from "../../../../../../modules/time/Seconds"
import { delay } from "../../../../../../modules/time/delay"
import { FadeInOut } from "../../../../../animation/FadeInOut"
import { OpacityFocus } from "../../../../../animation/OpacityFocus"
import { useTheme } from "../../../../../design/hooks/useTheme"
import { Button } from "../../../../../design/system/buttons/Button"
import { ButtonHorizontalContent } from "../../../../../design/system/buttons/ButtonHorizontalContent"
import { GreaterThan } from "../../../../../design/system/icons/GreaterThan"
import { useMessages } from "../../../../../i18n/hooks/useMessages"
import { useLogger } from "../../../../../logger/hooks/useLogger"
import { useScreen } from "../../../../../screen/hooks/useScreen"
import { FirstInstructionModal } from "../../instructions/FirstInstructionModal"
import { SecondInstructionModal } from "../../instructions/SecondInstructionModal"
import { CaptchaSubsection } from "./CaptchaSubsection"
import { FinishLaterSubsection } from "./FinishLaterSubsection"
import { InitialSurveyFormValues } from "./InitialSurveyFormValues"
import { InitialSurveyRegulations } from "./InitialSurveyRegulations"
import { PagesNavigation } from "./PagesNavigation"
import { QuestionField } from "./fields/QuestionField"

type Props = {
  definition: SurveyDefinition
  instance: SurveyInstance | null
  numOfSteps: number
  setActiveStep: (step: number) => void
}

type TrackingState = {
  activeSince: Date | null
  fillingDuration: Seconds
}

type SurveyStage = {
  InstructionModal: React.FC
  questions: SurveyQuestion[]
  questionsPerPage: number
}

type SurveyStages = [SurveyStage, SurveyStage]

type Coords = {
  pageIdx: number
  stageIdx: 0 | 1
}

export const animationDuration = 500
const opacityOnOut = 0.3
const numOfQuestionsPerStage: [number] = [40]

const getNumOfPages = (stage: SurveyStage): number => Math.ceil(stage.questions.length / stage.questionsPerPage)

const getInitialCoords = (instance: SurveyInstance | null, stages: SurveyStages): Coords => {
  if (instance != null) {
    const stageIdx = use(
      stages.findIndex((stage) => stage.questions.find((question) => instance.answers[question.id] == null)),
      (idx) => (idx < 0 ? stages.length - 1 : idx) as Coords["stageIdx"]
    )
    const stage = stages[stageIdx]
    const questionIdx = use(
      stage.questions.findIndex((question) => instance.answers[question.id] == null),
      (idx) => (idx < 0 ? stage.questions.length - 1 : idx)
    )
    const pageIdx = Math.min(Math.floor(questionIdx / stage.questionsPerPage), getNumOfPages(stage) - 1)

    return {
      pageIdx,
      stageIdx
    }
  } else {
    return {
      pageIdx: 0,
      stageIdx: 0
    }
  }
}

const createValidationSchema = (definition: SurveyDefinition, messages: Messages): Yup.SchemaOf<InitialSurveyFormValues> =>
  Yup.object().shape({
    answers: createValidationSchemaForSurvey(definition, messages, true),
    email: Yup.string().email(),
    captchaToken: requiredString(messages),
    consents: Yup.object().shape({
      1: requiredTrue(messages),
      2: requiredTrue(messages)
    }),
    files: Yup.array()
  })

const createStages = (definition: SurveyDefinition, onInstructionClose: () => void): SurveyStages => [
  {
    InstructionModal: () => <FirstInstructionModal onClose={onInstructionClose} />,
    questions: definition.questions.slice(0, numOfQuestionsPerStage[0]),
    questionsPerPage: 6
  },
  {
    InstructionModal: () => <SecondInstructionModal onClose={onInstructionClose} />,
    questions: definition.questions.slice(numOfQuestionsPerStage[0]),
    questionsPerPage: 6
  }
]

const goTo = async (
  stage: SurveyStage,
  stages: [SurveyStage, SurveyStage],
  coords: Coords,
  setQuestionsVisibility: (visible: boolean) => void,
  setCoords: (coords: Coords) => void,
  back: boolean
): Promise<void> => {
  setQuestionsVisibility(false)
  await delay(animationDuration)
  const { pageIdx, stageIdx } = coords

  if (back) {
    if (pageIdx > 0) {
      setCoords({
        pageIdx: pageIdx - 1,
        stageIdx
      })
    } else if (stageIdx > 0) {
      const newStageIdx = (stageIdx - 1) as typeof stageIdx
      const newStage = stages[newStageIdx]
      setCoords({
        pageIdx: Math.max(getNumOfPages(newStage) - 1, 0),
        stageIdx: newStageIdx
      })
    }
  } else {
    if (pageIdx < getNumOfPages(stage) - 1) {
      setCoords({
        pageIdx: pageIdx + 1,
        stageIdx
      })
    } else if (stageIdx < stages.length - 1) {
      setCoords({
        pageIdx: 0,
        stageIdx: (stageIdx + 1) as typeof stageIdx
      })
    }
  }

  setQuestionsVisibility(true)
}

export const InitialSurveyForm: React.FC<Props> = ({ definition, instance, numOfSteps, setActiveStep }) => {
  const logger = useLogger()
  const screen = useScreen()
  const isScreenActive = screen.isFocused && screen.isVisible
  const { colors } = useTheme()
  const messages = useMessages()
  const dispatch = useDispatch()
  const [isInstructionVisible, setInstructionVisibility] = useState<boolean>(true)
  const onInstructionClose = useCallback(() => setInstructionVisibility(false), [])
  const stages: SurveyStages = useMemo(() => createStages(definition, onInstructionClose), [definition, onInstructionClose])
  const [trackingState, setTrackingState] = useState<TrackingState>({ activeSince: null, fillingDuration: instance?.fillingDuration || 0 })
  const [areQuestionsVisible, setQuestionsVisibility] = useState(true)

  const [coords, setCoords] = useState<Coords>(getInitialCoords(instance, stages))
  const { pageIdx, stageIdx } = coords
  const stage = stages[stageIdx]
  const { InstructionModal, questionsPerPage, questions } = stage
  const numOfPages = getNumOfPages(stage)
  const pageQuestions = questions.slice(pageIdx * questionsPerPage, (pageIdx + 1) * questionsPerPage)
  const isFirstPageOfFirstStage = stageIdx === 0 && pageIdx === 0
  const isLastPageOfLastStage = stageIdx === stages.length - 1 && pageIdx === numOfPages - 1
  const onBack = useCallback(() => goTo(stage, stages, coords, setQuestionsVisibility, setCoords, true), [stage, stages, coords])
  const onForward = useCallback(() => goTo(stage, stages, coords, setQuestionsVisibility, setCoords, false), [stage, stages, coords])
  const navigate = useNavigate()

  const onSubmit = useCallback(
    (data: InitialSurveyFormValues, formHelpers: FormikHelpers<InitialSurveyFormValues>) => {
      dispatch(
        upload({
          data: {
            ...data,
            id: definition.id,
            fillingDuration:
              trackingState.fillingDuration +
              (trackingState.activeSince != null ? differenceInSeconds(new Date(), trackingState.activeSince) : 0),
            instanceId: instance?.id,
            intervieweeId: instance?.intervieweeId || undefined
          },
          formHelpers,
          navigate
        })
      )
    },
    [dispatch, definition, instance, navigate, trackingState]
  )
  const validationSchema = useMemo(() => createValidationSchema(definition, messages), [definition, messages])
  const formik = useFormik<InitialSurveyFormValues>({
    validationSchema,
    initialValues: {
      answers: instance?.answers || {},
      captchaToken: "",
      consents: {
        1: false,
        2: false
      },
      files: []
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit
  })
  const answers = formik.values.answers
  const setAnswer = (questionId: string, value: SurveyInstanceAnswerValue): void => {
    formik.setFieldValue("answers", {
      ...answers,
      [questionId]: value
    })
  }
  const focusOnIdx = formik.isSubmitting ? -1 : pageQuestions.findIndex((question) => answers[question.id] == null)
  const numOfAnswers = definition.questions.filter((question) => answers[question.id] != null).length
  const completionPercentage = numOfAnswers / Math.max(definition.questions.length, 1)

  useEffect(() => {
    const newDate = new Date()

    setTrackingState((state) => ({
      activeSince: isScreenActive ? newDate : null,
      fillingDuration: state.fillingDuration + (state.activeSince != null ? differenceInSeconds(newDate, state.activeSince) : 0)
    }))
  }, [isScreenActive, setTrackingState])

  useEffect(() => {
    setActiveStep(Math.floor(completionPercentage * numOfSteps))
  }, [completionPercentage, numOfSteps, setActiveStep])

  useEffect(() => {
    if (trackingState.activeSince != null || trackingState.fillingDuration > 0) {
      logger.debug({
        message:
          trackingState.activeSince != null
            ? `Time tracking started at ${trackingState.activeSince}`
            : `Tracked total ${trackingState.fillingDuration} seconds of filling the survey`
      })
    }
  }, [logger, trackingState])

  useEffect(() => {
    if (pageIdx === 0) {
      setInstructionVisibility(true)
    }
  }, [pageIdx, stage])

  return (
    <form onSubmit={formik.handleSubmit}>
      {isInstructionVisible && <InstructionModal />}
      <div
        css={{
          borderBottom: isLastPageOfLastStage ? `solid 2px ${colors.neutral[300]}` : undefined
        }}
      >
        <FadeInOut duration={animationDuration} visible={areQuestionsVisible}>
          <OpacityFocus animationDuration={animationDuration} idx={focusOnIdx} opacityOnOut={opacityOnOut}>
            {pageQuestions.map(
              (question): JSX.Element => (
                <QuestionField
                  key={question.id}
                  onChange={(value) => setAnswer(question.id, value)}
                  question={question}
                  value={answers[question.id]}
                />
              )
            )}
          </OpacityFocus>
        </FadeInOut>
        <PagesNavigation
          disableBack={isFirstPageOfFirstStage}
          disableForward={focusOnIdx >= 0 || isLastPageOfLastStage}
          onBack={onBack}
          onForward={onForward}
          showBack={!isFirstPageOfFirstStage}
          showForward={!isLastPageOfLastStage}
        />
      </div>
      {isLastPageOfLastStage && (
        <>
          <InitialSurveyRegulations formik={formik} />
          <CaptchaSubsection formik={formik} />
          <div css={{ textAlign: "center" }}>
            <Button intent="Primary" disabled={formik.isSubmitting} type="submit">
              <ButtonHorizontalContent>
                ZOBACZ WYNIK <GreaterThan />
              </ButtonHorizontalContent>
            </Button>
          </div>
        </>
      )}
      <FinishLaterSubsection definition={definition} initialFormValues={formik.values} onSubmit={onSubmit} />
    </form>
  )
}
