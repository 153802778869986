/** @jsxImportSource @emotion/react */

import { useTheme } from "../../../../design/hooks/useTheme"

type Props = {
  borderRadius: number
  boxShadow?: string
  offset: number
}

export const TestimonialSubCard: React.FC<Props> = ({ borderRadius, boxShadow, offset }) => {
  const { colors } = useTheme()

  return (
    <div
      css={{
        backgroundColor: colors.white,
        borderRadius,
        bottom: -offset,
        boxShadow,
        height: 2 * borderRadius,
        left: offset,
        position: "absolute",
        width: `calc(100% - ${2 * offset}px)`
      }}
    ></div>
  )
}
