import { payloadAction } from "../action/creators"
import { WindowState } from "./WindowState"

const prefix = "SCREEN"

export const SET_WINDOW = `${prefix}/WINDOW/SET`
export const setWindow = payloadAction<WindowState>(SET_WINDOW)

export const SET_IS_FOCUSED = `${prefix}/IS_FOCUSED/SET`
export const setIsFocused = payloadAction<boolean>(SET_IS_FOCUSED)

export const SET_IS_VISIBLE = `${prefix}/IS_VISIBLE/SET`
export const setIsVisible = payloadAction<boolean>(SET_IS_VISIBLE)
