/** @jsxImportSource @emotion/react */

import { Link } from "react-router-dom"
import { StandardRegulations as Regulations } from "../../../../../design/system/regulations/standard/StandardRegulations"
import { StandardRegulationsHeader as Header } from "../../../../../design/system/regulations/standard/StandardRegulationsHeader"
import { StandardRegulationsItem as Item } from "../../../../../design/system/regulations/standard/StandardRegulationsItem"
import { StandardRegulationsSubHeader as SubHeader } from "../../../../../design/system/regulations/standard/StandardRegulationsSubHeader"
import { ContactEmail } from "../../../../../domain/ContactEmail"
import { useRoutes } from "../../../../../routes/hooks/useRoutes"
import { ServiceLink } from "../../../ServiceLink"

const startAt = "?"

export const ServiceRegulations: React.FC = () => {
  const routes = useRoutes()

  return (
    <Regulations>
      <Header>Regulamin</Header>
      <SubHeader value="1.">Postanowienia ogólne</SubHeader>
      <Item value="1.1.">
        Niniejszy Regulamin opisuje warunki i zasady korzystania przez Użytkowników z Portalu udostępnianego pod adresem: <ServiceLink />.
      </Item>
      <Item value="1.2.">
        Usługa przeprowadzenia testu osobowości, w tym otrzymania wyniku testu świadczona jest przez Usługodawcę na rzecz Użytkowników
        nieodpłatnie.
      </Item>
      <Item value="1.3.">Zakazane jest dostarczanie przez Użytkowników treści o charakterze bezprawnym.</Item>
      <Item value="1.4.">
        Użytkownik zobowiązany jest korzystać z Portalu zgodnie z przepisami prawa, dobrymi obyczajami oraz z poszanowaniem własności
        intelektualnej, w tym autorskich praw przysługujących Usługodawcy w stosunku do materiałów, grafik, opisów dostępnych na Portalu.
      </Item>
      <SubHeader value="2.">Definicje</SubHeader>
      <Item value="2.1.">
        Portal - strona internetowa na której Usługodawca świadczy Usługę dostępną pod adresem: <ServiceLink />.
      </Item>
      <Item value="2.2.">Regulamin - niniejszy Regulamin.</Item>
      <Item value="2.3.">Umowa - umowa o świadczenie Usług drogą elektroniczną wskazanych w niniejszym Regulaminie.</Item>
      <Item value="2.4.">
        Usługodawca - Peaksales sp. z o.o. z siedzibą w Warszawie (02-057), przy ul. Filtrowej 39, wpisana do rejestru przedsiębiorców
        Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m.st. Warszawy, XIII Wydział Krajowego Rejestru Sądowego pod numerem
        KRS 0000784488, NIP 5272892222, REGON 383240227.
      </Item>
      <Item value="2.5.">
        Usługi - usługi świadczone przez Usługodawcę drogą elektroniczną polegające na (1) umożliwieniu Użytkownikom przeprowadzenia testu
        osobowości oraz otrzymania odpowiednich wyników, a także (2) dołączenia do bazy kandydatów poprzez dobrowolne załączenie CV dla
        potrzeb rekrutacji.
      </Item>
      <Item value="2.6.">
        Użytkownik - każda osoba fizyczna posiadająca pełną zdolność do czynności prawnych, przeglądająca Portal i korzystająca z Usług.
      </Item>
      <SubHeader value="3.">Test osobowości</SubHeader>
      <Item value="3.1.">
        Za pomocą Portalu Usługodawca świadczy Usługi polegające na udostępnieniu Użytkownikom możliwości przeprowadzenia testu osobowości,
        otrzymania wyniku wypełnionego testu, a także dołączenia do bazy kandydatów poprzez dobrowolne załączenie CV.
      </Item>
      <Item value="3.2.">
        Test osobowości nie jest narzędziem diagnostycznym. Pytania w nim zawarte nie dotyczą jednocześnie informacji o stanie zdrowia
        Użytkownika zarówno fizycznego, jak i psychicznego.
      </Item>
      <Item value="3.3.">
        Umowa pomiędzy Usługodawcą a Użytkownikiem zostaje zawarta na czas nieoznaczony z chwilą zaakceptowania przez Użytkownika
        niniejszego Regulaminu poprzez zaznaczenie okienka przy podawaniu adresu e-mail oraz wysłanie wypełnionego testu lub załączenie CV.
      </Item>
      <Item value="3.4.">
        W ramach Usług Usługodawca zobowiązuje się przekazać Użytkownikowi, po wypełnieniu przez Użytkownika testu osobowości, wynik
        przeprowadzonej analizy osobowości. Wyniki testu Użytkownik otrzymuje na podany adres e-mail.
      </Item>
      <Item value="3.5.">
        W celu wykonania testu osobowości i otrzymania wyników Użytkownik:
        <Item value="a.">Wybiera na górze Portalu panel „Test” lub z poziomu strony głównej Portalu klika „Rozpocznij test”.</Item>
        <Item value="b.">Wskazuje w jakim stopniu zgadza się z danym stwierdzeniem składającym się na test osobowości.</Item>
        <Item value="c.">
          Po wypełnieniu testu osobowości podaje prawdziwy i aktualny adres e-mail w celu otrzymania wyników przeprowadzonego testu.
        </Item>
        <Item value="d.">Zobowiązany jest zaakceptować postanowienia niniejszego Regulaminu.</Item>
      </Item>
      <Item value="3.6.">
        Użytkownik może w każdej chwili dokończyć test osobowości w innym terminie. W tym celu podaje adres e-mail na który otrzyma link za
        pomocą którego będzie mógł powrócić w dowolnej chwili do kontynuowania wypełniania testu.
      </Item>
      <Item value="3.7.">Użytkownik niezwłocznie otrzyma wyniki testu na podany adres e-mail.</Item>
      <Item value="3.8.">
        Wynik testu osobowości są generowane automatycznie na podstawie udzielonych przez Użytkownika odpowiedzi. Użytkownik otrzymuje na
        ich podstawie opis swojej osobowości.
      </Item>
      <SubHeader value="4.">Baza kandydatów</SubHeader>
      <Item value="4.1.">
        Użytkownik po przeprowadzonym teście osobowości może dobrowolnie załączyć CV i dołączyć w ten sposób do bazy kandydatów Usługodawcy.
      </Item>
      <Item value="4.2.">
        Usługodawca może skontaktować się z Użytkownikiem w celu złożenia oferty pracy w przypadku zgłoszenia Usługodawcy, przez jego
        klienta, zapotrzebowania na projekt lub stałe zlecenie, które odpowiadają kwalifikacjom Użytkownika, ocenianym na podstawie
        załączonego CV, oraz typowi osobowości przypisanemu danemu Użytkownikowi poprzez wypełnienie testu.
      </Item>
      <SubHeader value="5.">Ochrona danych osobowych</SubHeader>
      <Item value="5.1.">
        Świadczenie przez Usługodawcę Usługi testu osobowości wiąże się z przetwarzaniem danych osobowych Użytkowników, które zostały podane
        w celu otrzymania wyniku testu osobowości lub dołączenia do bazy kandydatów.
      </Item>
      <Item value="5.2.">
        Usługodawca przetwarza dane osobowe Użytkownika obejmujące adres e-mail, udzielone odpowiedzi na teście osobowości, wyniki testu
        osobowości oraz dane osobowe zawarte w CV, jeżeli Użytkownik zdecydował się na jego załączenie.
      </Item>
      <Item value="5.3.">
        Administratorem danych osobowych jest Usługodawca. Szczegółowe informacje o przetwarzaniu danych osobowych Użytkowników oraz
        przysługujących im prawach znajdują się w <Link to={routes.privacyPolicy()}>polityce prywatności</Link>.
      </Item>
      <SubHeader value="6.">Rozwiązanie umowy</SubHeader>
      <Item value="6.1.">
        Użytkownik oraz Usługodawca mogą w dowolnym momencie, bez podawania przyczyn, rozwiązać umowę o świadczenie usług drogą
        elektroniczną na Usługi objęte niniejszym Regulaminem ze skutkiem natychmiastowym.
      </Item>
      <Item value="6.2.">
        W przypadku rozwiązania umowy o świadczenie usług drogą elektroniczną Usługodawca usuwa w terminie 5 dni roboczych wyniki
        przeprowadzonego testu oraz gdy Użytkownik załączył CV – usuwa go w tym samym terminie z bazy kandydatów.
      </Item>
      <SubHeader value="7.">Reklamacje</SubHeader>
      <Item value="7.1.">
        Użytkownik ma prawo złożyć reklamację dotyczącą niewłaściwego działania Portalu lub niewykonania albo nienależytego wykonania Usług
        przez Usługodawcę poprzez przesłanie wiadomości mailowej na adres: <ContactEmail />.
      </Item>
      <Item value="7.2.">
        Użytkownik przesyłając reklamacje zobowiązany jest korzystać z adresu e-mail podanego podczas wypełniania testu osobowości oraz
        przedstawić opis sytuacji stanowiącej podstawę złożonej reklamacji.
      </Item>
      <Item value="7.3.">
        Usługodawca udzieli odpowiedzi na reklamację w terminie 14 dni od daty jej otrzymania, chyba że konieczne okaże się uzyskanie od
        Użytkownika bardziej szczegółowych informacji stanowiących podstawę reklamacji; wówczas Usługodawca udzieli odpowiedzi w terminie 7
        dni od dnia otrzymania uzupełniającej odpowiedzi.
      </Item>
      <SubHeader value="8.">Odpowiedzialność</SubHeader>
      <Item value="8.1.">
        Usługodawca nie ponosi odpowiedzialności w przypadkach przerwy w świadczeniu Usług wynikających z awarii lub problemów technicznych
        pozostających poza wpływem Usługodawcy.
      </Item>
      <Item value="8.2.">
        Usługodawca nie ponosi odpowiedzialności za niewykonanie Usługi w przypadku podania nieprawidłowego adresu e-mail.
      </Item>
      <SubHeader value="9.">Wymaganie techniczne</SubHeader>
      <Item value="9.1">
        W celu korzystania z Portalu niezbędne jest posiadanie przez Użytkownika urządzenia teleinformatycznego z dostępem do sieci Internet
        oraz posługiwanie się jedną z przeglądarek internetowych zaktualizowanych do wersji pozwalającej na korzystanie z Portalu.
      </Item>
      <Item value="9.2">Przeglądarka Użytkownika powinna mieć włączoną obsługę javascript.</Item>
      <SubHeader value="10.">Zmiany regulaminu</SubHeader>
      <Item value="10.1.">
        Usługodawca uprawniony jest do zmiany postanowień niniejszego Regulaminu w drodze powiadomienia Użytkownika na adres e-mail podany
        przy wypełnianiu testu osobowości, w przypadku gdy wynika to z:
        <Item value="a.">
          konieczności dostosowania Regulaminu do zmian przepisów prawa powszechnie obowiązującego, które mają wpływ na jego postanowienia;
        </Item>
        <Item value="b.">
          konieczności dostosowania Regulaminu do decyzji, nakazów, zakazów, orzeczeń, wytycznych lub zaleceń uprawnionych organów władzy
          publicznej
        </Item>
        <Item value="c.">rozbudowy, zmiany lub rezygnacji z Usług oferowanych Użytkownikom w ramach Portalu;</Item>
        <Item value="d.">zmiany warunków technicznych Usług świadczonych drogą elektroniczną na podstawie Regulaminu.</Item>
      </Item>
      <Item value="10.2.">
        Nowy Regulamin wchodzi w życie w terminie 5 dni od dnia powiadomienia Użytkownika o zmianach postanowień Regulaminu.
      </Item>
      <Item value="10.3.">
        Użytkownik ma prawo w terminie 5 dni, od dnia otrzymania powiadomienia o zmianie Regulaminu, złożyć oświadczenie o braku zgody na
        wprowadzone zmiany poprzez przesłanie wiadomości mailowej na adres: <ContactEmail />
      </Item>
      <Item value="10.4.">Jeżeli Użytkownik nie złoży powyższego oświadczenia uznaje się, że zaakceptował on nową wersję Regulaminu.</Item>
      <Item value="10.5.">
        Złożenie oświadczenia o braku zgody na wprowadzone do Regulaminu zmiany skutkuje rozwiązaniem umowy o świadczenie usług drogą
        elektroniczną z momentem wejścia w życie nowego Regulaminu.
      </Item>
      <SubHeader value="11.">Postanowienia końcowe</SubHeader>
      <Item value="11.1.">Regulamin wchodzi w życie w dniu: {startAt}.</Item>
      <Item value="11.2.">W zakresie nieuregulowanym w niniejszym Regulaminie zastosowanie mają przepisy prawa polskiego.</Item>
      <Item value="11.3.">
        Jeżeli jakiekolwiek istniejące lub przyszłe postanowienie lub część postanowienia Regulaminu zostanie uznane za nieważne lub
        niewykonalne w całości lub części z dowolnego powodu obecnie lub w przyszłości, jego nieważność lub niewykonalność nie ma wpływu na
        wykonalność pozostałych postanowień niniejszego Regulaminu. Niniejszy Regulamin będzie interpretowany zgodnie z przepisami prawa
        tak, jakby niewykonalne postanowienie nigdy nie było w nim zawarte, a Usługodawca i Użytkownik w dobrej wierze rozpoczną negocjacje
        mające na celu przeformułowanie takiego postanowienia, aby było ono wykonalne i skuteczne i aby odzwierciedlało intencje Usługodawcy
        i Użytkownika w chwili akceptacji Regulaminu.
      </Item>
      <Item value="11.4.">
        Ewentualne spory prawne, wynikające z Regulaminu, będą rozstrzygane pomiędzy Usługodawcą a Użytkownikiem na drodze polubownej.
        Spory, których nie udało się rozwiązać na drodze polubownej będą rozstrzygane przez sądy powszechne według właściwości ogólnej.
      </Item>
    </Regulations>
  )
}
