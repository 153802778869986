/** @jsxImportSource @emotion/react */
import arrowLeftDisabled from "../../../../../images/pages/landing/arrow-left-disabled.png"
import arrowRightEnabled from "../../../../../images/pages/landing/arrow-right-enabled.png"
import { Milliseconds } from "../../../../../modules/time/Milliseconds"

type Props = {
  animationDuration: Milliseconds
  direction: "Back" | "Forward"
  enabled: boolean
  onClick(): void
}

export const NavArrow: React.FC<Props> = ({ animationDuration, direction, enabled, onClick }) => {
  const generateTransition = (property: string): string => `${property} ${animationDuration}ms ease-in`
  return (
    <img
      alt={direction}
      css={{
        cursor: enabled ? "pointer" : "not-allowed",
        transform: (direction === "Forward" && !enabled) || (direction === "Back" && enabled) ? ["scaleX(-1)"] : [],
        transition: [generateTransition("height"), generateTransition("width")]
      }}
      height={enabled ? 19 : 12}
      onClick={onClick}
      src={enabled ? arrowRightEnabled : arrowLeftDisabled}
      width={enabled ? 39 : 24}
    />
  )
}
