/** @jsxImportSource @emotion/react */
import { useTheme } from "../../../../design/hooks/useTheme"
import { H2 } from "../../../../design/system/headers/H2"

type Props = { description: string; icon: string; title: string }

export const Dimension: React.FC<Props> = ({ description, icon, title }) => {
  const { colors, fonts } = useTheme()

  return (
    <div
      css={{
        maxWidth: 384,
        padding: "0 16px",
        textAlign: "center"
      }}
    >
      <img alt={title} height={32} src={icon} width={32} />
      <H2 textAlign="center">{title}</H2>
      <div
        css={{
          color: colors.neutral[900],
          fontFamily: fonts.inter,
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "24px"
        }}
      >
        {description}
      </div>
    </div>
  )
}
