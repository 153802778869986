/** @jsxImportSource @emotion/react */

type Props = {
  onClose: () => void
}

export const ModalCloseBar: React.FC<Props> = ({ onClose }) => (
  <div
    css={{
      display: "grid",
      justifyContent: "right",
      height: 60
    }}
  >
    <div
      css={{
        alignItems: "center",
        cursor: "pointer",
        display: "inline-grid",
        justifyContent: "center",
        height: 24,
        width: 24
      }}
      onClick={onClose}
    >
      &#10005;
    </div>
  </div>
)
