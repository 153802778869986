import { StrictMode } from "react"
import { render } from "react-dom"
import "./index.css"
import { App } from "./App"
import { createMainInjector } from "./bootstrap/injectors/createMainInjector"
import { sagas } from "./bootstrap/sagas"
import reportWebVitals from "./modules/vitals/reportWebVitals"

const injector = createMainInjector()
injector.sagaMiddleware.run(sagas(injector))

render(
  <StrictMode>
    <App injector={injector} />
  </StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
