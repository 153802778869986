/** @jsxImportSource @emotion/react */
import { Milliseconds } from "../../../../../modules/time/Milliseconds"
import { NavArrow } from "./NavArrow"

type Props = {
  animationDuration: Milliseconds
  backEnabled: boolean
  forwardEnabled: boolean
  onBackClick(): void
  onForwardClick(): void
}

export const NavArrows: React.FC<Props> = ({ animationDuration, backEnabled, forwardEnabled, onBackClick, onForwardClick }) => (
  <div
    css={{
      alignItems: "center",
      columnGap: 21,
      display: "grid",
      gridTemplateColumns: "auto auto"
    }}
  >
    <NavArrow animationDuration={animationDuration} direction="Back" enabled={backEnabled} onClick={onBackClick} />
    <NavArrow animationDuration={animationDuration} direction="Forward" enabled={forwardEnabled} onClick={onForwardClick} />
  </div>
)
