/** @jsxImportSource @emotion/react */

import { Formik } from "../../../../../../modules/form/Formik"
import { InitialSurveyFormValues } from "./InitialSurveyFormValues"
import { CaptchaField } from "./fields/CaptchaField"

type Props = {
  formik: Formik<InitialSurveyFormValues>
}

export const CaptchaSubsection: React.FC<Props> = ({ formik }) => (
  <div
    css={{
      display: "grid",
      justifyContent: "center",
      padding: "92px 0 50px"
    }}
  >
    <CaptchaField formik={formik} />
  </div>
)
