/** @jsxImportSource @emotion/react */

import { useCallback, useState } from "react"
import { useTheme } from "../../../hooks/useTheme"

type Props = {
  hasErrors?: boolean
  label?: string
  value: boolean
  onChange: (value: boolean) => void
}

export const CompactRegulationsCheckbox: React.FC<Props> = ({ hasErrors, label, value, onChange }) => {
  const { colors, fonts, gaps } = useTheme()
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const onClick = useCallback(() => onChange(!value), [value, onChange])
  const onFocus = useCallback(() => setIsFocused(true), [setIsFocused])
  const onBlur = useCallback(() => setIsFocused(false), [setIsFocused])

  return (
    <button
      css={{
        background: "none",
        border: "none",
        outline: "none",
        padding: 0
      }}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      type="button"
    >
      <div
        css={{
          alignItems: "center",
          cursor: "pointer",
          display: "grid",
          gridTemplateColumns: "auto auto",
          gap: gaps[3],
          justifyContent: "left"
        }}
      >
        <div
          css={{
            alignItems: "center",
            border: `solid 8px ${isFocused ? colors.neutral[200] : "transparent"}`,
            borderRadius: 16,
            display: "inline-grid",
            height: 40,
            justifyContent: "center",
            width: 40
          }}
        >
          <div
            css={{
              alignItems: "center",
              border: `solid 2px ${hasErrors ? colors.danger[900] : value ? colors.primary[600] : colors.neutral[600]}`,
              borderRadius: 8,
              display: "grid",
              height: 24,
              justifyContent: "center",
              width: 24
            }}
          >
            {value && (
              <span
                css={{
                  color: colors.primary[600],
                  fontFamily: fonts.inter,
                  lineHeight: "18px",
                  fontSize: 18,
                  fontWeight: 500
                }}
                dangerouslySetInnerHTML={{ __html: "&check;" }}
              ></span>
            )}
          </div>
        </div>
        <legend
          css={{
            color: colors.neutral[900],
            fontFamily: fonts.inter,
            fontSize: 12,
            lineHeight: "24px"
          }}
        >
          {label}
        </legend>
      </div>
    </button>
  )
}
