import { Routes, Route } from "react-router-dom"
import { ContactPage } from "../pages/contact/ContactPage"
import { Error404 } from "../pages/errors/Error404"
import { LandingPage } from "../pages/landing/LandingPage"
import { CookiesPolicyPage } from "../pages/law/cookies/CookiesPolicyPage"
import { PrivacyPolicyPage } from "../pages/law/privacy/PrivacyPolicyPage"
import { ServiceRegulationsPage } from "../pages/law/regulations/ServiceRegulationsPage"
import { InitialSurveyPage } from "../pages/survey/initial/InitialSurveyPage"
import { HCCSurveyResultPage } from "../pages/survey/initial/result/hcc/HCCSurveyResultPage"
import { HCCSurveyResultPrintablePage } from "../pages/survey/initial/result/hcc/printable/HCCSurveyResultPrintablePage"

export const RootRoutes: React.FC = () => (
  <Routes>
    <Route path="/" element={<LandingPage />} />
    <Route path="/survey">
      <Route path="continue" element={<InitialSurveyPage />} />
      <Route path="initial" element={<InitialSurveyPage />} />
      <Route path="result">
        <Route path="printable" element={<HCCSurveyResultPrintablePage />} />
        <Route path="" element={<HCCSurveyResultPage />} />
      </Route>
    </Route>
    <Route path="/regulations" element={<ServiceRegulationsPage />} />
    <Route path="/privacy" element={<PrivacyPolicyPage />} />
    <Route path="/cookies" element={<CookiesPolicyPage />} />
    <Route path="/contact" element={<ContactPage />} />
    <Route path="*" element={<Error404 />} />
  </Routes>
)
