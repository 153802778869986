/** @jsxImportSource @emotion/react */

type Props = { color: string; opacity?: number; radius: number }

export const BlurredCircle: React.FC<Props> = ({ color, opacity = 0.6, radius }) => {
  const diameter = 2 * radius
  return (
    <div
      css={{
        backgroundColor: color,
        borderRadius: radius,
        filter: "blur(100px)",
        opacity,
        height: diameter,
        width: diameter
      }}
    ></div>
  )
}
