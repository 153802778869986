export const dimensions = [
  "extraversion",
  "openness",
  "empathy",
  "emotionalStability",
  "dilligence",
  "professionalSelfEfficacy",
  "independence"
] as const
export type HCCDimension = typeof dimensions[number]
