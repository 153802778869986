/** @jsxImportSource @emotion/react */

import { useTheme } from "../../design/hooks/useTheme"
import { Circle } from "../Circle"

type Props = {
  radius: number
}

export const SpiderMeshCircle: React.FC<Props> = ({ radius }) => {
  const { colors } = useTheme()
  return <Circle color={colors.neutral[300]} center={{ x: 0.5, y: 0.5 }} radius={radius} thickness={0} />
}
